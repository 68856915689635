<template>
    <query-modal
        :success="status.isSuccess"
        :error="status.isError"
        :show-error="status.isError"
        :pending="status.isPending"
        @close="$emit('closeModalPass')"
    >
        <template #modal>
            <form
                class="max-w-[300px]"
                @submit.prevent="onSubmit"
            >
                <div class="flex justify-between items-center mb-4">
                    <h2 class="text-lg font-medium">
                        Изменить пароль
                    </h2>
                </div>
                <input-password
                    v-model.trim="newPassword.value"
                    class="mb-6"
                    label="Новый пароль"
                    placeholder="Введите пароль"
                    :error="newPassword.touched && newPassword.error"
                    :message="newPassword.message"
                    @blur="onBlurNewPassword('newPassword')"
                    @input="onInput('newPassword')"
                />
                <input-password
                    v-model.trim="repeatPassword.value"
                    class="mb-6"
                    label="Повторите пароль"
                    placeholder="Введите пароль"
                    :error="repeatPassword.touched && repeatPassword.error"
                    :message="repeatPassword.message"
                    @blur="onBlurNewPassword('repeatPassword')"
                    @input="onInput('repeatPassword')"
                />

                <enter-button
                    type="submit"
                    class="w-full mb-6"
                >
                    Сохранить
                </enter-button>
            </form>
        </template>
        <template #success>
            <div class="mt-8 mb-3 flex flex-col gap-4">
                <div>
                    Пароль успешно изменен!
                </div>
                <enter-button @click="$emit('openAuthorizationModal')">
                    Авторизоваться
                </enter-button>
            </div>
        </template>
        <template #error>
            <div class="mt-8 mb-3 flex flex-col gap-4">
                <div>
                    {{ message }}
                </div>
                <enter-button @click="$emit('openForgotModal')">
                    Получить новую ссылку
                </enter-button>
            </div>
        </template>
    </query-modal>
</template>

<script>
import EnterButton from "@/components/Enter/Button";
import InputPassword from "@/UI/InputPassword";
import { resetPassword } from "@/api";
import QueryModal from "@/components/QueryModal";
import router from "@/router";

export default {
    name: "ResetPasswordModal",
    components: {
        EnterButton,
        InputPassword,
        QueryModal
    },
    data() {
        return {
            newPassword: {
                value: "",
                touched: false,
                error: true,
                message: "Поле не должно быть пустым"
            },
            repeatPassword: {
                value: "",
                touched: false,
                error: true,
                message: "Поле не должно быть пустым"
            },
            status: {
                isPending: false,
                isError: false,
                isSuccess: false
            },
            message: ''
        };
    },
    computed: {
        isSamePassword() {
            return this.newPassword.value === this.repeatPassword.value;
        },
        isValidForm() {
            return (
                !this.newPassword.error &&
				!this.repeatPassword.error
            );
        },
        isFilledNewPassword() {
            if (this.newPassword.value === "") {
                this.newPassword.message = "Поле не должно быть пустым"
            }

            if (this.repeatPassword.value === "") {
                this.repeatPassword.message = "Поле не должно быть пустым"
            }

            return this.newPassword.value !== "" && this.repeatPassword.value !== "";
        }
    },
    methods: {
        showErrors() {
            this.newPassword.touched = true;
            this.repeatPassword.touched = true;
        },

        onSubmit() {
            if (!this.isFilledNewPassword) {
                this.showErrors();
                return;
            }
            if (!this.isValidForm) {
                this.showErrors();
                return;
            }
			
            const { code } = router.currentRoute.value.query;

            const data = {
                code: code,
                password: this.newPassword.value,
                passwordConfirmation: this.repeatPassword.value
            }

            resetPassword(data).then(() => {
                this.status.isSuccess = true
            }).catch(err => {
                console.log(err)
                this.status.isError = true
                this.message = err.response.data.error.message
            })
        },
        onBlurNewPassword(type) {
            this.onBlur(type);
            const valid = this[type].value.length >= 6

            if (!valid) {
                this[type].error = true;
                this[type].message = "Длина пароля не должна быть не менее 6 символов"
                return
            }

            if (this.isFilledNewPassword) {
                this.newPassword.error = !this.isSamePassword;
                this.repeatPassword.error = !this.isSamePassword;
                this.newPassword.message = 'Пароли должны совпадать';
                this.repeatPassword.message = 'Пароли должны совпадать';
            }
        },
        onBlur(type) {
            this[type].touched = true;
            this[type].error = this[type].value === "";
        },
        onInput(type) {
            this[type].touched = false;
        }
    }
};
</script>