<template>
    <query-modal
        :error="error"
        :pending="false"
        :success="success"
        @close="$emit('closeModalForgot')"
    >
        <template #modal>
            <form
                action=""
                class="w-full max-w-[412px] mx-auto flex flex-col gap-4"
                @submit.prevent="onSubmit"
            >
                <h2 class="text-lg font-medium">
                    Восстановить пароль
                </h2>
                <p class="w-full mx-auto">
                    Для восстановления пароля введите e-mail, <strong class="font-medium">указанный при регистрации</strong>.<br>
                    Ссылку на восстановление мы вышлем в письме.
                </p>
                <input-email
                    v-model="field.email.value"
                    label="E-mail"
                    placeholder="Введите e-mail"
                    :error="field.email.isError"
                    :message="field.email.message"
                    @input="field.email.input"
                    @blur="field.email.blur"
                />
                <enter-button>
                    Отправить
                </enter-button>
            </form>
        </template>
        <template #success>
            <div class="w-full max-w-[412px] mx-auto flex flex-col gap-4">
                <h2 class="text-lg font-medium">
                    Письмо отправлено
                </h2>
                <p class="w-full mx-auto">
                    Мы отправили письмо на указанный адрес электронной почты с инструкцией по восстановлению пароля.
                    <br>
                    Проверьте папку "Спам", если не можете найти письмо в основной папке "Входящие".
                </p>
            </div>
        </template>
    </query-modal>
</template>

<script>
import QueryModal from "@/components/QueryModal";
import InputEmail from "@/UI/InputEmail";
import EnterButton from "@/components/Enter/Button";
import { submit } from "@/use/submit";
import { toRef } from "vue";
import { forgotPassword } from "@/api";
import { forgot } from "@/configForms/resetPassword";
export default {
    name: "ForgotPasswordModal",
    components: { EnterButton, InputEmail, QueryModal },
    setup() {
        const init = submit({
            fields: forgot
        });

        const form = toRef(init, "form");
        return { form };
    },
    data() {
        return {
            success: false,
            error: false
        }
    },
    computed: {
        field() {
            return {
                ...this.form.fields
            };
        }
    },
    methods: {
        onSubmit() {
            if (!this.form.valid) {
                this.form.showErrors()
                return;
            }
            const data = {
                email: this.field.email.value
            }
            forgotPassword(data).then(response => {
                this.success = true;
                return response
            }).catch(err => {
                console.log(err)
            })
        }
    }
}
</script>

<style scoped>

</style>