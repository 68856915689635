<template>
	<div class="w-full">
		<input
			type="radio"
			:id="id"
			v-model="value"
			:value="id"
			:name="name"
			class="peer hidden [&:checked_+_label_svg]:block"
		/>
		<label
			:for="id"
			class="h-full flex-1 flex cursor-pointer items-center gap-2 border-2 border-black py-3 px-2 text-gray-900 hover:bg-white peer-checked:bg-white"
		>
			
			<svg class="hidden h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
				<path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
			</svg>
			
			<span class="text-sm font-bold tracking-widest text-black">{{name}}</span>
		</label>
	</div>
</template>

<script>
export default {
	name: "CheckFilter",
	props: {
		modelValue: {
			type: Boolean,
			required: true
		},
		id: {
			type: String,
			required: true
		},
		name: {
			type: String,
			required: true
		}
	},
	computed: {
		value: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit("update:modelValue", value);
			}
		}
	}
}
</script>

<style scoped>

</style>