<template>
    <p>Вы лучше {{ percentage }}% коллег {{ criterionLabel }} {{ periodLabel }}</p>
</template>

<script>
export default {
    name: "CriterionPercentile",

    props: {
        criterionLabel: {
            type: String,
            required: true
        },

        percentage: {
            type: Number,
            required: true
        },

        periodLabel: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>

</style>