<template>
    <div class="flex flex-col items-center sm:items-start">
        <h2
            v-if="heading"
            class="text-2xl mb-5"
            v-text="heading"
        />
        <div
            v-if="subheading"
            class="mb-10 max-w-[600px] text-center sm:text-left subheading"
            v-html="subheading"
        />

        <h2
            v-if="caption"
            class="text-2xl mb-5"
            v-text="caption"
        />

        <ol
            v-if="sources"
            class="max-w-[800px] grid grid-cols-2 gap-1 xl:grid-cols-2 md:grid-cols-1"
        >
            <li
                v-for="(item, idx) in sources"
                :key="idx"
                class="max-w-md w-full mx-auto"
            >
                <a
                    class="underline cursor-pointer"
                    :href="item.text"
                    target="_blank"
                >
                    {{ item.heading }}
                </a>
            </li>
        </ol>
    </div>
</template>

<script>

export default {
    name: 'BaseSources',
    props: {
        heading: {
            type: String,
            default: () => ''
        },
        subheading: {
            type: String,
            default: () => ''
        },
        caption: {
            type: String,
            default: () => ''
        },
        sources: {
            type: Array,
            default: () => []
        }
    }
}
</script>

<style module>
.description a {
    text-decoration: underline;
}
</style>
