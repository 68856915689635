<template>
    <div class="relative w-full h-full">
        <span
            class="h-full absolute inset-0 translate-x-2 translate-y-2 bg-orange-light"
        />
        <div class="h-full relative p-6 border-2 border-current md:p-4">
            <h3 class="font-bold text-lg mb-5">
                {{ topic }}
            </h3>
            <ul class="list-disc w-full">
                <li
                    v-for="link of links"
                    :key="link.id"
                    class="flex flex-wrap w-full"
                >
                    <a
                        :href="link.link"
                        target="_blank"
                        class="mb-2 underline hover:no-underline"
                    >
                        {{ link.text ? link.text : link.link }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "LinksCard",
    props: {
        links: {
            type: Object,
            required: true
        },
        topic: {
            type: String,
            required: true
        }
    }
};
</script>

<style scoped></style>
