<template>
    <h2
        v-if="heading"
        class="text-2xl mb-5"
        v-text="heading"
    />
    <div class="w-full max-w-[600px]">
        <div class="mb-10">
            <p
                v-if="description"
                v-text="description"
            />
            <hr class="my-5">
            <vc-blog-url-complete-form
                :message="form.submissionMessage"
                :submitted-url="submittedBlogUrl"
                :submit-button-text="form.submitButtonLabel"
            />
        </div>
        <partner-banner
            v-if="shouldDisplayPartnerBanner"
            :logo-url="partnerLogoUrl"
            :website-link-url="partner.websiteUrl"
            :website-link-text="partner.linkLabel"
        />
    </div>
</template>

<script>
import PartnerBanner from "@/components/VcBlogAnalytics/components/PartnerBanner";
import VcBlogUrlCompleteForm from "@/components/VcBlogAnalytics/components/VcBlogUrlCompleteForm";

export default {
    name: "VcBlogAnalyticsPendingScreen",

    components: {
        VcBlogUrlCompleteForm,
        PartnerBanner
    },

    props: {
        heading: {
            type: String,
            required: true
        },

        description: {
            type: String,
            required: true
        },

        form: {
            type: Object,
            required: true
        },

        submittedBlogUrl: {
            type: String,
            required: true
        },

        partner: {
            type: Object,
            required: true
        }
    },

    computed: {
        domain() {
            return process.env.VUE_APP_API_HOST;
        },

        partnerLogoUrl() {
            const logoUrlEndpoint = this.partner?.logoUrl;

            if (!logoUrlEndpoint) {
                return null;
            }

            return this.domain + logoUrlEndpoint;
        },

        shouldDisplayPartnerBanner() {
            const partnerInformation = this.partner;

            if (!partnerInformation) {
                return false;
            }

            const { websiteUrl, linkLabel, logoUrl } = partnerInformation;
            return websiteUrl && linkLabel && logoUrl;
        }
    }
};
</script>

<style scoped></style>
