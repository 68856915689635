<template>
    <h3
        v-if="heading"
        class="text-xl mb-2"
    >
        {{ heading }}
    </h3>
    <div class="flex flex-wrap items-stretch gap-10 mb-10">
        <div
            v-for="item in list"
            :key="item.id"
            class="max-w-sm relative inline-block"
        >
            <span
                class="w-full absolute inset-0 translate-x-2 translate-y-2 bg-orange-light"
            />
            <div
                class="w-full h-full relative flex gap-4 items-start p-4 border-2 border-current"
            >
                <span class="w-full w-[30px] flex-shrink-0 flex-grow-0">
                    <img
                        :src="host + item.image"
                        alt="иконка"
                    >
                </span>
                <p>
                    {{ item.label }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { API_HOST } from "@/constants";

export default {
    name: "ExchangeChat",
    props: {
        heading: {
            type: String,
            default: ""
        },
        list: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            host: API_HOST
        }
    }
};
</script>

<style scoped></style>
