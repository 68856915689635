<template>
    <p class="mb-10 lg:mb-5">
        {{ text }}
    </p>
</template>

<script>
export default {
    name: "IntroDescription",
    props: {
        text: {
            type: String,
            required: true
        }
    }
};
</script>

<style scoped></style>
