<template>
    <div class="w-full mb-10 relative group inline-block">
        <span
            class="w-full absolute inset-0 translate-x-2 translate-y-2 bg-orange-light"
        />
        <div
            class="w-full flex flex-col relative gap-2 group inline-block p-4 border-2 border-current"
        >
            <div class="flex flex-wrap -m-1 sm:m-0 sm:gap-2 sm:w-full">
                <div class="w-1/4 max-w-[300px] sm:w-full md:w-1/2 md:max-w-full lg:1/5 p-1 sm:p-0">
                    <load-filter
                        v-model="filters.cities"
                        :name="filterList.CITY"
                        placeholder="Город"
                        :query="request.CITY"
                        @on-select="onSelect"
                    />
                </div>
                <div class="w-1/4 max-w-[300px] sm:w-full md:w-1/2 md:max-w-full lg:1/5 p-1 sm:p-0">
                    <load-filter
                        v-model="filters.workers"
                        :name="filterList.WORKERS"
                        placeholder="Количество сотрудников"
                        :query="request.WORKERS"
                        @on-select="onSelect"
                    />
                </div>
                <div class="w-1/4 max-w-[300px] sm:w-full md:w-1/2 md:max-w-full lg:1/5 p-1 sm:p-0">
                    <load-filter
                        v-model="filters.expertise"
                        :name="filterList.EXPERTISE"
                        placeholder="Доменная экспертиза"
                        :query="request.EXPERTISE"
                        @on-select="onSelect"
                    />
                </div>
                <div class="w-1/4 max-w-[300px] sm:w-full md:w-1/2 md:max-w-full lg:1/5 p-1 sm:p-0">
                    <load-filter
                        v-model="filters.stacks"
                        :name="filterList.STACKS"
                        :query="request.STACK"
                        placeholder="Стек"
                        @on-select="onSelect"
                    />
                </div>
                <div class="w-1/4 max-w-[300px] sm:w-full md:w-1/2 md:max-w-full lg:1/5 p-1 sm:p-0">
                    <load-filter
                        v-model="filters.services"
                        :name="filterList.SERVICES"
                        :query="request.SERVICES"
                        placeholder="Услуги"
                        @on-select="onSelect"
                    />
                </div>
                <div class="w-1/4 max-w-[300px] sm:w-full md:w-1/2 md:max-w-full lg:1/5 p-1 sm:p-0">
                    <load-filter
                        v-model="filters.format"
                        :query="request.FORMAT"
                        :name="filterList.FORMAT"
                        placeholder="Формат"
                        @on-select="onSelect"
                    />
                </div>
                <div class="w-1/4 max-w-[300px] sm:w-full md:w-1/2 md:max-w-full lg:1/5 p-1 sm:p-0">
                    <load-filter
                        v-model="filters.markets"
                        :name="filterList.MARKETS"
                        :query="request.MARKETS"
                        placeholder="Рынки"
                        @on-select="onSelect"
                    />
                </div>
                <div class="w-1/4 max-w-[300px] sm:w-full md:w-1/2 md:max-w-full lg:1/5 p-1 sm:p-0">
                    <static-filter
                        v-model="optionalFilters"
                        name="optionalFilters"
                        :options="[
                            {value: filterList.BENCHMAP, title: 'Бенч-карта'},
                            {value: filterList.REQUESTSHOWCASE, title: 'Витрина запросов'}
                        ]"
                        placeholder="Другое"
                        @on-select="filtered"
                    />
                </div>
            </div>
			
            <selected-list
                v-if="list.length || fieldsList.length"
                :list="[...list, ...fieldsList]"
                @remove="remove"
            />
        </div>
    </div>
</template>

<script>
import "vue-multiselect/dist/vue-multiselect.css";
import SelectedList from "@/components/ResourcePage/SelectedList";
import LoadFilter from "@/components/ResourcePage/LoadFilter";
import StaticFilter from "@/components/ResourcePage/StaticFilter";
import CheckFilter from "@/components/ResourcePage/CheckFilter";
import BaseCheckbox from "@/UI/BaseCheckbox";
import { filterList, request } from "@/constants";

const TAG_TYPE = {
    [filterList.CITY]: "white",
    [filterList.FORMAT]: "orange",
    [filterList.WORKERS]: "blue-300",
    [filterList.EXPERTISE]: "fuchsia-300",
    [filterList.STACKS]: "green-300",
    [filterList.SERVICES]: "pink",
    [filterList.MARKETS]: "yellow-200"
};

export default {
    name: "ResourceFilter",
    components: { LoadFilter, SelectedList, CheckFilter, BaseCheckbox, StaticFilter },
    emits: [ 'onChange' ],
    data() {
        return {
            request: request,
            filters: {
                [filterList.CITY]: [],
                [filterList.FORMAT]: [],
                [filterList.WORKERS]: [],
                [filterList.EXPERTISE]: [],
                [filterList.STACKS]: [],
                [filterList.SERVICES]: [],
                [filterList.MARKETS]: []
            },
            empty: {
                [filterList.BENCHMAP]: false,
                [filterList.REQUESTSHOWCASE]: false
            },
            optionalFilters: {},
            fieldsList: []
        };
    },
    computed: {
        filterList() {
            return filterList
        },
        list() {
            return Object.entries(TAG_TYPE).reduce((acc, [ key, value ]) => {
                if (this.filters[key].length === 0) return acc;
                const values = this.filters[key].map(selected => ({
                    title: selected.title,
                    id: selected.value,
                    key: key,
                    type: value
                }));
				
                acc = [ ...acc, ...values ];
				
                return acc;
            }, []);
        }
    },
    methods: {
        remove({ id, key }) {
            if (this.empty[key]) {
                this.empty[key] = false;
                delete this.filters[id];
                this.fieldsList = this.fieldsList.filter(i => i.id !== id);
            } else {
                this.filters[key] = this.filters[key].filter(i => i.value !== id);
            }
            this.$emit('onChange', this.filters);
        },
		
        onSelect({ value, name }) {
            this.filters[name] = value;
            this.$emit('onChange', this.filters);
        },
		
        filtered(value) {
            const id = value.value[1].value;

            if (this.empty[id]) {
                this.empty[id] = false;
                delete this.filters[id];
                this.fieldsList = this.fieldsList.filter(i => i.id !== id);
                this.$emit('onChange', this.filters);

                return;
            }
			
            this.empty[id] = !this.empty[id];
            this.fieldsList = [
                ...this.fieldsList,
                {
                    title: value.value[1].title,
                    id: id,
                    key: id,
                    type: "white"
                }
            ]
            this.filters[id] = true;

            this.$emit('onChange', this.filters);
        }
    }
};
</script>

<style></style>
