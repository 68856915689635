<template>
    <page-loading :status="status">
        <h2 class="text-2xl mb-10">
            Личный кабинет
        </h2>
        <div class="flex items-start md:flex-col gap-4">
            <info-user
                @show-edit="showEdit=true"
                @show-change-pass="showChangePass=true"
            />

            <users-list
                v-if="isAdmin"
            />
        </div>

        <edit-modal
            v-if="showEdit"
            @closeModalEdit="closeModalEdit"
        />
        <change-pass
            v-if="showChangePass"
            @closeModalPass="closeModalPass"
        />
    </page-loading>
</template>

<script>
import InfoUser from "@/components/ProfilePage/InfoUser";
import EditModal from "@/components/ProfilePage/EditModal";
import ChangePass from "@/components/ProfilePage/ChangePass";
import { loadData } from "@/use/load";
import PageLoading from "@/components/PageLoading";
import { getUserDetail } from "@/api/formattedResponse";
import { mainHandler } from "@/handlers";
import { toRef } from "vue";
import UsersList from "@/components/ProfilePage/UsersList";

export default {
    name: "ProfilePage",
    components:{ UsersList, ChangePass, InfoUser, EditModal, PageLoading },
    setup() {
        const { data: response, status } = loadData({ query: getUserDetail, handler: mainHandler });
        const data = toRef(response, "data");
        return { data, status };
    },
    data() {
        return {
            showEdit: false,
            showChangePass: false
        }
    },
    computed:{
        isAdmin() {
            return this.$store.getters['isAdmin'];
        }
    },
    methods: {
        closeModalEdit() {
            this.showEdit = false;
        },
        closeModalPass() {
            this.showChangePass = false;
        }
    }
}
</script>
