<template>
    <div
        :class="$style.description"
        v-html="html"
    />
</template>

<script>
export default {
    name: 'HtmlContent',
    props: {
        html: {
            type: String,
            required: true
        }
    }
}
</script>

<style module>

.description a {
   text-decoration: underline;
}
</style>