<template>
    <div class="relative inline-block max-w-xs w-full min-h-[200px] sm:max-w-full sm:min-w-full">
        <span class="w-full h-full absolute inset-0 translate-x-2 translate-y-2 bg-orange bg-gray-50 shadow-md" />
        <div class="w-full h-full relative flex flex-col justify-between p-8 border-2 border-current sm:p-4">
            <card-controls
                v-if="adminMode"
                :published="published"
                @edit="editCard"
                @publish="publishCard"
                @unPublish="unPublishCard"
                @delete="deleteCard"
            />
            <div class="h-full flex flex-col justify-between">
                <div>
                    <div class="flex flex-col gap-2">
                        <p class="text-sm text-gray-700">
                            {{ date }}
                        </p>
                        <div
                            class="flex flex-wrap gap-1 mb-2"
                        >
                            <span
                                v-if="type"
                                class="bg-orange px-2 py-0.5 text-xs"
                            >
                                {{ type }}
                            </span>

                            <span
                                v-if="city"
                                class="bg-orange px-2 py-0.5 text-xs"
                            >
                                {{ city }}
                            </span>
                            <span
                                v-for="item of tags"
                                :key="item"
                                class="w-fit bg-orange-light px-2 py-0.5 text-xs"
                            >
                                {{ item }}
                            </span>
                        </div>
                        <h4 class="text-lg font-bold block w-full">
                            {{ heading }}
                        </h4>

                        <p
                            v-if="price"
                            class="text-xs"
                        >
                            {{ price }}
                        </p>
                    </div>
                    <div
                        v-if="description"
                        class="mt-4 text-sm text-gray-700 w-full mt-auto"
                        v-html="description"
                    />
                </div>
                <div
                    v-if="transcript || registration"
                    class="w-full mt-4"
                >
                    <a
                        v-if="transcript"
                        :href="transcript"
                        target="_blank"
                        class="relative w-full inline-block group focus:outline-none focus:ring sm:w-full text-center"
                    >
                        <span class="absolute inset-0 transition-transform translate-x-1 translate-y-1 bg-orange group-hover:translate-y-0 group-hover:translate-x-0" />
                        <span class="w-full relative inline-block px-3 py-2 text-sm font-bold text-black uppercase border-2 border-current">
                            Конспект
                        </span>
                    </a>
                    <a
                        v-if="!transcript"
                        :href="registration"
                        target="_blank"
                        class="relative w-full inline-block group focus:outline-none focus:ring sm:w-full text-center"
                    >
                        <span class="absolute inset-0 transition-transform translate-x-1 translate-y-1 bg-orange group-hover:translate-y-0 group-hover:translate-x-0" />
                        <span class="w-full relative inline-block px-3 py-2 text-sm font-bold text-black uppercase border-2 border-current">
                            Регистрация
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CardControls from "@/components/CalendarPage/components/CardControls";
import { getCardEventFromId } from "@/api";
export default {
    name: "CalendarCard",
    components: { CardControls },
    props: {
        id: {
            type: Number,
            required: true
        },
        heading: {
            type: String,
            required: true
        },
        registration: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        transcript: {
            type: String,
            default: ''
        },
        date: {
            type: String,
            default: ''
        },
        price: {
            type: String,
            default: ''
        },
        tag: {
            type: String,
            default: ''
        },
        city: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        },
        published: {
            type: Boolean
        }
    },
    computed: {
        adminMode() {
            return this.$store.state.calendarAdminMode;
        },

        tags() {
            if (!this.tag) return []
            console.log()
            return this.tag.split(',')
        }
    },
    methods: {
        deleteCard() {
            this.$emit('onDelete', { id: this.id, heading: this.heading })
        },
        publishCard() {
            this.$emit('onPublish', { id: this.id, heading: this.heading })
        },
        unPublishCard() {
            this.$emit('onUnpublish', { id: this.id, heading: this.heading })
        },
        editCard() {
            getCardEventFromId(this.id)
                .then(response => {
                    this.$store.commit("setEventCard", response.data.data);
                    this.$emit('onEdit')
                    return response
                })
                .catch(e => {
                    console.log(e)
                })
        }
    }
}
</script>

<style scoped>

</style>