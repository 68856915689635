<template>
    <div
        class="flex w-[500px] sm:w-[100%] sm:min-w-[300px] relative"
    >
        <span class="w-full absolute inset-0 translate-x-2 translate-y-2 bg-black" />
        <div class="w-full relative border-2 border-current bg-white p-4 flex flex-col gap-4">
            <div class="flex flex-row w-full gap-4">
                <div class="w-full max-w-[16%]">
                    <img
                        v-if="card.photo"
                        class="w-full"
                        :src="link + card.photo"
                        alt=""
                    >
                    <svg
                        v-if="!card.photo"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-full"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                        />
                    </svg>
                </div>
                <div class="flex w-full flex-col gap-0.5">
                    <component
                        :is="tag"
                        class="text-lg font-medium"
                        :class="{'underline': card.telegramLink}"
                        target="_blank"
                        :href="card.telegramLink"
                    >
                        {{ card.name }}
                    </component>
                    <span class="text-xs font-extralight">{{ card.post }}</span>
                    <a
                        v-if="card.site"
                        class="underline"
                        :href="card.site"
                        target="_blank"
                    >
                        {{ card.company }}
                    </a>
                    <h4 v-if="!card.site">
                        {{ card.company }}
                    </h4>
                </div>
                <div class="w-1/12">
                    <span
                        v-if="card.video"
                        class="pulse right-4 top-4"
                        @click="openVideo"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1,5"
                            stroke="white"
                            class="w-full h-full cursor-pointer"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                            />
                        </svg>
                    </span>
                </div>
            </div>
			
            <p
                class="cursor-default"
                v-html="card.feedback"
            />
        </div>
    </div>
    <feedback-video
        v-if="showVideo"
        :video="video"
        @onClose="closeVideo"
    />
</template>

<script>
import FeedbackVideo from "@/components/Landing/Feedback/FeedbackVideo";

export default {
    name: "FeedbackCard",
    components: { FeedbackVideo },
    props: {
        card: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showVideo: false,
            video: ''
        }
    },
    computed: {
        link() {
            return process.env.VUE_APP_API_HOST;
        },
        tag() {
            return this.card.telegramLink ? "a" : "div";
        }
    },
    methods: {
        openVideo() {
            this.showVideo = true;
            this.video = this.card.video;
        },
        closeVideo() {
            this.showVideo = false;
            this.video = '';
        }
    }
}
</script>

<style scoped>
.pulse {
	display: block;
	width: 24px;
	height: 24px;
	padding: 4.5px 4px 4px 4.5px;
	border-radius: 50%;
	background: #FBB724;
	cursor: pointer;
	box-shadow: 0 0 0 rgba(251, 183, 36, 0.6);
	animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(251, 183, 36, 0.6);
	}
	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(251, 183, 36, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(251, 183, 36, 0);
	}
}

@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(251, 183, 36, 0.6);
		box-shadow: 0 0 0 0 rgba(251, 183, 36, 0.6);
	}
	70% {
		-moz-box-shadow: 0 0 0 10px rgba(251, 183, 36, 0);
		box-shadow: 0 0 0 10px rgba(251, 183, 36, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(251, 183, 36, 0);
		box-shadow: 0 0 0 0 rgba(251, 183, 36, 0);
	}
}
</style>