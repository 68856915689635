<template>
    <page-loading
        :message="errors?.empty"
        :status="status"
        :empty="errors?.empty"
        :errors="errors"
        class="overflow-x-hidden"
    >
        <button-up />
        <base-sources
            :heading="data.heading"
            :caption="data?.caption"
            :subheading="data?.subheading"
            :sources="data?.sources"
            class="mb-[60px] reports"
        />
        <div class="flex gap-4 items-start">
            <reports-preview
                :reports="data.reports"
                :years="years"
                :active-year="idYear"
                :content="activeYear"
                :quarter="activeYear?.quarter"
                :month="activeYear?.month"
                :weeks="activeYear?.week"   
                @toggle="toggle"
            />
        </div>
        <div class="relative w-full mx-auto max-w-[600px]">
            <partner-banner
                :logo-url="data.partner[0].logo.data.attributes.url"
                :website-link-url="data.partner[0].websiteUrl"
                :website-link-text="data.partner[0].linkLabel"
            />
        </div>
    </page-loading>
</template>

<script>
import { loadData } from "@/use/load";
import PageLoading from "@/components/PageLoading";
import { toRef } from "vue";
import { reportsPage } from "@/api";
import listHandler from "../handlers/listHandler";
import BaseSources from "@/components/Reports2/BaseSources";
import ReportsPreview from "@/components/Reports2/ReportsPreview";
import ButtonUp from "@/components/Reports2/components/ButtonUp";
import PartnerBanner from "@/components/VcBlogAnalytics/components/PartnerBanner";

export default {
    name: "ReportsPage3",
    components:{ PartnerBanner, ButtonUp, PageLoading, ReportsPreview, BaseSources },
    setup() {
        const { data: response, status } = loadData({ query: reportsPage, handler: listHandler });
        const data = toRef(response, "data");
        const errors = toRef(response, "errors");

        return {
            data,
            status,
            errors
        };
    },

    data() {
        return {
            showEdit: false,
            selectedYear: null,
        }
    },
    computed: {
        years() {
            if (!this.data.list) return []
            return Object.keys(this.data.list)
        },
        activeYear() {
            if (!this.data.list) return {}
            const activeYear = this.data.list[this.idYear];
            return activeYear || {}
        },
        idYear() {
            return this.selectedYear ?? Object.keys(this.data.list).at(-1);
        },
        domain() {
            return process.env.VUE_APP_API_HOST;
        }
    },
    methods: {
        toggle(year) {
            this.selectedYear = String(year);
        }
    }
}
</script>

<style>
.reports .subheading  a {
	background-color: #F6E5CC;
}
</style>
