<template>
    <div
        v-if="status.isPending"
        class=" flex flex-col m-auto"
    >
        <loading-content />
    </div>
    <error-load v-if="status.isError" />
    <slot v-if="showContent" />

    <base-error
        v-if="showError && errors"
        @close="showError = false"
    >
        {{ textError }}
    </base-error>
    <h1
        v-if="showEmpty"
        class="text-2xl uppercase font-bold mb-4 mt-4 text-center"
    >
        {{ message }}
    </h1>
</template>

<script>
import LoadingContent from "@/components/LoadingContent";
import ErrorLoad from "@/components/ErrorLoad";
import BaseError from "@/components/BaseError";

export default {
    name: "PageLoading",
    components: { LoadingContent, ErrorLoad, BaseError },
    props: {
        status: {
            type: Object,
            required: true
        },
        errors: {
            type: Object,
            required: true
        },
        empty: {
            type: Boolean,
            default: false
        },
        message: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showError: true
        }
    },
    computed: {
        showContent() {
            return this.status.isSuccess && !this.empty
        },
        showEmpty() {
            return this.status.isSuccess && this.empty
        },
        textError() {
            return Object.entries(this.errors).reduce((acc, [ key, value ]) => {
                if (key !== 'empty') {
                    acc.push(value)
                }
                console.log(value)
                return acc
            }, []).join('. ')
        }
    }
};
</script>

<style scoped></style>
