<template>
    <li class="relative group inline-block">
        <span class="absolute inset-0 translate-x-1.5 translate-y-1.5 bg-orange-light" />
        <div class="relative group inline-block p-4 border-2 border-current">
            <slot />
        </div>
    </li>
</template>

<script>
export default {
    name: "ListItem"
}
</script>

<style scoped>

</style>