<template>
    <form
        action=""
        class="w-[260px]"
        @submit.prevent="onSubmit"
    >
        <div class="mb-6">
            <input-email
                v-model.trim="field.login.value"
                class="mb-4 w-full"
                label="E-mail"
                placeholder="Введите логин"
                :error="field.login.isError"
                :message="field.login.message"
                @input="field.login.input"
                @blur="field.login.blur"
            />
        </div>

        <div class="mb-6">
            <input-password
                v-model.trim="field.password.value"
                label="Пароль"
                placeholder="Введите пароль"
                :error="field.password.isError"
                :message="field.password.message"
                @input="field.password.input"
                @blur="field.password.blur"
            />
        </div>

        <div
            v-if="error"
            class="mt-2 text-red block text-xs font-thin text-center mb-2"
        >
            {{ error }}
        </div>
        <enter-button
            type="submit"
            class="w-full mb-6"
        >
            Войти
        </enter-button>
    </form>
</template>

<script>
import { overflowShow } from "@/helpers";
import EnterButton from "@/components/Enter/Button";
import InputEmail from "@/UI/InputEmail";
import InputPassword from "@/UI/InputPassword";
import { authUser } from "@/api/formattedResponse";
import submitForm from "@/use/submitForm";
import { toRef } from "vue";
import auth from "@/configForms/auth";

export default {
    name: 'AuthForm',
    components: {
        EnterButton,
        InputEmail,
        InputPassword
    },
    emits: [ 'submit' ],
    setup() {
        const submit = submitForm(auth);

        const form = toRef(submit, "form");
        const responseError = toRef(submit, "responseError");

        return { form, responseError };
    },
    data() {
        return {
            isDirty: false,
            error: null
        }
    },
    computed: {
        field() {
            const { login, password } = this.form.fields;
            return {
                login,
                password
            };
        }
    },
    methods: {
        onSubmit() {
            if (!this.form.valid) {
                this.form.showErrors()
                return;
            }

            authUser(this.form.fields).then(() => {
                this.$emit('submit')
                overflowShow();
            }).catch(error => {
                this.responseError(error);
            })
        }
    }
}
</script>

<style scoped>

</style>
