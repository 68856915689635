<template>
    <button
        v-for="tab of tabs"
        :key="tab"
        class="relative group inline-block"
        @click="selectPeriod(tab)"
    >
        <span
            :class="{
                ['bg-orange']: tab === selectedKey,
                ['bg-orange-light']: tab !== selectedKey,
                ['translate-y-0']: tab === selectedKey,
                ['translate-x-0']: tab === selectedKey,
            }"
            class="absolute inset-0
                translate-x-1.5
                transition-transform
                translate-y-1.5 group-hover:translate-y-0 group-hover:translate-x-0 pointer-events-none"
        />
        <span
            class="relative inline-block text-sm leading-5 font-bold tracking-widest py-3 px-3 border-2 border-current pointer-events-none"
            v-text="tab"
        />
    </button>
</template>

<script>
export default {
    name: "ReportsTabs",
    props: {
        tabs: {
            type: Array,
            required: true
        },
        selectedKey: {
            type: String,
            required: true
        }
    },
    emits: [ 'toggle' ],
    methods: {
        selectPeriod(key) {
            this.$emit('toggle', key)
        }
    }
}
</script>

<style scoped>

</style>