<template>
    <modal-template
        @onClose="closeModalAuthorization"
    >
        <div class="flex justify-between items-center mb-4">
            <h2 class="text-lg font-medium">
                Войти в личный кабинет
            </h2>
        </div>
        <auth-form @submit="submit" />
        <button
            type="button"
            class="block w-full mb-1 underline text-center text-gray-700 hover:no-underline hover:text-black"
            @click.prevent="$emit('openForgotModal')"
        >
            Забыли пароль
        </button>
        <button
            type="button"
            class="block w-full underline text-center text-gray-700 hover:no-underline hover:text-black"
            @click.prevent="$emit('openRegEmployeeModal')"
        >
            Регистрация для менеджеров
        </button>
    </modal-template>
</template>

<script>

import CloseModal from "@/components/Modal/CloseModal";
import ModalTemplate from "@/components/Modal/ModalTemplate";

import { overflowShow } from "@/helpers";

import AuthForm from "@/components/Enter/AuthForm";

export default {
    name: "AuthorizationModal",
    components: {
        AuthForm,
        ModalTemplate,
        CloseModal
    },
    data() {
        return {
            popUp: false
        };
    },
    methods: {
        closeModalAuthorization() {
            this.$emit("closeModalAuthorization");
        },
        submit() {
            overflowShow();
            this.$router.push({ name: "main" });
        },

        closePopUp() {
            this.closeModalAuthorization();
        }
    }
};
</script>
