<template>
    <page-loading
        :message="errors?.empty"
        :status="status"
        :empty="errors?.empty"
    >
        <h2
            v-if="data.heading"
            class="text-2xl mb-5"
        >
            {{ data.heading }}
        </h2>
        <f-a-q-item
            v-for="question of data.questions"
            :key="question.id"
            :header="question.heading"
            :text="question.text"
        />
    </page-loading>
</template>

<script>
import FAQItem from "@/components/FAQPage/FAQItem";
import {loadData} from "@/use/load";
import {faqPage} from "@/api";
import {toRef} from "vue";
import PageLoading from "@/components/PageLoading";
import singlePageHandler from "@/handlers/singlePageHandler";

export default {
    name: "FAQPage",
    components: { FAQItem, PageLoading },
    setup() {
        const { data: response, status } = loadData({ query: faqPage, handler: singlePageHandler });
        const data = toRef(response, "data");
        const errors = toRef(response, "errors");
        return {
            status,
            data,
            errors
        };
    }
};
</script>

<style scoped></style>
