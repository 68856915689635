<template>
    <input-password
        v-model="newPassword.value"
        label="Новый пароль"
        placeholder="Введите пароль"
        :error="newPassword.error"
        :message="newPassword.message"
        @blur="onBlurPassword('newPassword')"
        @input="onInput('newPassword')"
    />
	
    <input-password
        v-model="repeatPassword.value"
        label="Повторите пароль"
        placeholder="Введите пароль"
        :error="repeatPassword.error"
        :message="repeatPassword.message"
        @blur="onBlurPassword('repeatPassword')"
        @input="onInput('repeatPassword')"
    />
</template>

<script>
import InputPassword from "@/UI/InputPassword";

export default {
    name: "CreatePassword",
    components: { InputPassword },
    props: {
        modelValue: {
            type: String,
            required: true
        },
        empty: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            newPassword: {
                value: "",
                touched: false,
                error: false,
                message: "Поле обязательно к заполнению"
            },
            repeatPassword: {
                value: "",
                touched: false,
                error: false,
                message: "Поле обязательно к заполнению"
            },
            newPass: '',
            message: '',
            isError: false
        }
    },
    computed: {
        isSamePassword() {
            return this.newPassword.value === this.repeatPassword.value;
        },
        isFilledNewPassword() {
            return this.newPassword.value !== "" && this.repeatPassword.value !== "";
        },
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            }
        }
    },
    watch: {
        empty() {
            if (!!this.empty && !this.newPassword.value) {
                this.newPassword.error = true
                this.newPassword.touched = true
                this.newPassword.message = 'Поле обязательно к заполнению'
            }
            if (!!this.empty && !this.repeatPassword.value) {
                this.repeatPassword.error = true
                this.repeatPassword.touched = true
                this.repeatPassword.message = 'Поле обязательно к заполнению'
            }
        }
    },
    methods: {
        onBlurPassword(type) {
            this[type].touched = true;
            this[type].error = this[type].value === "";

            if (!this[type].value) {
                this[type].error = true
                this[type].message = 'Поле обязательно к заполнению'
                this.$emit("setPass", null);

                return;
            }
            if (this[type].value.length < 6) {
                this[type].error = true
                this[type].message = 'Длина пароля не менеее 6ти символов'
                this.$emit("setPass", null);

                return;
            }

            if (this.isFilledNewPassword) {
                this.newPassword.error = !this.isSamePassword;
                this.repeatPassword.error = !this.isSamePassword;
                this.newPassword.touched = true
                this.repeatPassword.touched = true

                this.newPassword.message = 'Пароли должны совпадать';
                this.repeatPassword.message = 'Пароли должны совпадать';

                this.$emit("setPass", null);
            }
            if (this.isSamePassword) {
                this.$emit("setPass", this.repeatPassword.value);
            }
        },

        onBlur(type) {
            this[type].touched = true;
            this[type].error = this[type].value === "";
        },
        onInput(type) {
            this[type].touched = false;
            this[type].error = this[type].value === "";
        }
    }
};
</script>
