<template>
    <div
        class="fixed backdrop-blur-sm left-0 top-0 z-50 bg-background w-full h-screen overflow-auto flex justify-center"
        @click.self="close"
    >
        <button
            class="fixed right-4 z-[100] top-4 p-0.5 bg-orange"
            @click="close"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="3"
                stroke="black"
                class="w-6 h-6"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                />
            </svg>
        </button>
        <video
            :src="link + video"
            autoplay
            controls
        />
    </div>
</template>

<script>
import EnterButton from "@/components/Enter/Button";
export default {
    name: "FeedbackVideo",
    components: { EnterButton },
    props: {
        video: {
            type: String,
            required: true
        }
    },
    computed: {
        link() {
            return process.env.VUE_APP_API_HOST;
        }
    },
	methods: {
		close() {
			this.$emit('onClose')
		}
	}
}
</script>

<style scoped>

</style>