<template>
    <page-loading
        :status="status"
        :empty="errors?.empty"
        :message="errors?.empty"
    >
        <desc-text
            v-if="data.heading"
            :title="data.heading"
        />
        <main-list
            v-if="data.objective?.list?.length"
            :list="data.objective.list"
        >
            {{ data.objective.heading }}
        </main-list>
        <main-list
            v-if="data.done?.list?.length"
            :list="data.done.list"
        >
            {{ data.done.heading }}
        </main-list>
        <articles-list
            v-if="data.articles?.list?.length"
            :list="data.articles.list"
        >
            {{ data.articles.heading }}
        </articles-list>
    </page-loading>
</template>

<script>
import MainList from "@/components/MainPage/MainList";
import DescText from "@/components/MainPage/DescText";
import ArticlesList from "@/components/MainPage/ArticlesList";
import { personalPage } from "@/api";
import { loadData } from "@/use/load";
import { toRef } from "vue";
import PageLoading from "@/components/PageLoading";
import singlePageHandler from "@/handlers/singlePageHandler";

export default {
    name: "MainPage",
    components: { DescText, MainList, PageLoading, ArticlesList },
    setup() {
        const { data: response, status } = loadData({ query: personalPage, handler: singlePageHandler });
        const data = toRef(response, "data");
        const errors = toRef(response, "errors");

        return {
            data,
            status,
            errors
        };
    }
};
</script>

<style scoped></style>
