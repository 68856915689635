<template>
    <div class="flex items-center flex-col m-auto">
        <div class="flex justify-between items-center mb-4">
            <h2 class="max-w-[600px] text-xl text-center uppercase">
                Для доступа на эту страницу необходимо авторизоваться
            </h2>
        </div>

        <auth-form
            @submit="submit"
        />
    </div>
</template>

<script>
import AuthForm from "@/components/Enter/AuthForm";

export default {
    name: "AuthPage",
    components: { AuthForm },
    methods: {
        submit() {
            this.$router.go(-1);
        }
    }
}
</script>

<style>

</style>
