<template>
    <query-modal
        :success="status.isSuccess"
        :error="status.isError"
        :pending="status.isPending"
        @close="$emit('closeModalEdit')"
    >
        <template #modal>
            <form
                class="max-w-[270px]"
                @submit.prevent="onSubmit"
            >
                <div class="flex justify-between items-center mb-4">
                    <h2 class="text-lg font-medium">
                        Редактировать профиль
                    </h2>
                </div>

                <base-input
                    v-model.trim="field.email.value"
                    class="mb-4 w-full"
                    label="E-mail"
                    placeholder="Введите почту"
                    disabled
                    :error="field.email.isError"
                    :message="field.email.message"
                    @input="field.email.input"
                    @blur="field.email.blur"
                />

                <base-input
                    v-model.trim="field.name.value"
                    class="mb-4 w-full"
                    label="Имя"
                    type="text"
                    placeholder="Введите имя"
                    :error="field.name.isError"
                    :message="field.name.message"
                    @blur="field.name.blur"
                    @input="field.name.input"
                />

                <base-input
                    v-model.trim="field.lastName.value"
                    class="mb-4 w-full"
                    label="Фамилия"
                    type="text"
                    placeholder="Введите фамилию"
                    :error="field.lastName.isError"
                    :message="field.lastName.message"
                    @blur="field.lastName.blur"
                    @input="field.lastName.input"
                />

                <base-input
                    v-model.trim="field.company.value"
                    class="mb-4 w-full"
                    label="Компания"
                    type="text"
                    placeholder="Введите компанию"
                    :error="field.company.isError"
                    :message="field.company.message"
                    @blur="field.company.blur"
                    @input="field.company.input"
                />

                <base-input
                    v-model.trim="field.position.value"
                    class="mb-4 w-full"
                    label="Должность"
                    type="text"
                    placeholder="Введите должность"
                    :error="field.position.isError"
                    :message="field.position.message"
                    @blur="field.position.blur"
                    @input="field.position.input"
                />
                <enter-button
                    type="submit"
                    class="w-full mb-6"
                    :disabled="!isChanged"
                >
                    Сохранить
                </enter-button>
            </form>
        </template>
        <template #success>
            <p class="mt-8 mb-3">
                Данные успешно изменены!
            </p>
        </template>
    </query-modal>
</template>

<script>
import EnterButton from "@/components/Enter/Button";
import BaseInput from "@/UI/BaseInput";
import { updateUserDetail } from "@/api/formattedResponse";
import QueryModal from "@/components/QueryModal";
import { submit } from "@/use/submit";
import { toRef } from "vue";
import editPersonal from "@/configForms/editPersonal";

export default {
    name: "EditModal",
    components: { BaseInput, EnterButton, QueryModal },
    emits: [ 'closeModalEdit' ],
    setup() {
        const init = submit({
            submit: updateUserDetail,
            fields: editPersonal()
        });

        const form = toRef(init, "form");
        const callback = toRef(init, "onSubmit");
        const status = toRef(init, "status");
        return { form, submit: callback, status };

    },

    computed: {
        field() {
            return { ...this.form.fields };
        },
        isChanged() {
            return Object.values(this.form.fields).some(item => item.changed)
        }
    },

    methods: {
        onSubmit() {
            if (!this.isChanged) return;

            if (!this.form.valid) {
                this.form.showErrors()
                return;
            }
            this.submit();

        }
    }
};
</script>

<style scoped></style>
