<template>
    <div class="mt-auto mb-5">
        <h2
            v-if="join.heading"
            class="text-2xl mb-5 text-center"
        >
            {{ join.heading }}
        </h2>
        <p
            v-if="join.text"
            class="text-lx text-gray-700 text-center mb-4"
            v-html="join.text"
        />
        <div class="flex justify-center">
            <enter-button
                v-if="join.buttonText"
                class="mb-5"
                @click="openModal"
            >
                {{ join.buttonText }}
            </enter-button>
        </div>
    </div>
    <confirm-modal
        v-if="confirm"
        @close-modal="closeModal"
    />
</template>

<script>
import ConfirmModal from "@/components/Modal/ConfirmModal";
import EnterButton from "@/components/Enter/Button";

export default {
    name: 'JoinAssociation',
    components: { ConfirmModal, EnterButton },
    props: {
        join: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            confirm: false
        }
    },
    methods: {
        openModal() {
            this.confirm = true;
        },
        closeModal() {
            this.confirm = false;
        }
    }

}
</script>

<style scoped>

</style>
