<template>
    <keep-alive>
        <router-view />
    </keep-alive>
</template>

<script>
import { KEYS } from "@/constants";

export default {
    mounted() {
        const token = localStorage.getItem(KEYS.TOKEN);
        const user = localStorage.getItem(KEYS.USER);
        if (token) {
            this.$store.commit('getToken', token)
        }
        if (user) {
            const formatted = JSON.parse(user);
            this.$store.commit('getUser', formatted)
        }
    }
}
</script>
