import store from "@/store";
import { isValidEmail, required } from "@/helpers";
import { INVALID_EMAIL, REQUIRED_FIELD } from "@/constants";

export default () => {
    const { email, name, lastName, company, post } = store.state.user
    return {
        email: {
            value: email,
            validators: [
                {
                    validator: isValidEmail,
                    message: INVALID_EMAIL
                },
                {
                    validator: required,
                    message: REQUIRED_FIELD
                }
            ]

        },
        name: {
            value: name,
            validators: [
                {
                    validator: required,
                    message: REQUIRED_FIELD
                }
            ]

        },
        lastName: {
            value: lastName,
            validators: [
                {
                    validator: required,
                    message: REQUIRED_FIELD
                }
            ]

        },
        company: {
            value: company,
            validators: [
                {
                    validator: required,
                    message: REQUIRED_FIELD
                }
            ]

        },
        position: {
            value: post,
            validators: [
                {
                    validator: required,
                    message: REQUIRED_FIELD
                }
            ]

        }
    }
}
