import axios from "axios";
import { API_HOST, KEYS, publicSections } from "@/constants";
import store from "@/store";
import router from "@/router";

const axiosInstance = axios.create({
    baseURL: `${API_HOST}/api/`
})

axiosInstance.interceptors.response.use(function (response) {
    if (response?.data?.jwt) {
        const { jwt } = response.data;
        store.commit('getToken', jwt)
        localStorage.setItem(KEYS.TOKEN, jwt);

        if (!store.getters.user.resourceMap) {
            router.push('/account/form-map')
        }
    }

    return response;
}, function (error) {
    if (error.response.status === 403) {
        router.push('/account/auth')
    }
    if (error.response.status === 401) {
        router.push('/account/auth')
    }
    return Promise.reject(error);
});

axiosInstance.interceptors.request.use(function (config) {
    const { getToken: token } = store?.getters;
    // если протухший ключ будет передаваться
    // в раздел с полным доступом неавторизованному пользователю страпи вернет ошибку авторизации
    // поэтому в разделы где не нужна авторизацию не передают токен
    const sendToken = !publicSections.includes(config.url) && token

    if (sendToken) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

export default axiosInstance
