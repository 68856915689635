import { useForm } from "@/use/form";

export default function submitForm(init) {
    const form = useForm(init);

    const responseError = error => {
        const fields = error?.data?.fields;
        if (fields?.length) {
            fields.forEach(({ field, message }) => {
                form.fields[field].valid = false;
                form.fields[field].message = message;
            });
            return
        }
        this.error = error.message
    }

    return { form, responseError }
}
