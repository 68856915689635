<template>
    <div class="mb-20 relative">
        <span
            class="absolute inset-0 translate-x-3 translate-y-3 bg-orange-light"
        />
        <div class="relative border-2 border-current px-4 py-20">
            <div class="max-w-3xl mx-auto text-center">
                <p class="text-2xl font-bold sm:text-2xl mb-8">
                    {{ heading }}
                </p>
                <enter-button
                    v-if="buttonText"
                    @click="$emit('onClick')"
                >
                    {{ buttonText }}
                </enter-button>
            </div>
        </div>
    </div>
</template>

<script>
import EnterButton from "@/components/Enter/Button";
export default {
    name: "EntryBlock",
    components: { EnterButton },
    props: {
        heading: {
            type: String,
            default: ""
        },
        buttonText: {
            type: String,
            default: ""
        }
    },
    emits: [ 'onClick' ]
};
</script>

<style scoped></style>
