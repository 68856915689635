<template>
    <h3
        v-if="heading"
        class="text-xl mb-2"
    >
        {{ heading }}
    </h3>
    <ul
        class="list-disc list-inside marker:text-orange p-4 border-2 border-current max-w-5xl"
    >
        <li
            v-for="item in list"
            :key="item.id"
        >
            {{ item.text }}
        </li>
    </ul>
</template>

<script>
export default {
    name: "ExchangeList",
    props: {
        heading: {
            type: String,
            default: ""
        },
        list: {
            type: Array,
            required: true
        }
    }
};
</script>

<style scoped></style>
