<template>
    <div class="flex flex-wrap justify-center max-w-[250px] align-baseline">
        <slot name="icon" />
        <div class="w-full text-center">
            <slot name="text" />
        </div>
    </div>
</template>

<script>
export default {
    name: "MissionElement"
}
</script>

<style scoped>

</style>