<template>
  <div class="wrapper">
    <div class="content">
      <nav-bar-horizontal
          :show-menu="showMenu"
          @closeMenu="closeMenu"
          @openMenu="openMenu"/>
      <div class="px-6 mt-[65px]">
        <router-view/>
      </div>
    </div>
    <footer class="footer bg-orange border-2 border-black mt-8 flex w-full justify-between p-4">
      <router-link to="/">
        <img
            class="object-contain max-w-[50px] sm:max-w-[35px]"
            src="../assets/ITARDA.png"
            alt="IT-кластер ARDA"
        >
      </router-link>
      <a
          href="mailto:it@arda.digital"
          class="block underline"
      >
        it@arda.digital</a>
    </footer>
  </div>
</template>

<script>
import NavBarHorizontal from "@/components/Navigation/NavBarHorizontal";

export default {
  name: "ProfileLayout",
  components: {NavBarHorizontal},
  data() {
    return {
      showMenu: false
    };
  },
  watch: {
    $route() {
      this.closeMenu();
    }
  },
  methods: {
    closeMenu() {
      this.showMenu = false;
    },
    openMenu() {
      this.showMenu = true;
    }
  }
};
</script>

<style scoped>
html,
body {
  height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex-grow: 1;
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex: 0 0 auto;
}
</style>
