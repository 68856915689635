<template>
    <a
        download
        target="_blank"
        :href="url"
        class="flex gap-2 items-center cursor-pointer item border-black border-2 px-4"
    >
        <span>
            Скачать отчет
        </span>
        <img
            width="20"
            height="20"
            src="@/assets/html.svg"
            alt=""
        >
    </a>
</template>

<script>
export default {
    name: "ReportsDownload",
    props: {
        url: {
            type: String,
            required: true
        }
    }
}
</script>
