<template>
    <div class="flex flex-wrap mb-4">
        <div class="flex group w-full">
            <span>{{ start }}</span>
            <input
                v-model="value"
                type="range"
                :min="start"
                :max="end"
                :step="step"
            >
            <span>{{ end }}</span>
        </div>
        <p class="text-xs font-light">
            {{ start }} — {{ startDescription }}, а {{ end }} — {{ endDescription }}.
        </p>
    </div>
</template>

<script>
export default {
    name: "BaseRange",
    props: {
        modelValue: {
            type: [ Number, String ],
            required: true
        },
        start: {
            type: [ Number, String ],
            required: true
        },
        end: {
            type: [ Number, String ],
            required: true
        },
        step: {
            type: Number,
            required: true
        },
        startDescription: {
            type: String,
            required: true
        },
        endDescription: {
            type: String,
            required: true
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            }
        }
    }
}
</script>

<style scoped>
input[type=range] {
  -webkit-appearance: none;
  margin: 10px;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: #F6E5CC;
}
input[type=range]::-webkit-slider-thumb {
  height: 10px;
  width: 10%;
  background: #FBB724;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -2px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #F6E5CC;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: #F6E5CC;
}
input[type=range]::-moz-range-thumb {
  height: 10px;
  width: 10px;

  background: #FBB724;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 10px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #F6E5CC;
}
input[type=range]::-ms-fill-upper {
  background: #F6E5CC;
}
input[type=range]::-ms-thumb {
  height: 10px;
  width: 10px;
  background: #FBB724;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #F6E5CC;
}
input[type=range]:focus::-ms-fill-upper {
  background: #F6E5CC;
}
</style>
