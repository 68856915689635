<template>
    <div>
        <h3
            v-if="heading"
            class="text-xl mb-2"
            v-text="heading"
        />
        <p
            class="pr-support-chat-rules list-disc list-inside marker:text-orange p-4 border-2 border-current max-w-5xl"
            v-html="content"
        />
    </div>
</template>

<script>

export default {
    name: "PrSupportChatRules",

    props: {
        heading: {
            type: String,
            default: ''
        },

        content: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>
.pr-support-chat-rules :deep(a) {
    text-decoration: underline;
}

.pr-support-chat-rules :deep(a:hover) {
    text-decoration: none;
}
</style>
