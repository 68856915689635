<template>
    <div
        class="relative z-40"
        :class="{'z-50': toggle}"
        @mouseover="showTooltip"
        @focus="showTooltip"
        @mouseout="hideTooltip"
    >
        <div class="cursor-pointer">
            <svg
                aria-haspopup="true"
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-info-circle"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#212223"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
                <path
                    stroke="none"
                    d="M0 0h24v24H0z"
                />
                <circle
                    cx="12"
                    cy="12"
                    r="9"
                />
                <line
                    x1="12"
                    y1="8"
                    x2="12.01"
                    y2="8"
                />
                <polyline points="11 12 12 12 12 16 13 16" />
            </svg>
        </div>
        <div
            id="tooltip"
            role="tooltip"
            class="z-50 max-w-[230px] w-full absolute -mt-4 md:-mt-0 left-0 ml-6 md:ml-0 sm:left-[0vw] shadow-[0_0_6px_0px_rgba(169,145,123,0.2)] bg-white p-2"
            :class="{'hidden': !toggle}"
        >
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: "ToolTip",
    data() {
        return {
            toggle: false,
        }
    },
    methods: {
        showTooltip() {
            this.toggle = true
        },
        hideTooltip() {
            this.toggle = false
        }
    }
}
</script>