import { API_REQUEST } from "@/constants";
import axiosInstance from "@/plugins/axios";

export const required = val => {
    if (Array.isArray(val) && val.length !== undefined) { // если массив, то валидируем его длинну
        return !!val.length
    }

    return !!val;
}

export const isValidPrice = string => {
    if (!string.length) return true;

    const reg = /^[0-9]+$/;

    return reg.test(string);
};

export const isValidEmail = email => {
    // eslint-disable-next-line no-useless-escape
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,9})$/;
    return reg.test(email) === true;
};

// ожидаю дату в формате dd.mm.yyyy
export const isValidDate = date => {
    return date.replace(/\s/g, "").length === 10;
};

export const isValidVcBlogUrl = url => {
    return /^https:\/\/vc\.ru\//.test(url);
}

export const formattedDate = string => {
    const [ day, month, year ] = string.split('.');
    const date = new Date();
    date.setDate(day);
    date.setMonth(month - 1);
    date.setFullYear(year);

    return date
}

export const overflowShow = () => {
    document.body.style.overflow = "auto";
    document.body.style.marginRight = '';
};

export const overflowHidden = () => {
    document.body.style.overflow = "hidden";
    document.body.style.marginRight = getScrollbarSize() + "px";
};

function getScrollbarSize() {
    var outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.width = '100px';
    outer.style.msOverflowStyle = 'scrollbar';
    document.body.appendChild(outer);
    var widthNoScroll = outer.offsetWidth;

    outer.style.overflow = 'scroll';

    var inner = document.createElement('div');
    inner.style.width = '100%';
    outer.appendChild(inner);
    var widthWithScroll = inner.offsetWidth;

    outer.parentNode.removeChild(outer);

    var scrollWidth;

    if (document.body.offsetHeight > window.innerHeight) {
        scrollWidth = widthNoScroll - widthWithScroll;
    } else {
        scrollWidth = 0;
    }

    return scrollWidth;
}

export const trim = value => {
    if (typeof value === 'string') {
        return value.trim()
    }
    return value
}

export const addIconByLink = (html, link, className) => {
    if (!html) return;
    try {
        const node = html.querySelectorAll(`a[href*="${link}"]`);
        if (!node) return
        node.forEach(item => {
            item.classList.add('s-icon')
            item.classList.add(className)
        })
    } catch (e) {
        console.error(e)
    }
}

export const addTelegramIcon = html => {
    addIconByLink(html, 't.me', 'telegram')
}

export const addFbIcon = html => {
    addIconByLink(html, 'facebook', 'facebook')
}

export const axiosGet = async request => {
    return axiosInstance.get(API_REQUEST[request]);
};

export const axiosGetId = async (request, id) => {
    return axiosInstance.get(`${API_REQUEST[request]}/${id}`);
};

export const axiosPost = async (request, data) => {
    return axiosInstance.post(API_REQUEST[request], data)
};

export function pipe(...functions) {
    return input => functions.reduce((cumulativeOutput, fn) => {
        return fn(cumulativeOutput)
    }, input);
}

export function hasPlainText(html) {
    if (!html) {
        return false;
    }

    const plainText = convertHtmlToPlainText(html);
    return plainText.length > 0;
}

function convertHtmlToPlainText(html) {
    return pipe(removeHtmlTags, normalizeWhitespace)(html);
}

function removeHtmlTags(html) {
    return html.replace(/<[^>]*>/g, '');
}

function normalizeWhitespace(string) {
    return string.replace(/\s+/, ' ').trim();
}

export function sortListBySortingField(firstElem, secondElem) {
    return firstElem.sorting - secondElem.sorting;
}
