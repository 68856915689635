<template>
    <main-templates v-slot="scope">
        <page-loading :status="status">
            <div class="max-w-screen-2xl mx-auto px-5">
                <intro-item
                    v-if="data.intro"
                    ref="test"
                    :title="data.intro.heading"
                    :text="data.intro.text"
                    :button-text="data.intro.buttonText"
                    @on-click="scope.openModal"
                />
                <base-agenda
                    v-if="data.agenda"
                    :heading="data.agenda.heading"
                    :text="data.agenda.text"
                />
                <problems-grid
                    v-if="data.problems"
                    :heading="data.problems.heading"
                    :list="data.problems.list"
                />
                <mission-item
                    v-if="data.mission"
                    :heading="data.mission.heading"
                    :list="data.mission.list"
                />
                <profit-item
                    v-if="data.advantage"
                    :heading="data.advantage.heading"
                    :list="data.advantage.list"
                />
                <participation-item
                    v-if="data.entry"
                    :heading="data.entry.heading"
                    :list="data.entry.list"
                />
                <feedback
                    v-if="data.feedback"
                    :heading="data.feedback.heading"
                    :list="data.feedback.list"
                />
					
                <entry-block
                    v-if="data.accede"
                    :heading="data.accede.heading"
                    :button-text="data.accede.buttonText"
                    @on-click="scope.openModal"
                />
                <participants-grid :list="data.members" />
                <arda-stats
                    v-if="data.stats"
                    :heading="data.stats.heading"
                    :list="data.stats.list"
                    :button-text="data.stats.buttonText"
                />
                <arda-item
                    v-if="data.join"
                    :title="data.join.heading"
                    :text="data.join.text"
                    :button-text="data.join.buttonText"
                />
            </div>
        </page-loading>
    </main-templates>
</template>

<script>
import IntroItem from "@/components/Intro/Items";
import ProblemsGrid from "@/components/Landing/ProblemsGrid";
import MissionItem from "@/components/Landing/Mission";
import ProfitItem from "@/components/Landing/Profit/Item";
import ParticipationItem from "@/components/Participation/Item";
import ArdaItem from "@/components/Arda/ArdaItem";
import ParticipantsGrid from "@/components/Landing/Participants/Grid";
import { loadData } from "@/use/load";
import { mainPage } from "@/api";
import { toRef } from "vue";
import EntryBlock from "@/components/EntryBlock/EntryBlock";
import ArdaStats from "@/components/Arda/ArdaStats";
import BaseAgenda from "@/components/Landing/BaseAgenda";
import MainTemplates from "@/pages/MainTemplates";
import PageLoading from "@/components/PageLoading";
import Feedback from "@/components/Landing/Feedback/Feedback";
import { mainHandler } from "@/handlers";

export default {
    name: "LandingPage",
    components: {
        PageLoading,
        MainTemplates,
        BaseAgenda,
        ArdaStats,
        ParticipationItem,
        ProfitItem,
        EntryBlock,
        MissionItem,
        IntroItem,
        ProblemsGrid,
        ArdaItem,
        ParticipantsGrid,
        Feedback
    },
    setup() {
        const { data: response, status } = loadData({ query: mainPage, handler: mainHandler });
        const data = toRef(response, "data");

        return {
            data,
            status
        };
    }
};
</script>

<style></style>
