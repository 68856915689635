<template>
    <div
        v-if="webinars"
        class="flex mb-10 flex-wrap w-full"
    >
        <div
            v-for="webinar in webinars"
            :key="webinar.id"
            class="flex w-1/4 sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3 p-2"
        >
            <webinar-card
                :image="webinar.image"
                :time="webinar.time"
                :heading="webinar.heading"
                :description="webinar.description"
                :link="webinar.link"
            />
        </div>
    </div>
    <div
        v-if="error"
        class="text-xl mb-2 text-center"
    >
        {{ message }}
    </div>
    <div
        v-if="empty"
        class="text-xl mb-2 text-center"
    >
        Список вебинаров пуст
    </div>
</template>

<script>

import WebinarCard from "@/components/WebinarsPage/WebinarCard";

export default {
    name: 'WebinarBoard',
    components: { WebinarCard },
    props: {
        error: {
            type: Boolean,
            required: true
        },
        message: {
            type: String,
            default: ''
        },
        webinars: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        empty() {
            return !this.error && !this.webinars.length
        }
    }
}
</script>

<style scoped>

</style>
