<template>
    <query-modal
        :error="status.isError"
        :pending="status.isPending"
        :success="status.isSuccess"
        @close="closeModal"
    >
        <template #modal>
            <h2 class="text-lg font-medium mb-4">
                Новое событие
            </h2>
            <form
                action=""
                class="max-w-[340px]"
                @submit.prevent="onSubmit"
            >
                <base-input
                    v-model.trim="field.name.value"
                    class="mb-6 w-full"
                    label="Название"
                    type="text"
                    placeholder="Название мероприятия"
                    :error="field.name.isError"
                    :message="field.name.message"
                    @input="field.name.input"
                    @blur="field.name.blur"
                />

                <base-input
                    v-model.trim="field.type.value"
                    class="mb-6 w-full"
                    label="Формат мероприятия (онлайн/оффлайн)"
                    type="text"
                    placeholder="Тип мероприятия"
                    :error="field.type.isError"
                    :message="field.type.message"
                    @blur="field.type.blur"
                    @input="field.type.input"
                />

                <input-date
                    v-model="field.start.value"
                    class="mb-6 w-full"
                    label="Дата начала"
                    placeholder="Дата начала"
                    :error="field.start.isError"
                    :message="field.start.message"
                    @blur="field.start.blur"
                    @input="field.start.input"
                />

                <input-date
                    v-model="field.end.value"
                    class="mb-6 w-full"
                    label="Дата окончания"
                    type="text"
                    placeholder="Дата окончания"
                    :error="field.end.isError"
                    :message="field.end.message"
                    @blur="field.end.blur"
                    @input="field.end.input"
                />

                <base-input
                    v-model.trim="field.city.value"
                    class="mb-6 w-full"
                    label="Город"
                    type="text"
                    placeholder="Место проведения"
                    :error="field.city.isError"
                    :message="field.city.message"
                    @input="field.city.input"
                    @blur="field.city.blur"
                />

                <base-input
                    v-model.trim="field.link.value"
                    class="mb-6 w-full"
                    label="Сайт/Ссылка на регистрацию"
                    type="text"
                    placeholder="Ссылка на регистрацию"
                    :error="field.link.isError"
                    :message="field.link.message"
                    @input="field.link.input"
                    @blur="field.link.blur"
                />

                <enter-button
                    type="submit"
                    class="w-full"
                >
                    Отправить
                </enter-button>
                <p
                    v-if="error && status.isError"
                    class="mt-4 mb-2 text-red font-thin text-center"
                >
                    {{ error }}
                </p>
            </form>
        </template>
        <template #success>
            <p class="mt-8 mb-3">
                Заявка на добавление мероприятия успешно отправлена
            </p>
        </template>
    </query-modal>
</template>

<script>
import EnterButton from "@/components/Enter/Button";
import BaseInput from "@/UI/BaseInput";
import InputDate from "@/UI/InputDate";
import { submit } from "@/use/submit";
import { createNewEvent } from "@/api/formattedResponse";
import { toRef } from "vue";
import newEvent from "@/configForms/newEvent";
import QueryModal from "@/components/QueryModal";

export default {
    name: "CalendarModal",
    components: { InputDate, EnterButton, BaseInput, QueryModal },
    setup() {
        const init = submit({
            submit: createNewEvent,
            fields: newEvent
        });

        const form = toRef(init, "form");
        const callback = toRef(init, "onSubmit");
        const status = toRef(init, "status");
        const error = toRef(init, "error");
        return { form, submit: callback, status, error };
    },

    computed: {
        field() {
            const { name, type, start, end, city, link } = this.form.fields;
            return { name, type, start, end, city, link };
        }
    },
    methods: {
        closeModal() {
            this.$emit("closeModal");
        },

        onSubmit() {
            if (!this.form.valid) {
                this.form.showErrors()
                return;
            }
            this.submit();
        }
    }
};
</script>

<style scoped></style>
