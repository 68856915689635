<template>
    <page-loading
        :status="status"
        :errors="errors?.empty"
        :message="errors?.empty"
    >
        <div class="flex flex-col justify-between gap-4 relative p-4 border-2 border-current">
            <div class="flex flex-row gap-2 items-center">
                <h3
                    class="text-lg font-bold"
                >
                    Управление аккаунтами
                </h3>
                <help-user />
            </div>
            <div v-if="!list.length">
                <h4 class="text-gray-400">
                    Здесь будут отображаться сотрудники привязанные к вашей компании
                </h4>
                <h4 class="text-gray-400">
                    Вы можете отправить им ссылку на регистрацию или добавить их самостоятельно
                </h4>
            </div>
            <div
                v-if="list.length"
                class="flex flex-col h-full w-full gap-4"
            >
                <div class="grid grid-cols-12 items-baseline gap-2">
                    <h4 class="text-xs text-gray-400 col-span-5">
                        Фамилия Имя
                    </h4>
                    <h4 class="text-xs text-gray-400 col-span-3">
                        Роль
                    </h4>
                    <h4 class="text-xs text-gray-400 col-span-3">
                        Статус
                    </h4>
                    <h4 class="text-xs text-gray-400 justify-self-center">
                        Удалить
                    </h4>
                </div>
                <div
                    v-for="user of list"
                    :key="user"
                    class="grid grid-cols-12 items-center gap-2"
                >
                    <p class="col-span-5">
                        {{ user.name }} {{ user.lastName }}
                    </p>
                    <p class="col-span-3">
                        {{ user.userPermissions }}
                    </p>
                    <div class="text-xs col-span-3">
                        <span
                            v-if="!user.confirmed"
                            class="p-1 bg-pink cursor-pointer"
                            @click="openModalActivateProfile(user)"
                        >
                            Не активен
                        </span>
                    </div>
                    <icon-delete
                        class="justify-self-center"
                        @click="openModalDeleteProfile(user)"
                    />
                </div>
            </div>
            <div class="mt-auto flex flex-row lg:flex-col gap-4">
                <enter-button @click="toggleModalCreate">
                    Добавить сотрудника
                </enter-button>
            </div>
        </div>
    </page-loading>
	
    <activate-profile
        v-if="modalActivateProfile"
        :user="selectedUser"
        @update-list="updateList"
        @close-modal="closeModalActivateProfile"
    />

    <blocked-profile
        v-if="modalDeleteProfile"
        :user="selectedUser"
        @update-list="updateList"
        @close-modal="closeModalDeleteProfile"
    />

    <create-employee
        v-if="showCreate"
        @close-modal-create="toggleModalCreate"
        @update-list="updateList"
    />
</template>

<script>
import IconDelete from "@/UI/Icons/IconDelete.vue";
import CreateEmployee from "@/components/ProfilePage/CreateEmployee";
import { toRef } from "vue";
import { getCompanyUsers } from "@/api";
import { loadData } from "@/use/load";
import { mainHandler } from "@/handlers";
import PageLoading from "@/components/PageLoading";
import EnterButton from "@/components/Enter/Button";
import ActivateProfile from "@/components/ProfilePage/ActivateProfile";
import BlockedProfile from "@/components/ProfilePage/BlockedProfile";
import HelpUser from "@/components/ProfilePage/HelpUser";

export default {
    name: "UsersList",
    components: { HelpUser, BlockedProfile, ActivateProfile, EnterButton, PageLoading, IconDelete, CreateEmployee },
    setup() {
        const { data: response, status } = loadData({ query: getCompanyUsers, handler: mainHandler });
        const data = toRef(response, "data");
        const errors = toRef(response, "errors");

        return {
            list: data,
            status,
            errors
        };
    },
    data() {
        return {
            modalActivateProfile: false,
            modalDeleteProfile: false,
            selectedUser: null,
            showCreate: false
        };
    },
    methods: {
        openModalActivateProfile(user) {
            this.selectedUser = user
            this.modalActivateProfile = true
        },
        closeModalActivateProfile() {
            this.modalActivateProfile = false
        },
        openModalDeleteProfile(user) {
            this.selectedUser = user
            this.modalDeleteProfile = true;
        },
        closeModalDeleteProfile() {
            this.modalDeleteProfile = false;
        },
        toggleModalCreate() {
            this.showCreate = !this.showCreate;
        },
        updateList() {
            getCompanyUsers().then(response => {
                return this.list = response.data.data;
            }).catch(() => {
                console.error(err)
            })
        }
    }
};
</script>
