<template>
    <query-modal
        :success="status.isSuccess"
        :error="status.isError"
        :pending="status.isPending"
        @close="closeModalPass"
    >
        <template #modal>
            <form
                class="max-w-[300px]"
                @submit.prevent="onSubmit"
            >
                <div class="flex justify-between items-center mb-4">
                    <h2 class="text-lg font-medium">
                        Изменить пароль
                    </h2>
                </div>

                <input-password
                    v-model.trim="oldPassword.value"
                    class="mb-6"
                    label="Старый пароль"
                    placeholder="Введите пароль"
                    :error="oldPassword.touched && oldPassword.error"
                    :message="oldPassword.message"
                    @input="onInput('oldPassword')"
                    @blur="onBlur('oldPassword')"
                />
                <input-password
                    v-model.trim="newPassword.value"
                    class="mb-6"
                    label="Новый пароль"
                    placeholder="Введите пароль"
                    :error="newPassword.touched && newPassword.error"
                    :message="newPassword.message"
                    @blur="onBlurNewPassword('newPassword')"
                    @input="onInput('newPassword')"
                />
                <input-password
                    v-model.trim="repeatPassword.value"
                    class="mb-6"
                    label="Повторите пароль"
                    placeholder="Введите пароль"
                    :error="repeatPassword.touched && repeatPassword.error"
                    :message="repeatPassword.message"
                    @blur="onBlurNewPassword('repeatPassword')"
                    @input="onInput('repeatPassword')"
                />

                <enter-button
                    type="submit"
                    class="w-full mb-6"
                >
                    Сохранить
                </enter-button>
            </form>
        </template>
        <template #success>
            <p class="mt-8 mb-3">
                Пароль успешно изменен!
            </p>
        </template>
    </query-modal>
</template>

<script>
import EnterButton from "@/components/Enter/Button";
import InputPassword from "@/UI/InputPassword";
import { updatePassword } from "@/api/formattedResponse";
import QueryModal from "@/components/QueryModal";
const errorsMessage = {
    "The provided current password is invalid": "Указанный текущий пароль недействителен",
    "Your new password must be different than your current password":"Новый пароль не может совпадать со старым"
}
export default {
    name: "ChangePass",
    components: {
        EnterButton,
        InputPassword,
        QueryModal
    },
    data() {
        return {
            oldPassword: {
                value: "",
                touched: false,
                error: true,
                message: "Поле не должно быть пустым"
            },
            newPassword: {
                value: "",
                touched: false,
                error: true,
                message: "Поле не должно быть пустым"
            },
            repeatPassword: {
                value: "",
                touched: false,
                error: true,
                message: "Поле не должно быть пустым"
            },
            status: {
                isPending: false,
                isError: false,
                isSuccess: false
            },
            message: ''
        };
    },
    computed: {
        isSamePassword() {
            return this.newPassword.value === this.repeatPassword.value;
        },
        isValidForm() {
            return (
                !this.oldPassword.error &&
                !this.newPassword.error &&
                !this.repeatPassword.error
            );
        },
        isFilledNewPassword() {
            if (this.newPassword.value === "") {
                this.newPassword.message = "Поле не должно быть пустым"
            }

            if (this.repeatPassword.value === "") {
                this.repeatPassword.message = "Поле не должно быть пустым"
            }

            return this.newPassword.value !== "" && this.repeatPassword.value !== "";
        }
    },
    methods: {
        showErrors() {
            this.oldPassword.touched = true;
            this.newPassword.touched = true;
            this.repeatPassword.touched = true;
        },

        onSubmit() {
            if (!this.oldPassword.value) {
                this.oldPassword.message = "Поле не должно быть пустым"
                this.oldPassword.error = true
                this.showErrors();
                return;
            }
            if (!this.isFilledNewPassword) {
                this.showErrors();
                return;
            }
            if (!this.isValidForm) {
                this.showErrors();
                return;
            }
            this.status.isPending = true

            const formatted = {
                currentPassword: this.oldPassword.value,
                password: this.newPassword.value,
                passwordConfirmation: this.repeatPassword.value
            }

            updatePassword(formatted).then(() => {
                this.status.isSuccess = true
                this.status.isPending = false
            }).catch(error => {
                this.oldPassword.error = true;
                this.oldPassword.message = errorsMessage[error.response.data.error.message];

                this.status.isSuccess = false
                this.status.isPending = false
                this.status.isError = true
            })
        },

        onBlurNewPassword(type) {
            this.onBlur(type);
            const valid = this[type].value.length >= 6

            if (!valid) {
                this[type].error = true;
                this[type].message = "Длина пароля не должна быть не менее 6 символов"
                return
            }

            if (this.isFilledNewPassword) {
                this.newPassword.error = !this.isSamePassword;
                this.repeatPassword.error = !this.isSamePassword;
                this.newPassword.message = 'Пароли должны совпадать';
                this.repeatPassword.message = 'Пароли должны совпадать';
            }
        },

        onBlur(type) {
            this[type].touched = true;
            this[type].error = this[type].value === "";
        },

        onInput(type) {
            this[type].touched = false;
            this.oldPassword.error = false;
            this.oldPassword.message = '';
        },

        closeModalPass() {
            return this.$emit("closeModalPass");
        }
    }
};
</script>
