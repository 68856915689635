<template>
    <div>
        <p
            v-if="title"
            class="mb-2"
        >
            {{ title }}
        </p>
        <div class="flex flex-col">
            <div
                v-for="variant in variants"
                :key="variant.id"
                class="block w-full"
            >
                <label
                    class="relative w-full"
                    :for="variant.id"
                >
                    <input
                        :id="variant.id"
                        v-model="variant.value"
                        class="hidden group peer"
                        type="checkbox"
                        :value="variant.id"
                        :name="variant.name"
                        @change="upendData(variant.id)"
                    >
                    <div class="relative inline-block group focus:outline-none focus:ring">
                        <span
                            class="absolute h-7 w-7 inset-0 transition-transform translate-x-1.5 translate-y-1.5 bg-orange-light group-hover:translate-y-0 group-hover:translate-x-0"
                        />
                        <span
                            class="relative inline-block h-7 w-7 p-2 border-2 border-current"
                        />
                    </div>

                    <img
                        class="absolute -top-2 left-1 h-5 w-5 hidden peer-checked:block"
                        src="../assets/checking-mark.png"
                        alt=""
                    >
					<span class="absolute ml-3 mt-1.5">
                    {{ variant.name }}
                </span>
                </label>

            </div>
        </div>
        <span
            v-if="error"
            class="mt-2 text-red block text-xs font-thin"
        >
            {{ textError }}
        </span>
    </div>
</template>

<script>
import { REQUIRED_FIELD } from "@/constants";
import BaseCheckbox from "@/UI/BaseCheckbox";

export default {
    name: "GroupCheckbox",
    components: { BaseCheckbox },
    props: {
        values: {
            type: Array
        },
        title: {
            type: String
        },
        error: {
            type: Boolean,
            default: false
        },
        variants: {
            type: Array,
            required: true
        },
        textError: {
            type: String,
            default: REQUIRED_FIELD
        }
    },
    data() {
        return {
            data: []
        }
    },
    methods: {
        upendData(id) {
            let index = this.data.indexOf(id);

            if (index !== -1) {
                this.data.splice(index, 1);
            }

            if (index === -1) {
                this.data.push(id);
            }
            this.$emit('select', this.data)
        }
    }
}
</script>

<style scoped>

</style>