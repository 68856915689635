<template>
    <h2 class="text-2xl font-bold mb-2">
        <slot />
    </h2>
    <div class="grid grid-cols-3 items-start lg:grid-cols-2 sm:grid-cols-1 gap-5 mb-10">
        <articles-item
            v-for="item of list"
            :key="item.id"
            :link="item.link"
            :heading="item.heading"
        />
    </div>
</template>

<script>
import ArticlesItem from "@/components/MainPage/ArticlesItem";
export default {
    name: "ArticlesList",
    components: { ArticlesItem },
    props: {
        list: Array
    }
}
</script>

<style scoped>

</style>