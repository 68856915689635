<template>
    <div class="w-full mb-5 relative border-4 p-5">
        <div v-html="messageText" />
    </div>
</template>

<script>
export default {
    name: "MessageItem",
    components: { },
    props: {
        messageText: {
            type: String,
            required: true
        }
    }
};
</script>

<style module>
</style>
