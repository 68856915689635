<template>
  <page-loading
      :message="errors?.empty"
      :status="status"
      :empty="errors?.empty"
  >
	  <button-up />

	  <resource-content
        :heading="data.heading"
        :cards="data.list"
        :message="errors?.list"
        :is-error="!!errors?.list"
    />
  </page-loading>
</template>

<script>
import {loadData} from "@/use/load";
import {mapPage} from "@/api";
import {toRef} from "vue";
import PageLoading from "@/components/PageLoading";
import ResourceContent from "@/components/ResourcePage/ResourceContent";
import {listHandler} from "@/handlers";
import ButtonUp from "@/components/Reports2/components/ButtonUp";

export default {
  name: "ResourcePage",
  components: {
	  ButtonUp,
    ResourceContent,

    PageLoading
  },
  setup() {
    const { data: response, status } = loadData({ query: mapPage, handler: listHandler });
    const data = toRef(response, "data");
    const errors = toRef(response, "errors");

    return {
      data,
      errors,
      status
    };
  }
};
</script>

<style scoped></style>
