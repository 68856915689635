import { isValidVcBlogUrl } from "@/helpers";
import { vcBlogAnalyticsConstants } from "@/constants";

export const vcBlogUrlFormFields = {
    url: {
        value: "",
        validators: [
            {
                validator: isValidVcBlogUrl,
                message: vcBlogAnalyticsConstants.INVALID_BLOG_URL_MESSAGE
            }
        ]
    }
}
