import { isValidDate, isValidPrice, required } from "@/helpers";
import { INVALID_DATE, INVALID_PRICE, REQUIRED_FIELD } from "@/constants";
import store from "@/store";

export default () => {
    const { id, heading, dateStart, dateEnd, type, city, registrationLink, transcriptLink, tag, priceStart, priceEnd, description, publishedAt } = store.state.eventCard
    return {
        id: {
            value: id,
            validators: []
        },
        heading: {
            value: heading,
            validators: [
                {
                    validator: required,
                    message: REQUIRED_FIELD
                }
            ]
        },
        dateStart: {
            value: dateStart,
            validators: [
                {
                    validator: required,
                    message: REQUIRED_FIELD
                },
                {
                    validator: isValidDate,
                    message: INVALID_DATE
                }
            ]
        },
        dateEnd: {
            value: dateEnd,
            validators: [
                {
                    validator: required,
                    message: REQUIRED_FIELD
                },
                {
                    validator: isValidDate,
                    message: INVALID_DATE
                }
            ]
        },
        type: {
            value: type || '',
            validators: [
                {
                    validator: required,
                    message: REQUIRED_FIELD
                }
            ]
        },
        city: {
            value: city,
            validators: []
        },
        registrationLink: {
            value: registrationLink,
            validators: [
            ]
        },
        transcriptLink: {
            value: transcriptLink,
            validators: [
            ]
        },
        tag: {
            value: tag,
            validators: []
        },
        priceStart: {
            value: priceStart || '',
            validators: [
                {
                    validator: isValidPrice,
                    message: INVALID_PRICE
                }
            ]
        },
        priceEnd: {
            value: priceEnd || '',
            validators: [
                {
                    validator: isValidPrice,
                    message: INVALID_PRICE
                }
            ]
        },
        description: {
            value: description,
            validators: [
                {
                    validator: required,
                    message: REQUIRED_FIELD
                }
            ]
        },
        publishedAt: {
            value: publishedAt,
            validators: []
        },
        newCity: {
            value: '',
            validators: []
        }
    }
}
