<template>
    <page-loading
        :message="errors?.empty"
        :status="status"
        :empty="errors?.empty"
    >
        <div
            class="calendar flex flex-wrap mb-10 items-center justify-between max-w-full gap-4"
        >
            <div>
                <h2
                    v-if="data.data.attributes.heading"
                    class="text-2xl mb-2"
                >
                    {{ data.data.attributes.heading }}
                </h2>
                <div
                    v-if="data.data.attributes.subheading"
                    class="max-w-[600px] subheading"
                    v-html="data.data.attributes.subheading"
                />
            </div>
            <div class="flex flex-wrap gap-4">
                <enter-button
                    class="ml-auto"
                    @click="toggleModal"
                >
                    Добавить событие
                </enter-button>
                <enter-button
                    v-if="isAdmin"
                    class="ml-auto"
                    @click="toggleMode"
                >
                    {{ mode.title }}
                </enter-button>
            </div>
        </div>
		
        <calendar-board
            :message="errors?.list"
            :error="!!errors?.list"
            :events="data.list"
        />
        <div class="relative w-full mx-auto max-w-[600px]">
            <partner-banner
                :logo-url="data.data.attributes.partner.logo.data.attributes.url"
                :website-link-url="data.data.attributes.partner.websiteUrl"
                :website-link-text="data.data.attributes.partner.linkLabel"
            />
        </div>
        <calendar-modal
            v-if="addEvent"
            @close-modal="toggleModal"
        />
    </page-loading>
</template>

<script>
import CalendarModal from "@/components/CalendarPage/CalendarModal";
import EnterButton from "@/components/Enter/Button";
import PartnerBanner from "@/components/VcBlogAnalytics/components/PartnerBanner";
import { loadData } from "@/use/load";
import { calendarPage, getUser } from "@/api";
import { toRef } from "vue";
import PageLoading from "@/components/PageLoading";
import CalendarBoard from "@/components/CalendarPage/CalendarBoard";
import { listHandler } from "@/handlers";
import { saveUserData } from "@/api/formattedResponse";

export default {
    name: "CalendarPage",
    components: { CalendarBoard, PageLoading, EnterButton, CalendarModal, PartnerBanner },
    setup() {
        const { data: response, status } = loadData({ query: calendarPage, handler: listHandler });
		
        const data = toRef(response, "data");
        const errors = toRef(response, "errors");

        getUser().then(response => {
            const { data: user } = response
            saveUserData(user.data);
            return response
        }).catch(error => {
            return Promise.reject(error);
        })

        return {
            status,
            data,
            errors,
            response
        };
    },
    data() {
        return {
            addEvent: false,
            mode: {
                admin: false,
                title: 'Режим админа'
            }
        }
    },
    computed: {
        isAdmin() {
            return this.$store.getters['getPermissionChangeCalendar']
        }
    },
    methods: {
        toggleModal() {
            this.addEvent = !this.addEvent;
        },
        toggleMode() {
            this.$store.commit('toggleCalendarAdminMode')
            this.mode.admin = !this.mode.admin
            this.mode.admin ? this.mode.title = "Режим просмотра" : this.mode.title = "Режим админа"
        }
    }
};
</script>

<style>
.calendar .subheading  a {
	background: #F6E5CC;
}
</style>
