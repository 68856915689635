<template>
    <div class="flex flex-wrap relative w-full">
        <span
            class="w-full text-xs font-thin mb-1"
        >
            Компания
        </span>
        <div class="relative block group w-full">
            <span
                class="absolute inset-0 transition-transform translate-x-1.5 translate-y-1.5 bg-orange-light group-hover:translate-y-0 group-hover:translate-x-0 group-focus:translate-y-0 group-focus:translate-x-0"
            />
            <multiselect
                v-model="value"
                class="border-current border-2 multiselect-custom"
                :options="options"
                :searchable="true"
                :show-labels="true"
                :loading="status.isPending"
                select-label="+"
                deselect-label="-"
                selected-label=""
                placeholder="Выберите компанию"
                label="company"
                track-by="company"
            >
                <template #noOptions>
                    <span class="text-sm font-bold tracking-widest text-black">Нет вариантов для выбора</span>
                </template>
                <template #noResult>
                    <span class="text-sm font-bold tracking-widest text-black">Не найдено</span>
                </template>
            </multiselect>
        </div>
        <span
            v-if="error"
            class="mt-2 text-red inline-block text-xs font-thin"
        >
            {{ message }}
        </span>
    </div>
</template>

<script>
import { toRef } from "vue";
import { loadData } from "@/use/load";
import { getAllCards } from "@/api";
import Multiselect from "vue-multiselect";
import { REQUIRED_FIELD } from "@/constants";
import mainHandler from "../../handlers/mainHandler";

export default {
    name: "SelectCompany",
    components: { Multiselect },
    props: {
        modelValue: {
            type: Object,
            required: true
        },

        error: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
            default: REQUIRED_FIELD
        },
    },
    emits: [ "onSelect" ],
    setup() {
        const { data: response, status } = loadData({ query: getAllCards, handler: mainHandler, initialValue: [] });
        const data = toRef(response, "data");
        return {
            data,
            status
        };
    },
    computed: {
        options() {
            if (!this.data?.length) return [];

            let options = this.sortOptions([ ...this.data ]);
			
            return options || [];
        },
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("onSelect", { ...value });
            }
        }
    },
    methods: {
        sortOptions(options) {
            return [ ...options ].sort((a, b) => {
                return a.company.localeCompare(b.company);
            }).sort((a, b) => {
                return a.sorting - b.sorting;
            });
        }
    }
};
</script>

<style></style>
