import routes from "@/router/routes";
import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    base: process.env.VUE_APP_PUBLIC_PATH

});
router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next()
});
router.beforeEach((to, from, next) => {
    if (to.name === 'formMap') {
        next()
    }

    if (store.getters.isAuth && !store.getters.user.resourceMap) {
        return next({
            name: 'formMap',
        })
    }

    next()
})

export default router;
