<template>
    <div>
        <div class="mb-4">
            <blog-value-count
                :label="countLabel"
                :count="countForBlog"
            />
        </div>
        <div>
            <percentage-scale
                :percentage="percentage"
                :total-count="countForAllBlogs"
                :total-count-label="totalCountLabel"
            />
        </div>
        <div class="mt-5">
            <criterion-percentile
                :criterion-label="criterionLabel"
                :percentage="percentile"
                :period-label="periodLabel"
            />
        </div>
    </div>
</template>

<script>
import BlogValueCount from "@/components/VcBlogAnalytics/components/BlogValueCount";
import PercentageScale from "@/components/VcBlogAnalytics/components/PercentageScale";
import CriterionPercentile from "@/components/VcBlogAnalytics/components/CriterionPercentile";

export default {
    name: "CriterionStats",

    components: {
        CriterionPercentile,
        PercentageScale,
        BlogValueCount
    },

    props: {
        countLabel: {
            type: String,
            required: true
        },

        totalCountLabel: {
            type: String,
            required: true
        },

        criterionLabel: {
            type: String,
            required: true
        },

        periodLabel: {
            type: String,
            required: true
        },

        countForBlog: {
            type: Number,
            required: true
        },

        countForAllBlogs: {
            type: Number,
            required: true
        },

        percentage: {
            type: Number,
            required: true
        },

        percentile: {
            type: Number,
            required: true
        }
    }
}
</script>

<style scoped>

</style>