import { statusQuery } from "@/constants";
import { reactive, computed } from "vue";

const defaultValue = {};

export function loadData({ query, handler, initialValue = defaultValue, ...props }) {
    let status = reactive({ value: statusQuery.PENDING });
    let data = reactive(initialValue);

    query(props)
        .then(response => {
            const res = handler(response.data)
            if (res.error) {
                return Promise.reject(res.errors)
            }
            status.value = statusQuery.SUCCESS;
            data.data = res.data;
            data.errors = res.errors
        })
        .catch(error => {
            console.log(error)
            status.value = statusQuery.ERROR;
            Object.entries(error).forEach(([ key, value ]) => {
                data[key] = value;
            });
        });

    const currentStatus = computed(() => ({
        isPending: status.value === statusQuery.PENDING,
        isError: status.value === statusQuery.ERROR,
        isSuccess: status.value === statusQuery.SUCCESS
    }));

    return { data, status: currentStatus };
}
