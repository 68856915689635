<template>
	<vue-multiselect
		v-model="value"
		class="border-current border-2 multiselect-custom"
		:options="options"
		:show-labels="false"
		:multiple="true"
		:searchable="false"
		:placeholder="placeholder"
		:loading="isLoading"
		label="title"
		track-by="value"
	>
		<template #selection="{ values }">
            <span
				v-if="values.length"
				class="text-sm font-bold tracking-widest text-black"
			>
				{{ placeholder }}
			</span>
		</template>
		<template #placeholder>
			<span
				class="text-sm font-bold tracking-widest text-black"
			>
				{{ placeholder }}
			</span>
		</template>
		<template #noOptions>
			<span
				class="text-sm font-bold tracking-widest text-black"
			>
				Нет вариантов для выбора
			</span>
		
		</template>
	</vue-multiselect>
</template>

<script>
import VueMultiselect from "vue-multiselect";

export default {
	name: "BaseMultiSelect",
	components: {VueMultiselect},
	props: {
		modelValue: {
			type: Array,
			required: true
		},
		name: {
			type: String,
			required: true
		},
		placeholder: {
			type: String,
			default: ""
		},
		options: {
			type: Array,
			required: true
		},
		isLoading: {
			type: Boolean,
			default: false
		}
	},
	emits: ['onSelect'],
	computed: {
		value: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit("onSelect", {value, name: this.name});
			}
		}
	}
};
</script>

<style>
.multiselect-custom {
	cursor: pointer;
	box-sizing: border-box;
	color: black;
}

.multiselect__spinner {
	background: transparent;
	width: 40px;
	height: 40px;
}

.multiselect__spinner:after, .multiselect__spinner:before {
	border-color: #fbb724 transparent transparent;
}

.multiselect-custom .multiselect__tags {
	min-height: inherit;
	padding: 10px 20px;
	border: none;
	border-radius: 0;
	font-size: 16px;
	line-height: 1.5;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	background: transparent;
}

.multiselect-custom .multiselect__placeholder {
	display: block;
	padding: 0;
	width: 100%;
	margin: 0;
	color: #000;
	font-size: 16px;
	line-height: 1.5;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.multiselect__option {
	padding: 5px;
	min-height: inherit;
}

.multiselect__option--highlight {
	background: #F6E5CC;
	color: #000;
}

.multiselect__option--selected.multiselect__option--highlight {
	background: #F6E5CC;
	color: #000;
}

.multiselect__option--selected {
	background: #F6E5CC;
	color: #000;
}

.multiselect__content-wrapper {
	left: -2px;
	min-width: calc(100% + 4px);
	border-radius: 0;
	width: 100%;
	border: 2px solid #000000;
}

.multiselect--above .multiselect__content-wrapper {
	border: 2px solid #000000;
	border-radius: 0;
}

.multiselect__select {
	padding: 12px 20px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	right: 0;
}

.multiselect__select:before {
	display: block;
	position: initial;
	border-color: #000 transparent transparent transparent;
}
</style>
