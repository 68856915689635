<template>
    <div class="text-center relative">
        <svg
            class="spinner"
            viewBox="0 0 50 50"
        >
            <circle
                class="bg"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                stroke-width="5"
            />
            <circle
                class="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                stroke-width="5"
            />
        </svg>
    </div>
</template>

<script>
export default {
    name: "BaseLoading"
};
</script>

<style scoped>
.spinner {
  animation: rotate 2s linear infinite;
  width: 100%;
  height: 100%;
}

.spinner .path {
  stroke: rgb(251 183 36);
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

.spinner .bg {
  stroke: rgb(251 183 36 / 0.3);
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
