<template>
    <div>
        <p
            class="mb-5"
            v-text="description"
        />
        <div class="flex items-start sm:block">
            <base-input
                v-model.trim="form.fields.url.value"
                class="w-full mr-2 sm:mr-0 mb-0 sm:mb-3"
                :placeholder="inputPlaceholder"
                :error="hasError"
                :message="currentErrorMessage"
            />
            <enter-button @click="validateAndSubmit">
                {{ submitButtonText }}
            </enter-button>
        </div>
    </div>
</template>

<script>
import BaseInput from "@/UI/BaseInput";
import { submit } from "@/use/submit";
import { toRef } from "vue";
import { vcBlogUrlFormFields } from "@/configForms/vcBlogUrl";
import EnterButton from "@/components/Enter/Button";
import { sendVcBlogUrl } from "@/api";
import { vcBlogAnalyticsConstants } from "@/constants";

const ERROR_TYPES = Object.freeze({
    VALIDATION: "VALIDATION",
    DUPLICATION: "DUPLICATION",
    SERVER: "SERVER"
});

function isUrlAlreadyExistsError(axiosError) {
    const error = axiosError?.response?.data?.error?.details?.errors?.[0];

    if (!error) {
        return false;
    }

    return error?.path?.[0] === "url"
        && error?.message === "This attribute must be unique";
}

export default {
    name: "VcBlogUrlInputForm",

    components: {
        BaseInput,
        EnterButton
    },

    props: {
        shouldSubmit: {
            type: Boolean,
            required: true
        },

        submitButtonText: {
            type: String,
            required: true
        },

        description: {
            type: String,
            default: ""
        },

        inputPlaceholder:  {
            type: String,
            default: ""
        }
    },

    emits: [ "submit" ],

    setup() {
        const formConfig = submit({
            fields: vcBlogUrlFormFields
        });

        const form = toRef(formConfig, "form");
        const status = toRef(formConfig, "status");

        return {
            form,
            status
        };
    },

    data() {
        return {
            submissionErrorType: null
        };
    },

    computed: {
        userCompanyId() {
            return this.$store.state.user.resourceMap.id;
        },

        hasValidationError() {
            return this.form.fields.url.isError;
        },

        hasSubmissionError() {
            return this.submissionErrorType !== null;
        },

        hasError() {
            return this.hasValidationError || this.hasSubmissionError;
        },

        currentErrorMessage() {
            if (this.hasValidationError) {
                return this.form.fields.url.message;
            }

            if (this.submissionErrorType === ERROR_TYPES.SERVER) {
                return vcBlogAnalyticsConstants.SERVER_ERROR_MESSAGE;
            }

            if (this.submissionErrorType === ERROR_TYPES.DUPLICATION) {
                return vcBlogAnalyticsConstants.URL_ALREADY_EXISTS_MESSAGE;
            }

            return "";
        }
    },

    methods: {
        async validateAndSubmit() {
            this.submissionErrorType = null;

            if (!this.shouldSubmit) {
                return;
            }

            if (!this.form.valid) {
                this.form.showErrors();
                return;
            }

            sendVcBlogUrl({
                url: this.form.fields.url.value,
                company: this.userCompanyId
            }).then(() => {
                this.$emit("submit", this.form.fields.url.value);
            }).catch(error => {
                this.submissionErrorType = isUrlAlreadyExistsError(error)
                    ? ERROR_TYPES.DUPLICATION
                    : ERROR_TYPES.SERVER;
            });
        }
    }
}
</script>

<style scoped>

</style>