<template>
    <div class="min-h-[100vh] flex flex-col">
        <header-item @open-modal-authorization="openModalAuthorization" />
        <div class="box-border container max-w-[1500px] mx-auto">
            <slot
                :open-modal="openModalRegistration"
            />
        </div>
        <footer-item />
        <authorization-modal
            v-if="openAuthorization"
            @close-modal-authorization="closeModalAuthorization"
            @open-forgot-modal="openForgotModal"
            @open-reg-employee-modal="openModalRegistrationEmployeeFromAuth"
        />
        <registration-modal
            v-if="openRegistration"
            @close-modal-registration="closeModalRegistration"
        />
        <forgot-password-modal
            v-if="openForgotPassword"
            @close-modal-forgot="closeForgotModal"
        />
        <reset-password-modal
            v-if="openResetPassword"
            @close-modal-pass="closeModalPass"
            @open-authorization-modal="openModalAuthorization2"
            @open-forgot-modal="openForgotModal2"
        />
        <registration-employee
            v-if="openRegistrationEmployee || openRegistrationFromLink"
            @open-registration="closeEmployeeOpenRegistration"
            @close-modal="closeModalRegistrationEmployee"
        />
    </div>
</template>

<script>
import HeaderItem from "@/components/Header/Item";
import AuthorizationModal from "@/components/Enter/Authorization";
import RegistrationModal from "@/components/Enter/Registration";
import { overflowHidden, overflowShow } from "@/helpers";
import FooterItem from "@/components/Footer/Item";
import ForgotPasswordModal from "@/components/Enter/ForgotPassword";
import ResetPasswordModal from "@/components/Enter/ResetPassword";
import router from "@/router";
import RegistrationEmployee from "@/components/RegistrationEmployee/RegistrationEmployee";

export default {
    name: 'MainTemplates',
    components: { RegistrationEmployee, ResetPasswordModal, ForgotPasswordModal, HeaderItem, AuthorizationModal, RegistrationModal, FooterItem },
    data() {
        return {
            openAuthorization: false,
            openRegistration: false,
            openRegistrationEmployee: false,
            openForgotPassword: false
        };
    },
    computed: {
        openResetPassword() {
            return this.$route.params.options === 'reset-password'
        },
        openRegistrationFromLink() {
            return this.$route.params.options === 'registration-employee'
        }
    },
    methods: {
        openModalAuthorization() {
            this.openAuthorization = true;
            overflowHidden();
        },
        openModalAuthorization2() {
            this.closeModalPass();
            this.openAuthorization = true;
            overflowHidden();
        },
        closeModalAuthorization() {
            this.openAuthorization = false;
            overflowShow();
        },
        openForgotModal() {
            this.openAuthorization = false;
            this.openForgotPassword = true;
        },
        openForgotModal2() {
            this.closeModalPass();
            this.openAuthorization = false;
            this.openForgotPassword = true;
        },
        closeForgotModal() {
            this.openForgotPassword = false;
            overflowShow();
        },
        closeModalPass() {
            router.push({ path: '/' })
            overflowShow();
        },
        openModalRegistration() {
            this.openRegistration = true;
            overflowHidden();
        },
        openModalRegistrationEmployee() {
            this.openRegistrationEmployee = true;
            overflowHidden();
        },
        closeModalRegistration() {
            this.openRegistration = false;
            overflowShow();
        },
        closeModalRegistrationEmployee() {
            this.openRegistrationEmployee = false;
            router.push({ path: '/' })
            overflowShow();
        },
        closeEmployeeOpenRegistration() {
            this.closeModalRegistrationEmployee();
            this.openModalRegistration();
        },
        openModalRegistrationEmployeeFromAuth() {
            this.closeModalAuthorization();
            this.openModalRegistrationEmployee();
        }
    }
}
</script>

<style scoped>

</style>
