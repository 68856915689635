<template><base-loading class="m-auto w-[100px] h-[100px]" /></template>

<script>
import BaseLoading from "@/UI/BaseLoading";
export default {
    name: "LoadingContent",
    components: { BaseLoading }
};
</script>

<style scoped></style>
