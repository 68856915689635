import { isValidDate, required } from "@/helpers";
import { INVALID_DATE, REQUIRED_FIELD } from "@/constants";

export default {
    name: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    type: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    start: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            },
            {
                validator: isValidDate,
                message: INVALID_DATE
            }
        ]
    },
    end: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            },
            {
                validator: isValidDate,
                message: INVALID_DATE
            }
        ]
    },
    city: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            },
        ]
    },
    link: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    }
}
