<template>
    <div class="mb-10 relative inline-block">
        <span class="absolute inset-0 translate-x-2 translate-y-2 bg-orange" />
        <p class="relative group inline-block p-8 border-2 border-current">
            {{ title }}
        </p>
    </div>
</template>

<script>
export default {
    name: "DescText",
    props: {
        title: {
            type: String,
            required: true
        }
    }
};
</script>

<style scoped></style>
