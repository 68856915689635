<template>
    <div>
        <p v-if="title">
            {{ title }}
        </p>
        <div class="flex">
            <base-radio
                v-for="variant in variants"
                :id="variant.id"
                :key="variant.id"
                v-model="value"
                :name="name"
                :value="variant.value"
                :title="variant.title"
                @change="$emit('change')"
                @input="$emit('input')"
                @blur="$emit('blur')"
            />
        </div>
        <span
            v-if="error"
            class="-mt-2 mb-4 text-red block text-xs font-thin"
        >
            {{ textError }}
        </span>
    </div>
</template>

<script>
import BaseRadio from "@/UI/BaseRadio";
import { REQUIRED_FIELD } from "@/constants";

export default {
    name: "GroupRadio",
    components: { BaseRadio },

    props: {
        modelValue: {
            type: String,
            required: true
        },
        title: {
            type: String
        },
        error: {
            type: Boolean,
            default: false
        },
        variants: {
            type: Array,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        textError: {
            type: String,
            default: REQUIRED_FIELD
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            }
        }
    }
};
</script>

<style scoped></style>
