<template>
    <page-loading
        :message="errors?.empty"
        :status="status"
        :empty="errors?.empty"
    >
        <h2
            v-if="data.heading"
            class="text-2xl mb-5 text-center"
        >
            {{ data.heading }}
        </h2>
        <p
            v-if="data.description"
            class="mb-5 text-center [&_a]:underline [&_a]:text-gray-700 [&_a]:mb-1 [&_a:hover]:no-underline"
            v-html="data.description"
        />

        <div
            class="px-20 py-10 md:px-10 sm:px-2"
        >
            <message-item
                v-for="message in data.list"
                :key="message.messageText"
                :message-text="message.messageText"
            />
            <div
                v-if="data.list.length === 0"
                class="w-full mb-5 relative border-4 p-5"
            >
                <p class="text-center">
                    Нет данных о новых сообщениях
                </p>
            </div>
        </div>

        <div />

        <p
            v-if="lastUpdateDate"
            class="mb-8 text-center text-sm"
        >
            Последнее обновление: {{ lastUpdateDate }}
        </p>

        <whats-new-info
            :image-url="data.logo.image.data.attributes.url"
            :link-text="data.link.text"
            :link-url="data.link.link"
        />
    </page-loading>
</template>

<script>
import { loadData } from "@/use/load";
import { whatsNewPage } from "@/api";
import { toRef } from "vue";
import PageLoading from "@/components/PageLoading";
import MessageItem from "@/components/WhatsNewPage/MessageItem";
import listHandler from "../handlers/listHandler";
import WhatsNewInfo from "@/components/WhatsNewPage/WhatsNewInfo.vue";

export default {
    name: "WhatsNewPage",
    components: { WhatsNewInfo, PageLoading, MessageItem },
    setup() {
        const { data: response, status } = loadData({ query: whatsNewPage, handler: listHandler });

        const data = toRef(response, "data");
        const errors = toRef(response, "errors");

        return {
            status,
            data,
            errors
        };
    },
    computed: {
        lastUpdateDate() {
            return this.data.list[0]?.messageDate
        }
    }
};
</script>

<style scoped></style>