<template>
    <page-loading
        :empty="errors?.empty"
        :message="errors?.empty"
        :status="status"
    >
        <h2
            v-if="data.heading"
            class="text-2xl mb-5"
        >
            {{ data.heading }}
        </h2>
        <div
            v-masonry
            transition-duration="0.3s"
            item-selector=".item"
            class="masonry-container"
        >
            <div
                v-for="link of data.list"
                :key="link.id"
                v-masonry-tile
                class="w-1/5 sm:w-full md:w-1/2 xl:w-1/3 2xl:w-w-1/4 pr-[15px] sm:pr-0 lg:max-w-full pb-[15px] item"
            >
                <div class="pr-2 pb-2">
                    <links-card
                        :links="link.list"
                        :topic="link.heading"
                    />
                </div>
            </div>
        </div>
    </page-loading>
</template>

<script>
import LinksCard from "@/components/LinksPage/LinksCard";
import {loadData} from "@/use/load";
import {linksPage} from "@/api";
import {toRef} from "vue";
import PageLoading from "@/components/PageLoading";
import singlePageHandler from "@/handlers/singlePageHandler";

export default {
    name: "LinksPage",
    components: { LinksCard, PageLoading },
    setup() {
        const { data: response, status } = loadData({ query: linksPage, handler: singlePageHandler });
        const data = toRef(response, "data");
        const errors = toRef(response, "errors");

        return {
            data,
            status,
            errors
        };
    }
};
</script>

<style scoped></style>
