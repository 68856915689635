<template>
    <div class="w-full mb-5 relative inline-block">
        <span
            class="w-full absolute inset-0 translate-x-2 translate-y-2 bg-orange-light"
        />
        <details class="w-full relative p-4 border-2 border-current group">
            <summary class="flex items-center justify-between cursor-pointer">
                <h5 class="font-bold tracking-wider">
                    {{ header }}
                </h5>
                <base-icon />
            </summary>
            <div
                :class="$style.content"
                class="mt-4 leading-relaxed text-gray-700"
                v-html="text"
            />
        </details>
    </div>
</template>

<script>
import BaseIcon from "./BaseIcon";
export default {
    name: "FAQItem",
    components: { BaseIcon },
    props: {
        header: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        }
    }
};
</script>

<style module>
.content a {
  text-decoration: underline;
}

.content ol,
.content ul {
  padding: revert;
  margin: revert;
  list-style: revert;
}
</style>
