import { ref, watch } from "vue";

const not = val => !val;

export function useField(field) {
    const valid = ref(field.valid || true);
    const value = ref(field.value);
    const touched = ref(field.touched || false);
    const message = ref('');
    const changed = ref(field.changed || false);
    const isError = ref(field.isError || false);

    const reassign = val => {
        valid.value = true;
        if (field?.validators?.length > 0) {
            field.validators.forEach(item => {
                const error = not(item.validator(val));
                if (error) {
                    isError.value = true;
                    message.value = item.message
                    valid.value = false;
                }
            });
            isError.value = !valid.value && touched.value
        }

    };

    watch(value, reassign);
    reassign(field.value);

    watch(touched, value => {
        isError.value = !valid.value && value
    })
    watch(valid, value => {
        isError.value = !value && touched.value
    })

    const blur = () => (touched.value = true);
    const input = () => {
        touched.value = false;
        changed.value = true;
    };

    return {
        value,
        valid,
        isError,
        message,
        touched,
        changed,
        blur: blur,
        input: input
    };
}
