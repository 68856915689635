const singlePageHandler = ({ errors, success, data }) => {
    if (success) {
        return { data, error: false }
    }

    if (errors?.empty) {
        return { error: false, errors }
    }
    if (errors?.main) {
        return { error: true, errors }
    }
}

export default singlePageHandler;
