<template>
    <div class="flex max-w-[480px] items-stretch relative group lg:max-w-[45%] lg:min-h-[150px] sm:max-w-full sm:w-full sm:min-h-[80px]">
        <span class="w-full absolute inset-0 translate-x-2 translate-y-2 bg-orange" />
        <div class="w-full relative border-2 border-current p-4 flex items-start gap-2">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="block w-full h-10 max-w-[28px]"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
            </svg>

            <div>
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ParticipationElement"
}
</script>

<style scoped>

</style>