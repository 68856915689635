<template>
    <div
        v-if="show"
        class="fixed bottom-16 right-16 z-50 md:bottom-6 md:right-6"
    >
        <button
            class="group relative inline-block text-sm font-medium text-orange focus:outline-none focus:ring"
            @click="toUp"
        >
            <span
                class="absolute inset-0 translate-x-0 translate-y-0 bg-orange transition-transform group-hover:translate-y-0.5 group-hover:translate-x-0.5"
            />
            <span class="relative block border border-current bg-white px-3 py-3">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 15.75l7.5-7.5 7.5 7.5"
                    />
                </svg>
            </span>
        </button>
    </div>
</template>

<script>
export default {
    name: "ButtonUp",
    data() {
        return {
            show: false
        }
    },
    mounted() {
        window.addEventListener('scroll', () => {
            const scrollY = window.scrollY || document.documentElement.scrollTop;
            scrollY > 400 ? this.show = true : this.show = false;
        });
    },
    methods: {
        toUp() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }
}
</script>

<style scoped>

</style>