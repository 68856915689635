<template>
    <div class="mb-20">
        <base-title
            v-if="heading"
            class="text-center"
        >
            {{ heading }}
        </base-title>
        <div
            v-if="list"
            class="flex justify-around gap-4 lg:flex-wrap"
        >
            <problems-element
                v-for="item in list"
                :key="item.id"
            >
                {{ item.text }}
            </problems-element>
        </div>
    </div>
</template>

<script>

import ProblemsElement from "./ProblemsElement";
import BaseTitle from "@/UI/BaseTitle";

export default {
    name: "ProblemsGrid",
    components: { BaseTitle, ProblemsElement },
    props: {
        heading: {
            type: String,
            default: ""
        },
        list: {
            type: Array,
            default: () => []
        }
    }
};
</script>

<style scoped></style>
