<template>
    <div class="w-full max-w-lg mx-auto flex flex-col gap-4">
        <div>
            <h2 class="text-lg font-black">
                Базовая информация о компании
            </h2>
        </div>
        <base-input
            v-model="field.company.value"
            class="w-full"
            label="Название компании"
            placeholder="Введите название"
            :error="field.company.isError"
            :message="field.company.message"
            @input="field.company.input"
            @blur="field.company.blur"
        />
        <div class="w-full flex flex-col gap-2">
            <selected-options
                v-model="field.cities.value"
                name="city"
                label="Город"
                placeholder="Выберите город"
                :more-option="true"
                more-option-title="Добавить свой город"
                :query="request.CITY"
                :error="field.cities.isError"
                :message="field.cities.message"
                @onСhoose="change"
                @crateNewOption="createNewCity"
            />
        </div>
		
        <base-input
            v-model="field.site.value"
            class="w-full"
            label="Сайт"
            placeholder="Сайт"
            :error="field.site.isError"
            :message="field.site.message"
            @input="field.site.input"
            @blur="field.site.blur"
        />
		
        <base-input
            v-model="field.description.value"
            type="textarea"
            class="w-full"
            label="Описание компании"
            placeholder="Опишите ваше позиционирование и ваши ценности"
            :error="field.description.isError"
            :message="field.description.message"
            @input="field.description.input"
            @blur="field.description.blur"
        />
        <base-input
            v-model="field.contacts.value"
            type="textarea"
            class="w-full"
            label="Контакты"
            placeholder="С кем и как связаться по вопросам сотрудничества с вами"
            :error="field.contacts.isError"
            :message="field.contacts.message"
            @input="field.contacts.input"
            @blur="field.contacts.blur"
        />
        <base-input
            v-model="field.rusProfile.value"
            type="textarea"
            class="w-full"
            label="Информация о ваших юр. лицах"
            placeholder="Название + ИНН"
            :error="field.rusProfile.isError"
            :message="field.rusProfile.message"
            @input="field.rusProfile.input"
            @blur="field.rusProfile.blur"
        />
        <enter-button
            class="w-full"
            @click="toNextStep"
        >
            Дальше
        </enter-button>
    </div>
</template>

<script>
import BaseInput from "@/UI/BaseInput";
import EnterButton from "@/components/Enter/Button";
import { request } from "@/constants";
import SelectedOptions from "@/components/FormResourceMap/SelectedOptions";
import { submit } from "@/use/submit";
import { step1 } from "@/configForms/newCompany";
import { toRef } from "vue";

export default {
    name: "Step1",
    components: { BaseInput, EnterButton, SelectedOptions },
    setup() {
        const init = submit({
            fields: step1
        });
		
        const form = toRef(init, "form");
        const status = toRef(init, "status");
        return { form, status };
    },
    data() {
        return {
            request: request,
            error: false,
            stop: false
        };
    },
	
    computed: {
        field() {
            return {
                ...this.form.fields
            };
        }
    },
	
    methods: {
        toNextStep() {
            if (this.field.cities.value.length === 1) {
                const checkNewCities = this.field.cities.value.findIndex(i => i === 'no') !== -1;

                if (checkNewCities && !this.field.newCity.value) {
                    this.field.cities.isError = true
                    this.field.cities.message = this.message
                    this.stop = true
                } else {
                    this.stop = false
                }
            }
            if (!this.form.valid || this.stop) {
                this.form.showErrors()
                return;
            }
            this.$emit('toNext', this.form.fields)
        },
        change(list) {
            this.field.cities.value = [ ...list ];
            if (this.field.cities.value.length >= 2) {
                this.stop = false
            }
        },
        createNewCity(value) {
            const name = value;
            if (name) {
                this.field.newCity.value = name;
                this.field.cities.isError = false;
                this.stop = false;
            } else if (this.field.cities.value.length >= 2) {
                this.field.cities.isError = false
                this.stop = false
            } else {
                this.field.newCity.value = name;
                this.field.cities.isError = true
                this.field.cities.message = this.message
            }
        }
    }
}
</script>

<style scoped>

</style>
