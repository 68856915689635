<template>
    <div
        class="fixed backdrop-blur-sm left-0 top-0 z-50 bg-background w-full
        h-screen overflow-auto flex"
        @mousedown.self="$emit('onClose')"
    >
        <div
            class="max-w-screen-xl min-h-min relative p-2 block max-w-[100%] m-auto overflow-hidden flex flex-col"
        >
            <close-modal @click="$emit('onClose')" />
            <span
                class="absolute inset-2 -translate-x-2 -translate-y-2 border-2 border-current z-50 pointer-events-none"
            />
            <div
                class="relative inline-block w-full h-[100%] overflow-y-auto bg-white flex justify-center"
            >
                <div class="mb-2 p-6 pr-12 overflow-y-auto">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CloseModal from "@/components/Modal/CloseModal";

export default {
    name: "ModalTemplate",
    components: { CloseModal },
    emits: [ 'onClose' ]
};
</script>

<style scoped></style>
