<template>
    <div
        class="flex max-w-[300px] items-stretch relative group lg:max-w-[45%]
        lg:min-h-[150px] sm:max-w-full sm:w-full sm:min-h-[80px]"
    >
        <span class="absolute inset-0 translate-x-2 translate-y-2 bg-orange-light" />
        <div class="relative border-2 border-current p-4 flex items-start gap-2">
            <img
                class="max-w-[20px]"
                src="../../../assets/cross.svg"
                alt=""
            >
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: "ProblemsElement"
}
</script>

<style scoped>

</style>
