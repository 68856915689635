<template>
    <modal-template @on-close="closeModal">
        <form
            v-show="!error && !success"
            class="flex flex-col gap-4 w-full min-w-[412px] max-w-[412px] sm:min-w-full"
            @submit.prevent="onSubmit"
        >
            <div class="flex w-full justify-between items-start">
                <div>
                    <h2 class="font-medium text-lg">
                        РЕГИСТРАЦИЯ СОТРУДНИКА КОМПАНИИ
                    </h2>
                    <p class="text-sm font-thin">
                        Добавить пользователя можно только для компании, уже зарегистрированной в личном кабинете
                    </p>
                </div>
            </div>
            <div class="-mt-4">
                <tool-tip class="left-16 top-[15px]">
                    <div class="text-sm">
                        <p>Вашей компании нет в списке?</p>
                        <p
                            class="underline cursor-pointer"
                            @click="$emit('openRegistration')"
                        >
                            Подать заявку на регистрацию
                        </p>
                    </div>
                </tool-tip>
                <select-company
                    v-if="!fromLink"
                    v-model="field.resourceMap.value"
                    :error="field.resourceMap.isError"
                    :message="field.resourceMap.message"
                    class="w-full"
                    @onSelect="change"
                />
                <select-company-from-link
                    v-if="fromLink"
                    v-model="field.resourceMap.value"
                    :error="field.resourceMap.isError"
                    :message="field.resourceMap.message"
                    class="w-full"
                    @onSelect="change"
                />
            </div>
            <input-email
                v-model.trim="field.email.value"
                class="w-full"
                label="Почта"
                type="email"
                placeholder="Введите почту"
                :error="field.email.isError"
                :message="field.email.message"
                @input="field.email.input"
                @blur="field.email.blur"
            />
            <base-input
                v-model.trim="field.name.value"
                class="w-full"
                label="Имя"
                type="text"
                placeholder="Введите имя"
                :error="field.name.isError"
                :message="field.name.message"
                @input="field.name.input"
                @blur="field.name.blur"
            />
            <base-input
                v-model.trim="field.lastName.value"
                class="w-full"
                label="Фамилия"
                type="text"
                placeholder="Введите фамилию"
                :error="field.lastName.isError"
                :message="field.lastName.message"
                @blur="field.lastName.blur"
                @input="field.lastName.input"
            />
            <base-input
                v-model.trim="field.post.value"
                class="w-full"
                label="Должность"
                type="text"
                placeholder="Введите должность"
                :error="field.post.isError"
                :message="field.post.message"
                @blur="field.post.blur"
                @input="field.post.input"
            />

            <create-password
                v-model="field.password.value"
                :empty="emptyPass"
                @set-pass="setPass"
            />

            <enter-button
                type="submit"
                class="w-full"
            >
                Отправить заявку
            </enter-button>
        </form>
        <div
            v-if="success"
            class="max-w-3xl mx-auto"
        >
            <h2 class="text-2xl font-semibold mb-3">
                Спасибо за регистрацию!
            </h2>
            Вы сможете авторизоваться, как только вашу учетную запись активирует администратор вашей компании.
        </div>
        <div
            v-show="error"
            class="max-w-3xl mx-auto flex flex-col justify-center"
        >
            <h2 class="text-2xl font-semibold mb-3">
                Пользователь с такой почтой уже зарегистрирован
            </h2>
            <enter-button
                class="mx-auto"
                @click="backToForm"
            >
                Вернуться к форме
            </enter-button>
        </div>
    </modal-template>
</template>

<script>
import EnterButton from "@/components/Enter/Button";
import BaseInput from "@/UI/BaseInput";
import InputEmail from "@/UI/InputEmail";
import ToolTip from "@/UI/ToolTip";
import { toRef } from "vue";
import { submit } from "@/use/submit";
import registrationEmployee from "@/configForms/registrationEmployee";
import SelectCompany from "@/components/RegistrationEmployee/SelectCompany";
import CreatePassword from "@/UI/CreatePassword";
import { NOT_MEMBER, MEMBER } from "@/constants";
import ModalTemplate from "@/components/Modal/ModalTemplate";
import { createUser, sendEmailToAdminForActivate } from "@/api";
import { formattedForm } from "@/api/foramttedQuery";
import router from "@/router";
import SelectCompanyFromLink from "@/components/RegistrationEmployee/SelectCompanyFromLink";

export default {
    name: "RegistrationEmployee",
    components: {
        SelectCompanyFromLink,
        ModalTemplate,
        CreatePassword,
        SelectCompany,
        EnterButton,
        BaseInput,
        InputEmail,
        ToolTip
    },
    emits: [ 'closeModalRegistration' ],
    setup() {
        const init = submit({
            fields: registrationEmployee
        });
        const form = toRef(init, "form");
 
        return { form };
    },
    data() {
        return {
            emptyPass: false,
            error: false,
            success: false,
            fromLink: !!this.$route.params.company
        }
    },
    computed: {
        field() {
            return {
                ...this.form.fields
            };
        }
    },
    methods: {
        onSubmit() {
            if (!this.form.valid) {
                this.form.showErrors()
                this.emptyPass = true

                return;
            }
			
            if (!!this.emptyPass) return;

            const formatted = formattedForm(this.form.fields)
            formatted.username = formatted.email
			
            createUser(formatted)
                .then(response => {
					console.log(response)
                    return sendEmailToAdminForActivate(response.data.id)
                })
                .then(response => {
                    this.success = true
                    return { data: response }
                })
                .catch(() => {
                    this.error = true
                })
        },
        change(value) {
            this.field.resourceMap.value = value;
            this.field.company.value = value.company;
            this.field.userRole.value = value.isMember ? MEMBER : NOT_MEMBER;
        },
        closeModal() {
            this.$emit("closeModal");
        },
        setPass(value) {
            if (!value) {
                this.emptyPass = true
                this.field.password.value = ''
                return
            }
            this.field.password.value = value
            this.emptyPass = false
        },
        backToForm() {
            this.error = false
        }
    }
};
</script>
