<template>
    <page-loading
        :message="errors?.empty"
        :status="status"
        :empty="errors?.empty"
        :errors="null"
    >
        <vc-blog-analytics-form-screen
            v-if="activeScreen === screens.form"
            :heading="pageHeading"
            :description="pageDescription"
            :form="pageFormLabels"
            :partner="partner"
            @blog-submitted="handleBlogSubmission"
        />
        <vc-blog-analytics-pending-screen
            v-else-if="activeScreen === screens.pending"
            :heading="pageHeading"
            :description="pageDescription"
            :form="pageFormLabels"
            :submitted-blog-url="blogUrl"
            :partner="partner"
        />
        <vc-blog-analytics-stats-screen
            v-else-if="activeScreen === screens.stats"
            :heading="pageHeading"
            :description="pageDescription"
            :statistics="comparativeStatistics"
            :partner="partner"
        />
    </page-loading>
</template>

<script>
import VcBlogAnalyticsFormScreen from "@/components/VcBlogAnalytics/VcBlogAnalyticsFormScreen";
import VcBlogAnalyticsPendingScreen from "@/components/VcBlogAnalytics/VcBlogAnalyticsPendingScreen";
import VcBlogAnalyticsStatsScreen from "@/components/VcBlogAnalytics/VcBlogAnalyticsStatsScreen";
import PageLoading from "@/components/PageLoading";
import { fetchVcBlogAnalyticsPage, fetchUserCompanyVcBlogStats } from "@/api";
import { vcBlogAnalyticsConstants } from "@/constants";

const { CUMULATIVE_VC_BLOG_STATS_KEY } = vcBlogAnalyticsConstants;

const SCREENS = Object.freeze({
    form: "FORM",
    pending: "PENDING",
    stats: "STATS"
});

function adaptApiBlogStatisticsData({ latestUpdateFormattedDatetime, statsByYear, cumulativeStats }) {
    return {
        latestUpdateDatetime: latestUpdateFormattedDatetime,
        statsByPeriod: {
            ...statsByYear,
            [CUMULATIVE_VC_BLOG_STATS_KEY]: cumulativeStats
        }
    }
}

export default {
    name: "VcBlogAnalyticsPage",

    components: {
        PageLoading,
        VcBlogAnalyticsFormScreen,
        VcBlogAnalyticsPendingScreen,
        VcBlogAnalyticsStatsScreen
    },

    data() {
        return {
            screens: SCREENS,
            activeScreen: null,

            errors: null,
            status: {
                isPending: true,
                isError: false,
                isSuccess: false
            },

            pageHeading: null,
            pageDescription: null,
            pageFormLabels: null,
            partner: null,

            blogUrl: null,
            comparativeStatistics: null
        };
    },

    created() {
        this.initiateApiData();
    },

    methods: {
        async initiateApiData() {
            const { data: { success, data, error } } = await fetchVcBlogAnalyticsPage();

            if (!success) {
                console.log(data)

                this.status = {
                    isPending: false,
                    isError: true,
                    isSuccess: false
                };

                this.error = error;
                return;
            }

            const { heading, description, form, partner } = data;

            this.pageHeading = heading;
            this.pageDescription = description;
            this.pageFormLabels = form;
            this.partner = partner;

            const { data: { blogUrl, comparativeStatistics } } = await fetchUserCompanyVcBlogStats();

            if (!blogUrl) {
                this.activeScreen = this.screens.form;

                this.status = {
                    isPending: false,
                    isError: false,
                    isSuccess: true
                };

                return;
            }

            this.blogUrl = blogUrl;

            if (!comparativeStatistics) {
                this.activeScreen = this.screens.pending;

                this.status = {
                    isPending: false,
                    isError: false,
                    isSuccess: true
                };

                return;
            }

            this.comparativeStatistics = adaptApiBlogStatisticsData(comparativeStatistics);
            this.activeScreen = this.screens.stats;

            this.status = {
                isPending: false,
                isError: false,
                isSuccess: true
            };
        },

        handleBlogSubmission(submittedBlogUrl) {
            this.blogUrl = submittedBlogUrl;
            this.switchToPendingScreen();
        },

        switchToPendingScreen() {
            this.activeScreen = this.screens.pending;
        }
    }
}
</script>