<template>
    <h2 class="text-2xl font-bold mb-2">
        <slot />
    </h2>
    <ul class="flex flex-wrap gap-5 mb-10">
        <list-item
            v-for="item of list"
            :key="item.id"
        >
            {{ item.text }}
        </list-item>
    </ul>
</template>

<script>
import ListItem from "@/components/MainPage/ListItem";
export default {
    name: "MainList",
    components: { ListItem },
    props: {
        list: Array
    }
};
</script>

<style scoped></style>
