<template>
    <h2 class="text-2xl uppercase font-bold mb-4">
        <slot />
    </h2>
</template>

<script>
export default {
    name: "BaseTitle"
}
</script>

<style scoped>

</style>
