<template>
    <div class="flex flex-col gap-4 mx-auto w-full mb-8">
        <div class="flex flex-col gap-4">
            <div class="flex flex-wrap justify-center gap-3">
                <reports-tabs
                    :tabs="years"
                    :selected-key="activeYear"
                    @toggle="toggleYear"
                />
            </div>
            <div class="flex flex-wrap flex-row justify-center items-baseline gap-3">
                <reports-tabs
                    :tabs="type"
                    :selected-key="selectedType"
                    @toggle="toggleType"
                />
            </div>
            <div class="flex gap-4 justify-center w-full mx-auto">
                <base-select
                    v-model="period"
                    class="max-w-xs"
                    :options="periodOptions"
                    @on-select="selectNewPeriod"
                />
                <reports-download :url="files.url" />
            </div>
        </div>
        <iframe
            v-if="files.urlView"
            id="result-iframe"
            name="preview"
            :src="files.urlView"
            width="100%"
            class="relative bg-white block min-h-[725px] -mb-20"
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            onload="(function(o){o.style.height=o.contentWindow.document.body.offsetHeight + 168 +'px';}(this));"
        />
    </div>
</template>

<script>
import ReportsTabs from "@/components/Reports2/components/ReportsTabs";
import BaseSelect from "@/UI/BaseSelect";
import ReportsDownload from "@/components/Reports2/components/ReportsDownload";

export default {
    name: "ReportsPreview",
    components: { ReportsDownload, BaseSelect, ReportsTabs },
    props: {
        reports: {
            type: Object,
            required: true
        },
        years: {
            type: Array,
            required: true
        },
        weeks: {
            type: Array,
            default: () => []
        },
        month: {
            type: Array,
            default: () => []
        },
        quarter: {
            type: Array,
            default: () => []
        },
        activeYear: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            selectedType: '',
            period: null,
            weeksOptions: [],
            monthOptions: [],
            quarterOptions: [],
            currentSlide: 0
        }
    },
    computed: {
        type() {
            const keys = [];
            if (this.weeks.length) {
                this.weeks.forEach(el => this.weeksOptions.push(el.heading))
                keys.push('Неделя')
                this.selectedType = 'Неделя';
            }
            if (this.month.length) {
                this.month.forEach(el => this.monthOptions.push(el.heading))
                keys.push('Месяц')
                this.selectedType = 'Месяц';

            }
            if (this.quarter.length && this.quarterOptions.length === 0) {
                this.quarter.forEach(el => this.quarterOptions.push(el.heading))
                keys.push('Квартал')
                this.selectedType = 'Квартал';
            }

            return keys;
        },
        periodOptions() {
            let period = null
            if (this.selectedType === 'Квартал') {
                period = this.quarterOptions.map(item => ({
                    title: item,
                    value: item
                }))
            }
            if (this.selectedType === 'Месяц') {
                period = this.monthOptions.map(item => ({
                    title: item,
                    value: item
                }))
            }
            if (this.selectedType === 'Неделя') {
                period = this.weeksOptions.map(item => ({
                    title: item,
                    value: item
                }))
            }
			
            this.period = period[0]
			
            return period
        },
        files() {
            let find = null;
            if (this.selectedType === 'Квартал') {
                find = this.quarter.filter(i => i.heading === this.period.value)
            }
            if (this.selectedType === 'Месяц') {
                find = this.month.filter(i => i.heading === this.period.value)
            }
            if (this.selectedType === 'Неделя') {
                find = this.weeks.filter(i => i.heading === this.period.value)
            }

            return {
                urlView: find[0].urlView ?? find[0].url,
                url: find[0].url
            }
        }
    },
    methods: {
        toggleYear(value) {
            this.weeksOptions = [];
            this.monthOptions = [];
            this.quarterOptions = [];
            this.$emit('toggle', value)
        },
        toggleType(value) {
            this.selectedType = value;
            this.currentSlide = 0;
        },
        selectNewPeriod(value) {
            this.period = value
        }
    }
}
</script>

<style scoped>

</style>