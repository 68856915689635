<template>
    <div
        class="relative max-w-xs w-full min-h-max block group focus:outline-none focus:ring w-[800px] max-w-[100%]"
        @click="clickCard"
    >
        <div
            class="w-full h-full relative p-4  p-0 p-0"
        >
            <div class="flex gap-1">
                <h3 class="text-lg font-bold">
                    {{ company }}
                </h3>
                <span class="w-6 h-6 right-4 top-4 block">
                    <svg
                        v-if="isMember"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="right-20"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                        />
                    </svg>
                </span>
            </div>
            <a
                v-if="site"
                class="w-full underline text-gray-700 mb-1 hover:no-underline"
                target="_blank"
                :href="site"
            >
                {{ site }}
            </a>
            <span
                v-if="city.length"
                class="w-full block text-gray-700 text-xs mb-5"
            >
                {{ city.join(', ') }}
            </span>
            <div
                class="flex flex-wrap items-center gap-2 mb-5"
            >
                <span
                    v-if="isBroker"
                    class="px-1 py-0.5 bg-amber-100"
                >
                    Брокер
                </span>
                <span
                    v-if="isAccredited"
                    class="px-1 py-0.5 bg-blue-100"
                >Аккредитация</span>
                <span
                    v-if="isMember"
                    class="px-1 py-0.5 bg-emerald-100"
                >Член ARDA</span>
            </div>
            <a
                v-if="rusProfile"
                target="_blank"
                :href="rusProfile"
                class="block underline text-gray-700 mb-1 hover:no-underline"
            >
                RusProfile
            </a>
            <html-content
                v-if="contacts"
                ref="contacts"
                class="gap-1 mb-5 contacts"
                :html="contacts"
            />
            <div
                v-if="hasPlainText(description)"
                class="flex flex-wrap gap-1 mb-5"
            >
                <html-content
                    class="block w-full text-sm"
                    :html="description"
                />
            </div>
            <div
                v-if="format.length"
                class="flex flex-wrap gap-1 mb-5"
            >
                <span class="block w-full font-medium text-sm">Формат сотрудничества</span>
                <p
                    v-for="item of format"
                    :key="item"
                    class="bg-orange py-0.5 px-1 text-sm"
                >
                    {{ item }}
                </p>
            </div>
            <div
                v-if="workers.length"
                class="flex flex-wrap gap-1 mb-5"
            >
                <span class="block w-full font-medium text-sm">Количество сотрудников</span>
                <p class="bg-blue-300 py-0.5 px-1 text-sm">
                    {{ workers.join(', ') }}
                </p>
            </div>
            <div
                v-if="stack.length"
                class="flex flex-wrap gap-1 mb-5"
            >
                <span class="block w-full font-medium text-sm">Стек</span>
                <p
                    v-for="item of stack"
                    :key="item"
                    class="bg-green-300 py-0.5 px-1 text-sm"
                >
                    {{ item }}
                </p>
            </div>
            <div
                v-if="services.length"
                class="flex flex-wrap gap-1 mb-5"
            >
                <span class="block w-full font-medium text-sm">Услуги</span>
                <p
                    v-for="(service, index) of services"
                    :key="index + service"
                    class="bg-pink py-0.5 px-1 text-sm"
                >
                    {{ service }}
                </p>
                <html-content
                    v-if="hasPlainText(commentsToServeices)"
                    class="block w-full text-sm"
                    :html="commentsToServeices"
                />
            </div>
            <div
                v-if="expertises.length"
                class="flex flex-wrap gap-1 mb-5"
            >
                <span class="block w-full font-medium text-sm">Доменная экспертиза</span>
                <p
                    v-for="expertise of expertises"
                    :key="expertise"
                    class="bg-fuchsia-300 py-0.5 px-1 text-sm"
                >
                    {{ expertise }}
                </p>
                <html-content
                    v-if="hasPlainText(commentToDomainExpertise)"
                    class="block w-full text-sm"
                    :html="commentToDomainExpertise"
                />
            </div>
            <div
                v-if="markets.length"
                class="flex flex-wrap gap-1 mb-5"
            >
                <span class="block w-full font-medium text-sm">Рынки</span>
                <p
                    v-for="market of markets"
                    :key="market"
                    class="bg-yellow-200 py-0.5 px-1 uppercase text-sm"
                >
                    {{ market }}
                </p>
                <html-content
                    v-if="hasPlainText(commentToMarkets)"
                    class="block w-full text-sm"
                    :html="commentToMarkets"
                />
            </div>
            <div
                v-if="requestShowcase"
                class="flex flex-wrap gap-1 mb-5"
            >
                <span class="block w-full font-medium text-sm">Витрина запросов</span>
                <a
                    class="underline text-gray-700 hover:no-underline break-all"
                    :href="requestShowcase"
                >
                    {{ requestShowcase }}
                </a>
                <html-content
                    v-if="hasPlainText(commentToRequestShowcase)"
                    class="block w-full text-sm"
                    :html="commentToRequestShowcase"
                />
            </div>
            <div
                v-if="benchMap"
                class="flex flex-wrap gap-1 mb-5"
            >
                <span class="block w-full font-medium text-sm">Бенч-карта</span>
                <a
                    class="underline text-gray-700 hover:no-underline break-all"
                    :href="benchMap"
                >
                    {{ benchMap }}
                </a>
                <html-content
                    v-if="hasPlainText(commentToBenchMap)"
                    class="block w-full text-sm"
                    :html="commentToBenchMap"
                />
            </div>
            <div
                v-if="content"
                class="flex flex-wrap gap-1 mb-5"
            >
                <span class="block w-full font-medium text-sm">Контент</span>
                <html-content
                    :html="content"
                />
            </div>
            <div
                v-if="hasPlainText(industrialDevelopments)"
                class="flex flex-wrap gap-1 mb-5"
            >
                <span class="block w-full font-medium text-sm">Индустриальные наработки</span>
                <html-content :html="industrialDevelopments" />
            </div>
        </div>
    </div>
</template>

<script>
import HtmlContent from "@/components/ResourcePage/HtmlContent";
import { addFbIcon, addTelegramIcon, hasPlainText } from "@/helpers";

export default {
    name: "ResourceCardFull",
    components: { HtmlContent },
    props: {
        company: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        },
        site: {
            type: String,
            default: ''
        },
        city: {
            type: Array,
            default: () => []
        },
        isMember: {
            type: Boolean,
            default: false
        },
        isBroker: {
            type: Boolean,
            default: false
        },
        isAccredited: {
            type: Boolean,
            default: false
        },
        rusProfile: {
            type: String,
            default: ''
        },
        contacts: {
            type: String,
            default: ''
        },
        workers: {
            type: Array,
            default: () => []
        },
        format: {
            type: Array,
            default: () => []
        },
        stack: {
            type: Array,
            default: () => []
        },
        services: {
            type: Array,
            default: () => []
        },
        commentsToServeices: {
            type: String,
            default: ''
        },
        expertises: {
            type: Array,
            default: () => []
        },
        commentToDomainExpertise: {
            type: String,
            default: ''
        },
        markets: {
            type: Array,
            default: () => []
        },
        commentToMarkets: {
            type: String,
            default: ''
        },
        requestShowcase: {
            type: String,
            default: ''
        },
        commentToRequestShowcase: {
            type: String,
            default: ''
        },
        benchMap: {
            type: String,
            default: ''
        },
        commentToBenchMap: {
            type: String,
            default: ''
        },
        content: {
            type: String,
            default: ''
        },
        industrialDevelopments: {
            type: String,
            default: ''
        }
    },
    mounted() {
        addTelegramIcon(this.$refs.contacts?.$el);
        addFbIcon(this.$refs.contacts?.$el);
    },
    methods: {
        clickCard() {
            return this.$emit("clickCard");
        },
        hasPlainText(html) {
            return hasPlainText(html);
        }
    }
};
</script>

<style></style>
