<template>
    <page-loading
        :message="errors?.empty"
        :status="status"
        :empty="errors?.empty"
    >
        <div class="flex flex-wrap items-center justify-between max-w-full">
            <h2
                v-if="data.heading"
                class="text-2xl mb-5"
            >
                {{ data.heading }}
            </h2>
        </div>
        <standard-board
            :list="data.list"
            :is-member="data.isMember"
            :message="errors?.list"
            :error="!!errors?.list"
        />
        <join-association
            v-if="data.join"
            :join="data.join"
        />
    </page-loading>
</template>

<script>
import {loadData} from "@/use/load";
import {standardPage} from "@/api";
import {toRef} from "vue";
import PageLoading from "@/components/PageLoading";
import JoinAssociation from "@/components/JoinAssociation";
import StandardBoard from "@/components/StandardsPage/StandardBoard";
import listHandler from "../handlers/listHandler";

export default {
    name: "StandardsPage",
    components: { StandardBoard, JoinAssociation, PageLoading },
    setup() {
        const { data: response, status } = loadData({ query: standardPage, handler: listHandler });
        const data = toRef(response, "data");
        const errors = toRef(response, "errors");
        return {
            data,
            status,
            errors
        };
    }
};
</script>

<style scoped></style>
