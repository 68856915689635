import { isValidDate, isValidPrice, required } from "@/helpers";
import { INVALID_DATE, INVALID_PRICE, REQUIRED_FIELD } from "@/constants";

export default {
    heading: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    dateStart: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            },
            {
                validator: isValidDate,
                message: INVALID_DATE
            }
        ]
    },
    dateEnd: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            },
            {
                validator: isValidDate,
                message: INVALID_DATE
            }
        ]
    },
    type: {
        value: null,
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    city: {
        value: null,
        validators: []
    },
    newCity: {
        value: "",
        validators: []
    },
    registrationLink: {
        value: "",
        validators: [
        ]
    },
    transcriptLink: {
        value: "",
        validators: [
        ]
    },
    tag: {
        value: "",
        validators: []
    },
    priceStart: {
        value: "",
        validators: [
            {
                validator: isValidPrice,
                message: INVALID_PRICE
            }
        ]
    },
    priceEnd: {
        value: "",
        validators: [
            {
                validator: isValidPrice,
                message: INVALID_PRICE
            }
        ]
    },
    description: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    }
}
