<template>
    <query-modal
        :error="status.isError"
        :pending="status.isPending"
        :success="status.isSuccess"
        @close="$emit('closeModal')"
    >
        <template #modal>
            <h2 class="text-lg font-medium mb-4">
                Редактировать карточку мероприятия
            </h2>
            <form
                action=""
                class="max-w-[340px] flex flex-col gap-6"
                @submit.prevent="onSubmit"
            >
                <base-input
                    v-model.trim="field.heading.value"
                    class="w-full"
                    label="Название"
                    type="text"
                    placeholder="Название мероприятия"
                    :error="field.heading.isError"
                    :message="field.heading.message"
                    @input="field.heading.input"
                    @blur="field.heading.blur"
                />
                <load-drop-down
                    v-model="field.type.value"
                    :query="request.EVENT_TYPE"
                    name="type"
                    class="w-full"
                    label="Формат мероприятия"
                    placeholder="Выберите формат"
                    :error="field.type.isError"
                    :message="field.type.message"
                    @on-select="changeType"
                />

                <load-drop-down
                    v-model="field.city.value"
                    :query="request.CITY"
                    name="city"
                    class="w-full"
                    label="Место проведения"
                    placeholder="Место проведения"
                    :more-option="true"
                    more-option-title="Добавить свой город"
                    :error="cityError.value"
                    :message="cityError.message"
                    searchable
                    :disabled="typeOnline"
                    @on-select="changeCity"
                />

                <base-input
                    v-if="newCity"
                    v-model.trim="field.newCity.value"
                    class="w-full"
                    type="text"
                    placeholder="Добавить свой город"
                    :error="newCityError.value"
                    :message="newCityError.message"
                    @input="newCityInput"
                    @blur="field.newCity.blur"
                />

                <div class="flex items-start gap-4">
                    <input-date
                        v-model="field.dateStart.value"
                        class="w-full"
                        label="Дата начала"
                        placeholder="Дата начала"
                        :error="field.dateStart.isError"
                        :message="field.dateStart.message"
                        @blur="field.dateStart.blur"
                        @input="field.dateStart.input"
                    />

                    <input-date
                        v-model="field.dateEnd.value"
                        class="w-full"
                        label="Дата окончания"
                        type="text"
                        placeholder="Дата окончания"
                        :error="field.dateEnd.isError"
                        :message="field.dateEnd.message"
                        @blur="field.dateEnd.blur"
                        @input="field.dateEnd.input"
                    />
                </div>

                <base-input
                    v-model.trim="field.registrationLink.value"
                    class="w-full"
                    label="Сайт/Ссылка на регистрацию"
                    type="text"
                    placeholder="Ссылка на регистрацию"
                    :error="field.registrationLink.isError"
                    :message="field.registrationLink.message"
                    @input="field.registrationLink.input"
                    @blur="field.registrationLink.blur"
                />
                <base-input
                    v-model.trim="field.transcriptLink.value"
                    class="w-full"
                    label="Ссылка на конспект"
                    type="text"
                    placeholder="Ссылка на конспект"
                    :error="field.transcriptLink.isError"
                    :message="field.transcriptLink.message"
                    @input="field.transcriptLink.input"
                    @blur="field.transcriptLink.blur"
                />
                <base-input
                    v-model.trim="field.tag.value"
                    class="w-full"
                    label="Тэг"
                    type="text"
                    placeholder="Направление, тема и тд"
                    :error="field.tag.isError"
                    :message="field.tag.message"
                    @input="field.tag.input"
                    @blur="field.tag.blur"
                />
                <div class="flex gap-4 items-baseline">
                    <base-input
                        v-model.trim="field.priceStart.value"
                        label="Стоимость от"
                        class="w-full"
                        type="text"
                        placeholder="От"
                        @input="field.priceStart.input"
                        @blur="field.priceStart.blur"
                    />
                    <base-input
                        v-model.trim="field.priceEnd.value"
                        label="до"
                        class="w-full"
                        type="text"
                        placeholder="До"
                        @input="field.priceEnd.input"
                        @blur="field.priceEnd.blur"
                    />
                </div>
                <base-input
                    v-model.trim="field.description.value"
                    type="textarea"
                    class="w-full"
                    label="Описание"
                    placeholder="Введите описание"
                    :error="field.description.isError"
                    :message="field.description.message"
                    @input="field.description.input"
                    @blur="field.description.blur"
                />

                <enter-button
                    class="w-full"
                    type="submit"
                >
                    Сохранить
                </enter-button>
                <p
                    v-if="error && status.isError"
                    class="mt-4 mb-2 text-red font-thin text-center"
                >
                    {{ error }}
                </p>
            </form>
        </template>
        <template #success>
            <p class="mt-8 mb-3">
                Карточка успешно обновлена
            </p>
        </template>
    </query-modal>
</template>

<script>
import EnterButton from "@/components/Enter/Button";
import QueryModal from "@/components/QueryModal";
import LoadDropDown from "@/components/CalendarPage/components/LoadDropDown";
import BaseInput from "@/UI/BaseInput";
import InputDate from "@/UI/InputDate";
import { submit } from "@/use/submit";
import { toRef } from "vue";
import { request, REQUIRED_FIELD } from "@/constants";
import editEventCard from "@/configForms/editEventCard";
import { formattedNewEventCard } from "@/api/foramttedQuery";
import { changeEventCardId, createCity } from "@/api";

export default {
    name: "EditEventModal",
    components: { InputDate, EnterButton, BaseInput, QueryModal, LoadDropDown },
    emits: [ 'updateCards', 'closeModal' ],
    setup() {
        const init = submit({
            fields: editEventCard()
        });

        const form = toRef(init, "form");
        const error = toRef(init, "error");
        return { form, error };
    },
    data() {
        return {
            publish: null,
            request: request,
            cityError: {
                value: false,
                message: REQUIRED_FIELD
            },
            status: {
                isError: false,
                isPending: false,
                isSuccess: false
            },
            newCityError: {
                value: false,
                message: REQUIRED_FIELD
            },
            newCity: false
        }
    },

    computed: {
        field() {
            return {
                ...this.form.fields
            };
        },
        typeOnline() {
            console.log(this.field.type.value)
            return this.field.type.value.title === "Онлайн"
        }
    },
    methods: {
        onSubmit() {
            if (!this.field.city.value && !this.typeOnline) {
                this.cityError.value = true
            }
            if (!!this.newCity && !this.field.newCity.value) {
                this.newCityError.value = true
            }
            if (!this.form.valid) {
                this.form.showErrors()
                return;
            }
            if (!!this.newCityError.value || this.cityError.value) {
                return;
            }
            const formatted = formattedNewEventCard(this.form.fields);

            if (!!this.newCity && this.field.newCity.value) {
                const city = {
                    heading: this.field.newCity.value,
                    publishedAt: new Date().toISOString()
                }
                createCity({ data: city })
                    .then(response => {
                        formatted.city = response.data.data.id
                        return changeEventCardId({ data: formatted }, this.field.id.value)
                    })
                    .then(response => {
                        this.$emit('updateCards')
                        this.status.isSuccess = true
                        return response
                    })
                    .catch(e => {
                        this.status.isError = true
                        console.log(e)
                    })
            } else {
                changeEventCardId({ data: formatted }, this.field.id.value)
                    .then(response => {
                        this.$emit('updateCards')
                        this.status.isSuccess = true
                        return response
                    })
                    .catch(error => {
                        console.log(error)
                        this.status.isError = true
                    })
            }
        },
        changeType(value) {
            this.field.type.value = value;
			 if (value.title === "Онлайн") {
				 this.typeOnline = true
				 this.field.city.value = null
				 this.cityError.value = false
			 } else {
				 this.typeOnline = false
			 }
        },
        changeCity(value) {
            if (value.value === null) {
                this.newCity = true
            } else {
                this.newCity = false
                this.newCityError.value = false
            }
            this.field.city.value = value;
            this.cityError.value = false
        },
        newCityInput() {
            this.newCityError.value = false
        }
    }
};
</script>

<style scoped></style>
