<template>
	<div class="w-full h-full my-[20%] flex items-center text-center justify-center flex-col gap-8">
		<h2 class="text-lg">
			Спасибо за заполенние формы. Доступ в Личный кабинет открыт
		</h2>
		<enter-button @click="done">
			Изучить личный кабинет
		</enter-button>
	
	</div>
</template>

<script>
import EnterButton from "@/components/Enter/Button";
import { getUserDetail } from "@/api/formattedResponse";
import router from "@/router";

export default {
	name: "FinishStep",
	components: {EnterButton},
	data() {
		return {
			resource: this.$config.routes.account.resource.name
		}
	},
	methods: {
		done() {
			getUserDetail()
				.then(() => {
					router.push({ path: '/account/main'})
				}).catch(error => {
					alert(error);
				})
		}
	},
}
</script>

<style scoped>

</style>