import { isValidEmail, required } from "@/helpers";
import { INVALID_EMAIL, REQUIRED_FIELD } from "@/constants";

export default {
    email: {
        value: "",
        validators: [
            {
                validator: isValidEmail,
                message: INVALID_EMAIL
            },
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    phone: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    name: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    lastName: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    company: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    site: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    position: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    programmers: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    outstaffing: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    stability: {
        value: 5,
        validators: []
    },
    whiteLabelOutstaffing: {
        value: 5,
        validators: []
    },
    agreement: {
        value: true,
        touched: true,
        changed: true,
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    }
}
