<template>
    <modal-template @on-close="closeModal">
        <div v-show="!error && !success">
            <h3 class="font-medium text-lg mb-2">
                Добавить сотрудника
            </h3>
            <form
                class="max-w-[270px] mx-auto flex flex-col gap-6"
                @submit.prevent="onSubmit"
            >
                <base-input
                    v-model="field.name.value"
                    class="w-full"
                    label="Имя"
                    type="text"
                    placeholder="Введите имя"
                    maxlength="32"
                    :error="field.name.isError"
                    :message="field.name.message"
                    @blur="field.name.blur"
                    @input="field.name.input"
                />

                <base-input
                    v-model="field.lastName.value"
                    class="w-full"
                    label="Фамилия"
                    type="text"
                    placeholder="Введите фамилию"
                    maxlength="32"
                    :error="field.lastName.isError"
                    :message="field.lastName.message"
                    @blur="field.lastName.blur"
                    @input="field.lastName.input"
                />

                <base-input
                    v-model.trim="field.post.value"
                    class="w-full"
                    label="Должность"
                    type="text"
                    placeholder="Введите должность"
                    :error="field.post.isError"
                    :message="field.post.message"
                    @blur="field.post.blur"
                    @input="field.post.input"
                />

                <input-email
                    v-model="field.email.value"
                    class="w-full"
                    label="E-mail"
                    placeholder="Введите почту"
                    :error="field.email.isError"
                    :message="field.email.message"
                    @input="field.email.input"
                    @blur="field.email.blur"
                />

                <enter-button
                    type="submit"
                    class="w-full"
                    :disabled="!isChanged"
                >
                    Добавить
                </enter-button>
            </form>
        </div>
        <div
            v-if="success"
            class="max-w-3xl mx-auto"
        >
            <h2 class="text-2xl font-semibold mb-3">
                Сотрудник создан
            </h2>
            Сотруднику на указанный email придет уведомление с дальнейшими инструкциями
        </div>
        <div
            v-show="error"
            class="max-w-3xl mx-auto flex flex-col justify-center"
        >
            <h2 class="text-2xl font-semibold mb-3">
                Пользователь с такой почтой уже зарегистрирован
            </h2>
            <enter-button
                class="mx-auto"
                @click="backToForm"
            >
                Вернуться к форме
            </enter-button>
        </div>
    </modal-template>
</template>

<script>
import BaseInput from "@/UI/BaseInput";
import InputEmail from "@/UI/InputEmail";
import EnterButton from "@/components/Enter/Button";
import { submit } from "@/use/submit";
import { toRef } from "vue";
import store from "@/store";
import QueryModal from "@/components/QueryModal";
import ModalTemplate from "@/components/Modal/ModalTemplate";
import { createUser, sendEmailToConfirmEmployee } from "@/api";
import addEmployee from "@/configForms/addEmployee";
import { MEMBER, NOT_MEMBER } from "@/constants";

// import { createEmployee, sendEmailToConfirmEmployee } from "@/api";
// const errorsMessages = {
//     "Email already taken": "Пользователь с такой почтой уже существует",
//     "Username already taken": "Пользователь с такой почтой уже существует"
// }
export default {
    name: "CreateEmployee",
    components: { ModalTemplate, QueryModal, EnterButton, BaseInput, InputEmail },
    emits: [ "closeModalCreate" ],
    setup() {
        const init = submit({
            fields: addEmployee
        });

        const form = toRef(init, "form");
        return { form };
    },
    data() {
        return {
            error: false,
            success: false
        }
    },
    computed: {
        field() {
            return { ...this.form.fields };
        },
        isChanged() {
            return Object.values(this.form.fields).some(item => item.changed);
        }
    },
	
    methods: {
        onSubmit() {
            if (!this.form.valid) {

                this.form.showErrors();
                return;
            }

            const req = {
                email: this.field.email.value,
                name: this.field.name.value,
                lastName: this.field.lastName.value,
                username: this.field.email.value,
                post: this.field.post.value,
                userRole: store.getters.user.resourceMap ? MEMBER : NOT_MEMBER,
                userPermissions: this.field.userPermissions.value,
                resourceMap: store.getters.user.resourceMap,
                company: store.getters.user.resourceMap.company,
                password: this.field.email.value,
                role: 1,
                confirmed: true
            }

            createUser(req)
                .then(response => {
                    this.success = true
                    return sendEmailToConfirmEmployee(response.data.id)
                })
                .then(() => {
                    return this.$emit('updateList');
                }).catch(() => {
                    this.error = true
                })
        },
        closeModal() {
            this.$emit("closeModalCreate");
        },
        backToForm() {
            this.error = false
        }
    }
};
</script>

<style scoped>

</style>