<template>
    <base-multi-select
        v-model="value"
        class="w-full bg-white"
        :name="name"
        :is-loading="status.isPending"
        :options="options"
        :placeholder="placeholder"
        @on-select="$emit('onSelect', $event)"
    />
</template>

<script>
import BaseMultiSelect from "@/UI/BaseMultiSelect";
import { loadData } from "@/use/load";
import { getDirectory } from "@/api";
import mainHandler from "../../../handlers/mainHandler";
import { toRef } from "vue";
import { filterList } from "@/constants";

export default {
    name: "LoadFilter",
    components: { BaseMultiSelect },
    props: {
        modelValue: {
            type: Array,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        query: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            default: ""
        }
    },
    emits: [ 'onSelect' ],
    setup(props) {
        const request = () => getDirectory(props.query)
        const { data: response, status } = loadData({ query: request, handler: mainHandler, initialValue: [] });
        const data = toRef(response, "data");
        return {
            data,
            status
        };
    },

    computed: {
        options() {
            if (!this.data?.length) return []

            let options = [ ...this.data ];

            if (this.shouldSort) {
                options = this.sortOptionsByTitle(options);
            }

            return options || [];
        },

        shouldSort() {
            return this.name !== filterList.WORKERS;
        },

        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("onSelect", { value, name: this.name });
            }
        }
    },

    methods: {
        sortOptionsByTitle(options) {
            return [ ...options ].sort((a, b) => {
                return a.title.localeCompare(b.title);
            });
        }
    }
}
</script>

<style scoped>

</style>
