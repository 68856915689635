import { isValidEmail, required } from "@/helpers";
import { EMPLOYEE, INVALID_EMAIL, REQUIRED_FIELD } from "@/constants";

export default {
    email: {
        value: "",
        validators: [
            {
                validator: isValidEmail,
                message: INVALID_EMAIL
            },
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    name: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    lastName: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    resourceMap: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    company: {
        value: "",
        validators: []
    },
    post: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    password: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    userRole: {
        value: "",
        validators: []
    },
    userPermissions: {
        value: EMPLOYEE,
        validators: []
    },
    role: {
        value: 1,
        validators: []
    }
}
