<template>
    <page-loading
        :message="errors?.empty"
        :status="status"
        :empty="errors?.empty"
    >
        <h2
            v-if="data?.heading"
            class="text-2xl mb-5"
            v-text="data.heading"
        />
        <qr-link
            :heading="data?.qr?.heading"
            :qr="domain + data?.qr?.image"
            :link="data?.qr?.link"
            :button-text="data?.qr?.buttonText"
        />
        <pr-support-chat-rules
            v-if="shouldDisplayRules"
            :heading="data.rules.heading"
            :content="data.rules.text"
        />
    </page-loading>
</template>

<script>
import { toRef } from "vue";
import { loadData } from "@/use/load";
import { prSupportPage } from "@/api";
import { hasPlainText } from "@/helpers";
import singlePageHandler from "@/handlers/singlePageHandler";
import PageLoading from "@/components/PageLoading";
import QrLink from "@/components/QrLink";
import PrSupportChatRules from "@/components/PrSupportPage/PrSupportChatRules";

export default {
    name: "PrSupportPage",

    components: {
        PageLoading,
        QrLink,
        PrSupportChatRules
    },

    setup() {
        const { data: response, status } = loadData({
            query: prSupportPage,
            handler: singlePageHandler
        });

        const data = toRef(response, "data");
        const errors = toRef(response, "errors");
        return { data, status, errors };
    },

    computed: {
        domain() {
            return process.env.VUE_APP_API_HOST;
        },

        shouldDisplayRules() {
            return hasPlainText(this.data?.rules?.text);
        }
    }
};
</script>

<style scoped></style>
