import {
    authQuery,
    changePassword,
    createCollectionMap,
    createEvent, createEventCard,
    getCards,
    getUser,
    sendRegistrationForm,
    updateUser
} from "@/api/index";
import store from "@/store";
import { fieldsReverse, KEYS } from "@/constants";
import {
    formattedCard,
    formattedFilters,
    formattedNewEvent,
    formattedNewEventCard,
    formattedQuery
} from "@/api/foramttedQuery";

const getErrors = error => {
    let data = {}
    let message = 'Ошибка сервера, попробуйте еще раз';
    const errorList = error.response?.data?.error?.details?.errors;
    if (errorList) {
        data.fields = errorList.map(field => {
            const [ key ] = field.path;
            return { field: fieldsReverse[key], message:  field.message }
        })
        message = 'Ошибка валидации формы'
    }

    return Promise.reject({ message, data })
}

export const saveUserData = user => {
    store.commit('getUser', user)
    localStorage.setItem(KEYS.USER, JSON.stringify(user));
}

export const authUser = data => {
    const formatted = formattedQuery(data)
    return authQuery(formatted).then(response => {
        const { user } = response.data
        saveUserData(user);
    }).catch(error => {
        return getErrors(error)
    })
}

export const getUserDetail = () => {
    return getUser().then(response => {
        const { data: user } = response
        saveUserData(user.data);
        return response
    }).catch(error => {
        return Promise.reject(error);
    })
}

export const updateUserDetail = data => {
    const formatted = formattedQuery(data)
    return updateUser(formatted).then(response => {
        saveUserData(response.data);
        return { data: response }
    }).catch(error => {
        return getErrors(error)
    })
}

export const createNewEvent = data => {
    const formatted = formattedNewEvent(data);
    formatted.status = 'Новая';

    return createEvent({ data: formatted }).then(response => {
        return { data: response }
    }).catch(error => {
        return getErrors(error)
    })
}

export const createNewEventCard = data => {
    const formatted = formattedNewEventCard(data);
    return createEventCard({ data: formatted }).then(response => {
        return response
    }).catch(error => {
        return getErrors(error)
    })
}

export const updatePassword = data => {
    return changePassword(data).then(response => {
        const jwt = data?.data?.jwt
        if (jwt) store.commit('getToken', jwt)
        return { data: response }
    }).catch(error => {
        return Promise.reject(error);
    })
}

export const sendFormReg = data => {
    const formatted = formattedQuery(data)
    formatted.status = 'Новая';
    // TODO переделать
    return sendRegistrationForm({ data: formatted }).then(response => {
        return { data: response }
    }).catch(error => {
        return getErrors(error)
    })
}

export const filters = query => {
    const formatted = formattedFilters(query)
    return getCards({ filters: formatted }).then(response => {
        return { data: response.data }
    }).catch(error => {
        return getErrors(error)
    })
}

export const sendFormNewCompany = data => {
    const formatted = formattedCard(data)

    return createCollectionMap({ data: formatted }).then(response => {
        return { data: response.data }
    }).catch(error => {
        return getErrors(error)
    })
}
