<template>
    <div class="relative w-20 mr-4">
        <label
            class="block p-4 text-sm font-medium"
            :for="id"
        >
            <input
                :id="id"
                v-model="model"
                class="hidden group peer"
                :value="value"
                type="radio"
                :name="name"
                @change="$emit('change')"
            >
            <div class="relative inline-block group focus:outline-none focus:ring">
                <span
                    class="absolute h-7 w-7 inset-0 transition-transform translate-x-1.5 translate-y-1.5 bg-orange-light group-hover:translate-y-0 group-hover:translate-x-0"
                />
                <span
                    class="relative inline-block h-7 w-7 p-2 border-2 border-current"
                />
            </div>
            <img
                class="absolute top-5 left-5 h-5 w-5 hidden peer-checked:block"
                src="../assets/checking-mark.png"
                alt=""
            >
            <span class="absolute right-0 top-5">{{ title }}</span>
        </label>
    </div>
</template>

<script>
export default {
    name: "BaseRadio",
    props: {
        modelValue: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        checked: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            required: true
        },
        value: {
            type: String,
            required: true
        }
    },
    computed: {
        model: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            }
        }
    }
};
</script>

<style scoped></style>
