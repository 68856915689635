import { cards, fields, filterNotNull, filterServer } from "@/constants";
import { formattedDate, trim } from "@/helpers";

export const formattedQuery = data => {
    return Object.entries(data).reduce((acc, [ key, { changed, value } ]) => {
        const field = fields[key];

        if (changed && trim(value)) {
            acc[field] = value
        }
        return acc
    }, {})
}
export const formattedForm = data => {
    return Object.entries(data).reduce((acc, [ key, value ]) => {
        const field = fields[key];

        acc[field] = value.value;

        return acc;
    }, {});
};

export const formattedCard = data => {
    return Object.entries(data).reduce((acc, [ key, { changed, value } ]) => {
        const field = cards[key];

        acc[field] = value

        return acc
    }, {})
}

export const formattedNewEvent = data => {
    const formatted = formattedQuery(data);
    const { dateStart, dateEnd } = formatted
    formatted.dateStart = formattedDate(dateStart)
    formatted.dateEnd = formattedDate(dateEnd)

    return formatted
}

export const formattedNewEventCard = data => {
    return {
        heading: data.heading.value,
        dateStart: formattedDate(data.dateStart.value),
        dateEnd: formattedDate(data.dateEnd.value),
        type: data.type.value.value,
        city: data.city.value?.value || null,
        registrationLink: data.registrationLink?.value || null,
        transcriptLink: data.transcriptLink?.value || null,
        tag: data.tag.value,
        priceStart: data.priceStart?.value || "0",
        priceEnd: data.priceEnd.value || null,
        description: data.description.value,
        publishedAt: data.publishedAt?.value || null
    }
}

export const formattedFilters = filters => {
    return Object.entries(filters).reduce((acc, [ key, value ]) => {
        if (value.length === 0) return acc;

        if (filterNotNull[key] === key) {
            const emptyKey = filterNotNull[key];

            acc[emptyKey] = {
                '$notNull': true
            };
        }
        if (filterServer[key] === key) {
            const serverKey = filterServer[key];

            acc[serverKey] = {
                id: {
                    '$eq': value.map(item => item.value)
                }
            };
        }

        acc.publishedAt = {
            '$notNull': true
        };

        return acc;
    }, {})
}
