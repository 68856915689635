<template>
    <div class="relative p-6 max-w-2xl w-full border-2 border-current">
        <h3 class="text-lg font-bold mb-4">
            Информация о пользователе
        </h3>
        <div class="grid grid-cols-2 gap-4 mb-4 sm:grid-cols-1">
            <div>
                <h4 class="text-xs">
                    Фамилия
                </h4>
                <p class="text-lg">
                    {{ user.lastName }}
                </p>
            </div>
            <div>
                <h4 class="text-xs">
                    Имя
                </h4>
                <p class="text-lg">
                    {{ user.name }}
                </p>
            </div>
            <div>
                <h4 class="text-xs">
                    Компания
                </h4>
                <p class="text-lg">
                    {{ user.company }}
                </p>
            </div>
            <div>
                <h4 class="text-xs">
                    Должность
                </h4>
                <p class="text-lg">
                    {{ user.post }}
                </p>
            </div>
            <div class="mr-4">
                <h4 class="text-xs">
                    Электронная почта
                </h4>
                <p class="text-lg">
                    {{ user.email }}
                </p>
            </div>
        </div>
        <div class="flex flex-wrap gap-4">
            <enter-button
                class="sm:w-full"
                @click="$emit('showEdit')"
            >
                Редактировать профиль
            </enter-button>
            <enter-button
                class="sm:w-full"
                @click="$emit('showChangePass')"
            >
                Изменить пароль
            </enter-button>
        </div>
    </div>
</template>

<script>

import { mapGetters } from "vuex";
import EnterButton from "@/components/Enter/Button";
export default {
    name: "InfoUser",
    components: { EnterButton },
    computed: {
        ...mapGetters({
            user: "user"
        })
    }
}
</script>
