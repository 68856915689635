<template>
    <page-loading
        :message="errors?.empty"
        :status="status"
        :empty="errors?.empty"
    >
        <h2
            v-if="data.heading"
            class="text-2xl mb-5"
        >
            {{ data.heading }}
        </h2>
        <qr-link
            :heading="data?.qr?.heading"
            :qr="domain + data?.qr?.image"
            :link="data?.qr?.link"
            :button-text="data?.qr?.buttonText"
        />
        <exchange-chat
            :heading="data.information.heading"
            :list="data.information.list"
        />
        <exchange-list
            :heading="data.questions.heading"
            :list="data.questions.list"
        />
    </page-loading>
</template>

<script>
import ExchangeChat from "@/components/ExchangePage/ExchangeChat";
import ExchangeList from "@/components/ExchangePage/ExchangeList";
import {loadData} from "@/use/load";
import {leadsPage} from "@/api";
import {toRef} from "vue";
import PageLoading from "@/components/PageLoading";
import singlePageHandler from "@/handlers/singlePageHandler";
import QrLink from "@/components/QrLink";

export default {
    name: "ExchangePage",
    components: { QrLink, ExchangeList, ExchangeChat, PageLoading },
    setup() {
        const { data: response, status } = loadData({ query: leadsPage, handler: singlePageHandler });
        const data = toRef(response, "data");
        const errors = toRef(response, "errors");
        return {
            data,
            status,
            errors
        };
    },
    computed: {
        domain() {
            return process.env.VUE_APP_API_HOST;
        }
    }
};
</script>

<style scoped></style>
