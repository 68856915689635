import { isValidEmail, required } from "@/helpers";
import { INVALID_EMAIL, REQUIRED_FIELD } from "@/constants";

export const forgot = {
    email: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            },
            {
                validator: isValidEmail,
                message: INVALID_EMAIL
            }
        ]
    }
};