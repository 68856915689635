<template>
    <button
        type="button"
        class="flex gap-x-1 items-center p-1"
        :class="`bg-${type}`"
        @click="$emit('click')"
    >
        <span>{{ title }}</span>
        <svg class="w-4 h-4">
            <use xlink:href="#delete" />
        </svg>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            class="hidden"
        >
            <symbol
                id="delete"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-1 h-1"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                />
            </symbol>
        </svg>
    </button>
</template>

<script>
export default {
    name: "SelectedItem",
    props: {
        title: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },
    emits: [ 'click' ]
};
</script>

<style scoped></style>
