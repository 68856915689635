<template>
    <enter-button
        v-if="adminMode"
        class="mb-4"
        @click="toggleModalCreate"
    >
        Создать карточку
    </enter-button>
    <div v-if="events.length">
        <div
            v-for="(event, key) in listEvents"
            :key="event + key"
        >
            <h2 class="w-full text-xl font-bold mb-5">
                {{ event.year }}
            </h2>
            <div class="flex flex-wrap gap-10 mb-10 sm:gap-6">
                <calendar-card
                    v-for="item of event.list"
                    :id="item.id"
                    :key="item"
                    :heading="item.heading"
                    :description="item.description"
                    :registration="item.registrationLink"
                    :transcript="item.transcriptLink"
                    :date="item.formattedDateRange"
                    :price="item.formattedPriceRange"
                    :tag="item.tag"
                    :city="item.city"
                    :type="item.type"
                    :published="item?.published"
                    @on-edit="modalEdit = true"
                    @on-delete="deleteCard"
                    @on-publish="publishCard"
                    @on-unpublish="unpublishCard"
                />
            </div>
        </div>
    </div>
    <div
        v-if="error"
        class="text-xl mb-2 text-center"
    >
        {{ message }}
    </div>
    <div
        v-if="empty"
        class="text-2xl uppercase font-bold mb-4 mt-4 text-center"
    >
        Список мероприятий пуст
    </div>
    <create-event-modal
        v-if="createCard"
        @update-cards="getCardsAll"
        @close-modal="toggleModalCreate"
    />
    <delete-event-card
        v-if="modalDelete"
        :id="eventId"
        :heading="eventHeading"
        @accept-delete="getCardsAll"
        @close-modal="modalDelete = false"
    />
    <publish-event-card
        v-if="modalPublish"
        :id="eventId"
        :heading="eventHeading"
        @accept="getCardsAll"
        @close-modal="modalPublish = false"
    />
    <unpublish-event-card
        v-if="modalUnpublish"
        :id="eventId"
        :heading="eventHeading"
        @accept="getCardsAll"
        @close-modal="modalUnpublish = false"
    />
    <edit-event-modal
        v-if="modalEdit"
        @update-cards="getCardsAll"
        @close-modal="modalEdit = false"
    />
</template>

<script>
import CalendarCard from "@/components/CalendarPage/CalendarCard";
import CreateEventModal from "@/components/CalendarPage/CreateEventModal";
import EnterButton from "@/components/Enter/Button";
import DeleteEventCard from "@/components/CalendarPage/DeleteEventCard";
import PublishEventCard from "@/components/CalendarPage/PublishEventCard";
import UnpublishEventCard from "@/components/CalendarPage/UnpublishEventCard";
import EditEventModal from "@/components/CalendarPage/EditEventModal";
import { getAllEventsCards, getAllEventsCardsAll } from "@/api";

export default {
    name: 'CalendarBoard',
    components: { EditEventModal, UnpublishEventCard, PublishEventCard, DeleteEventCard, CalendarCard, CreateEventModal, EnterButton },
    props: {
        error: {
            type: Boolean,
            required: true
        },
        message: {
            type: String,
            default: ''
        },
        events: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            listEvents: this.events,
            createCard: false,
            modalDelete: false,
            modalPublish: false,
            modalUnpublish: false,
            modalEdit: false,
            eventId: null,
            eventHeading: ''
        }
    },
    computed: {
        empty() {
            return !this.error && !this.events.length
        },
        adminMode() {
            return this.$store.getters['calendarAdminMode'];
        }
    },
    watch: {
        adminMode(newValue) {
            if (newValue) this.getCardsAll()
            if (!newValue) this.getCards()
        }
    },
    methods: {
        toggleModalCreate() {
            this.createCard = !this.createCard;
        },
        getCardsAll() {
            return getAllEventsCardsAll()
                .then(response => {
                    this.listEvents = response.data.data
                    return response
                })
                .catch(e => {
                    console.log(e)
                })
        },
        getCards() {
            return getAllEventsCards()
                .then(response => {
                    this.listEvents = response.data.data
                    return response
                })
                .catch(e => {
                    console.log(e)
                })
        },
        deleteCard({ id, heading }) {
            this.eventId = id;
            this.eventHeading = heading;
            this.modalDelete = true;
        },
        publishCard({ id, heading }) {
            this.eventId = id;
            this.eventHeading = heading;
            this.modalPublish = true;
        },
        unpublishCard({ id, heading }) {
            this.eventId = id;
            this.eventHeading = heading;
            this.modalUnpublish = true;
        }
    }
}
</script>

<style scoped>

</style>
