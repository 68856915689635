<template>
    <div class="my-5 flex flex-wrap justify-between items-center">
        <div class="w-2/4 pr-5 sm:w-full sm:pr-0">
            <intro-title
                v-if="title"
                :title="title"
            />
            <intro-description
                v-if="text"
                :text="text"
            />
            <div class="flex flex-wrap items-baseline gap-4 sm:justify-center">
                <enter-button
                    v-if="buttonText"
                    @click="onClick"
                >
                    {{ buttonText }}
                </enter-button>
            </div>
        </div>
        <img
            class="w-2/4 h-full sm:w-auto sm:h-[300px] sm:mx-auto sm:mt-7"
            src="@/assets/intro_small.png"
            alt=""
        >
    </div>
</template>

<script>
import IntroTitle from "./Title";
import IntroDescription from "./Description";
import EnterButton from "../Enter/Button";

export default {
    name: "IntroItems",
    components: { IntroTitle, IntroDescription, EnterButton },
    props: {
        title: {
            type: String,
            default: ""
        },
        text: {
            type: String,
            default: ""
        },
        buttonText: {
            type: String,
            default: ""
        }
    },
    emits: [ 'onClick' ],
    methods: {
        onClick() {
            this.$emit('onClick');
        }
    }
};
</script>

<style scoped></style>
