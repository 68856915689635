<template>
    <query-modal
        :success="status.isSuccess"
        :error="status.isError"
        :pending="status.isPending"
        @close="$emit('closeModal')"
    >
        <template #modal>
            <div class="flex justify-center flex-col gap-4">
                <h3 class="font-medium text-lg">
                    Снять с публикации мероприятие {{ heading }}?
                </h3>
                <enter-button
                    class="mx-auto"
                    @click="accept"
                >
                    Снять
                </enter-button>
            </div>
        </template>
        <template #success>
            <h3 class="font-medium text-lg">
                Мероприятие снято с публикации
            </h3>
        </template>
        <template #error>
            <h3 class="font-medium text-lg">
                Ошибка. Попробуйте позже
            </h3>
        </template>
    </query-modal>
</template>

<script>
import QueryModal from "@/components/QueryModal";
import EnterButton from "@/components/Enter/Button";
import { changeEventCardId } from "@/api";

export default {
    name: "UnpublishEventCard",
    components: { QueryModal, EnterButton },
    props: {
        id: Number,
        heading: String
    },
    data() {
        return {
            status: {
                isSuccess: false,
                isError: false,
                isPending: false
            }
        }
    },
    methods: {
        accept() {
            const data = { data: { publishedAt: null } }
            return changeEventCardId(data, this.id)
                .then(response => {
                    this.$emit('accept')
                    this.status.isSuccess = true
                    return response
                })
                .catch(e => {
                    this.status.isError = true
                    console.log(e)
                })
        }
    }
}
</script>

<style scoped>

</style>