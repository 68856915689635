<template>
    <div class="mb-20 flex flex-col items-center">
        <base-title class="text-center">
            {{ title }}
        </base-title>
        <h2 class="max-w-[600px] text-xl text-center mb-5">
            {{ text }}
        </h2>
        <div
            class="flex sm:justify-center justify-between flex-wrap items-start gap-5"
        >
            <a
                class="relative inline-block group focus:outline-none focus:ring"
                target="_blank"
                href="https://yoomoney.ru/to/4100116119092676"
            >
                <span
                    class="absolute inset-0 transition-transform
                    translate-x-1.5 translate-y-1.5 bg-orange-light group-hover:translate-y-0 group-hover:translate-x-0"
                />

                <span
                    class="relative inline-block px-8 py-3 text-sm
                    font-bold tracking-widest text-black uppercase border-2 border-current group-active:text-opacity-75"
                >
                    {{ buttonText }}
                </span>
            </a>
        </div>
    </div>
</template>

<script>
import BaseTitle from "@/UI/BaseTitle";
export default {
    name: "ArdaItem",
    components: { BaseTitle },
    props: {
        title: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        buttonText: {
            type: String,
            default: ''
        }
    }
};
</script>

<style scoped></style>
