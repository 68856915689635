<template>
    <div class="mb-20">
        <base-title
            v-if="heading"
            class="text-center"
        >
            {{ heading }}
        </base-title>
        <div
            v-if="list"
            class="flex flex-wrap justify-center gap-4 mb-10"
        >
            <element
                v-for="item in list"
                :key="item.key"
            >
                {{ item.text }}
            </element>
        </div>
    </div>
</template>

<script>

import Element from "@/components/Participation/Element";
import BaseTitle from "@/UI/BaseTitle";
export default {
    name: "ParticipationItem",
    components: { BaseTitle, Element },
    props: {
        heading: {
            type: String,
            default: ""
        },
        list: {
            type: Array,
            default: () => []
        }
    }
};
</script>

<style scoped></style>
