<template>
    <p class="relative p-2 border border-gray-300 text-xs">
        <span v-text="label" />
        {{ " – " }}
        <span
            class="font-bold"
            v-text="count"
        />
        <button
            class="absolute top-2 right-2"
            @click="handleClose"
        >
            &#x2715;
        </button>
    </p>
</template>

<script>
export default {
    name: "TotalValueCount",

    props: {
        label: {
            type: String,
            required: true
        },

        count: {
            type: Number,
            required: true
        }
    },

    emits: [ "close" ],

    methods: {
        handleClose() {
            this.$emit("close");
        }
    }
}
</script>

<style scoped>

</style>