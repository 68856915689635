<template>
    <div class="w-full pr-2 pb-2 flex">
        <div class="relative flex w-full">
            <span
                class="w-full absolute inset-0 translate-x-2 translate-y-2 bg-gray-50 shadow-md"
            />
            <div class="w-full relative flex flex-col p-4 border-2 border-current">
                <div
                    v-if="image"
                    class="relative mb-6 "
                >
                    <div class="h-0 w-full pb-[52.28%] overflow-hidden">
                        <img
                            class="object-cover w-full"
                            :src="domain + image"
                            :alt="heading"
                            loading="lazy"
                        >
                        <strong
                            v-if="time"
                            class="absolute bottom-0 inline-block px-3 py-1 text-xs font-medium bg-orange"
                        >
                            {{ time }}
                        </strong>
                    </div>
                </div>

                <div class="flex flex-col flex-1">
                    <h5
                        v-if="heading"
                        class="text-lg font-bold sm:text-base"
                    >
                        {{ heading }}
                    </h5>

                    <p
                        v-if="description"
                        class="mt-2 text-sm text-gray-700 mb-4"
                        v-html="description"
                    />

                    <a
                        v-if="link"
                        :href="link"
                        target="_blank"
                        class="mt-auto w-full relative inline-block text-center
                     group focus:outline-none focus:ring"
                    >
                        <span
                            class="absolute inset-0 transition-transform
                        translate-x-1.5 translate-y-1.5
                        bg-orange group-hover:translate-y-0 group-hover:translate-x-0"
                        />

                        <span
                            class="w-full relative inline-block px-8 py-3
                            text-sm font-bold tracking-widest text-black
                            uppercase border-2 border-current group-active:text-opacity-75"
                        >
                            Просмотр
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "WebinarCard",
    props: {
        image: {
            type: String,
            default: ''
        },
        time: {
            type: String,
            default: ''
        },
        heading: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        link: {
            type: String
        }
    },
    computed: {
        ...mapGetters({
            isMember: 'isMember'
        }),
        domain() {
            return process.env.VUE_APP_API_HOST;
        }

    }

};
</script>

<style scoped>
p {
    overflow: hidden;
    overflow-wrap: break-word;
    word-wrap: break-word;
}
</style>
