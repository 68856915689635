<template>
    <div>
        <div
            class="mb-5 [&>*]:mb-2"
            v-html="message"
        />
        <div class="flex items-start sm:block">
            <div class="w-full mr-2 sm:mr-0 mb-0 sm:mb-3 opacity-50 pointer-events-none backdrop-grayscale-[0.5]">
                <base-input
                    :model-value="submittedUrl"
                    class="w-full"
                />
            </div>
            <div class="opacity-50 pointer-events-none backdrop-grayscale-[0.5]">
                <enter-button>
                    {{ submitButtonText }}
                </enter-button>
            </div>
        </div>
    </div>
</template>

<script>
import BaseInput from "@/UI/BaseInput";
import EnterButton from "@/components/Enter/Button";

export default {
    name: "VcBlogUrlCompleteForm",

    components: {
        BaseInput,
        EnterButton
    },

    props: {
        message: {
            type: String,
            required: true
        },

        submittedUrl:  {
            type: String,
            required: true
        },

        submitButtonText: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>

</style>