<template>
    <li class="text-center mb-5">
        <div
            class="relative inline-block group focus:outline-none focus:ring max-w-[250px] w-full"
        >
            <span
                class="absolute inset-0 transition-transform translate-x-1.5 translate-y-1.5 bg-white group-hover:translate-y-0 group-hover:translate-x-0"
            />

            <router-link
                class="w-full text-center relative inline-block px-8 py-3 text-sm font-bold tracking-widest text-black uppercase border-2 border-current"
                :to="{ name: nav.page }"
            >
                {{ nav.title }}
            </router-link>
        </div>
    </li>
</template>

<script>
export default {
    name: "NavItem",
    props: {
        nav: {
            type: Object,
            required: true
        }
    }
};
</script>

<style scoped></style>
