<template>
    <ul
        v-if="periodIds"
        class="flex flex-wrap gap-5"
    >
        <li
            v-for="periodId of periodIds"
            :key="periodId"
        >
            <period-selection-button
                :id="periodId"
                :label="generatePeriodButtonLabel(periodId)"
                :is-selected="selectedPeriodId === periodId"
                @select="handleSelect"
            />
        </li>
    </ul>
</template>

<script>
import PeriodSelectionButton from "@/components/VcBlogAnalytics/components/PeriodSelectionButton";
import { vcBlogAnalyticsConstants } from "@/constants";

const { CUMULATIVE_VC_BLOG_STATS_KEY, CUMULATIVE_VC_BLOG_STATS_LABEL } = vcBlogAnalyticsConstants;

export default {
    name: "PeriodSelectionPanel",
    components: { PeriodSelectionButton },
    props: {
        periodIds: {
            type: Array,
            required: true
        },

        initialSelectedPeriodId: {
            type: String,
            required: true
        }
    },

    emits: [ "period-change" ],

    data() {
        return {
            selectedPeriodId: this.initialSelectedPeriodId
        }
    },

    methods: {
        handleSelect(periodId) {
            this.selectedPeriodId = periodId;
            this.$emit("period-change", periodId);
        },

        generatePeriodButtonLabel(periodKey) {
            if (periodKey === CUMULATIVE_VC_BLOG_STATS_KEY) {
                return CUMULATIVE_VC_BLOG_STATS_LABEL;
            }

            return `${periodKey} год`;
        }
    }
}
</script>

<style scoped>

</style>
