<template>
    <page-loading
        :status="status"
    >
        <progress-bar
            :steps="steps"
            :current-step="currentStep"
        />
        <span
            v-if="currentStep > 0 && currentStep !== steps.length"
            class="cursor-pointer text-gray-400"
            @click="prevStep"
        >
            К предыдущему шагу
        </span>
        
        <step1
            v-show="currentStep === 0"
            @toNext="nextStep"
        />
        <step2
            v-show="currentStep === 1"
            @toNext="nextStep"
        />
        <step3
            v-show="currentStep === 2"
            @finish="onSubmit"
        />
        <finish-step v-if="currentStep === 3" />
    </page-loading>
</template>

<script>
import { reactive, computed } from "vue";
import router from "@/router";
import ProgressBar from "@/components/ProgressBar";
import Step1 from "@/components/FormResourceMap/Step1";
import Step2 from "@/components/FormResourceMap/Step2";
import Step3 from "@/components/FormResourceMap/Step3";
import PageLoading from "@/components/PageLoading";
import FinishStep from "@/components/FormResourceMap/FinishStep";
import { sendFormNewCompany } from "@/api/formattedResponse";
import { getUserDetail } from "@/api/formattedResponse";
import { statusQuery } from "@/constants";

export default {
    name: "FormResourceMap",
    components: { Step1, Step2, Step3, FinishStep, ProgressBar, PageLoading },
    data() {
        return {
            currentStep: 0,
            steps: [
                { id: 'step1', title: 'Шаг 1' },
                { id: 'step2', title: 'Шаг 2' },
                { id: 'step3', title: 'Шаг 3' }
            ],
            data: []
        }
    },
    setup() {
        let status = reactive({ value: statusQuery.PENDING });

        const currentStatus = computed(() => ({
            isPending: status.value === statusQuery.PENDING,
            isError: status.value === statusQuery.ERROR,
            isSuccess: status.value === statusQuery.SUCCESS
        }));

        getUserDetail()
            .then(res => {
                status.value = statusQuery.SUCCESS;
                !!res.data.data.resourceMap && router.push({ path: '/account/main'});
            })
            .catch(error => {
                console.log(error)
                status.value = statusQuery.isError;
            });

        return {
            status: currentStatus,
        };
    },
    methods: {
        nextStep(data) {
            this.data = { ...this.data, ...data };
			
            if (this.currentStep > this.steps.length) return;
			
            this.currentStep++;
        },
        prevStep() {
            if (this.currentStep === 0) return;
			
            this.currentStep--;
        },
        onSubmit (data) {
            this.nextStep(data);
			
            sendFormNewCompany(this.data).then(() => {
                getUserDetail().catch(error => alert(error));
            });
        }
    }
}
</script>

<style scoped>

</style>
