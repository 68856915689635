<template>
    <div
        v-if="list.length"
        class="flex flex-wrap gap-5 mb-5"
    >
        <standard-item
            v-for="standard of sortedList"
            :key="standard.id"
            :standard="standard"
            :show-link="isMember"
        />
    </div>
    <div
        v-if="error"
        class="text-xl mb-2 text-center"
    >
        {{ message }}
    </div>
    <div
        v-if="empty"
        class="text-2xl uppercase font-bold mb-4 mt-4 text-center"
    >
        Список стандартов пуст
    </div>
</template>

<script>
import StandardItem from "@/components/StandardsPage/StandardItem";
import {sortListBySortingField} from "@/helpers";

export default {
    name: 'StandardBoard',
    components: { StandardItem },
    props: {
        list: {
            type: Array,
            default: () => []
        },
        isMember: {
            type: Boolean,
            required: true
        },
        error: {
            type: Boolean,
            required: true
        },
        message: {
            type: String,
            default: ''
        }
    },
    computed: {
        empty() {
            return this.list.length === 0 && !this.error
        },
        sortedList() {
          return this.list.sort(sortListBySortingField)
        }
    }

}
</script>

<style scoped>

</style>
