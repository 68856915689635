<template>
    <query-modal
        :error="error"
        :pending="false"
        :success="success"
        @close="$emit('closeModal')"
    >
        <template #modal>
            <div class="flex justify-center flex-col gap-4">
                <h3 class="font-medium text-lg">
                    Удалить сотрудника {{ user.name }} {{ user.lastName }}?
                </h3>
                <enter-button
                    class="mx-auto"
                    @click="acceptDeleteProfile"
                >
                    Удалить
                </enter-button>
            </div>
        </template>
        <template #success>
            <h3 class="font-medium text-lg">
                Сотрудник удален
            </h3>
        </template>
    </query-modal>
</template>

<script>
import EnterButton from "@/components/Enter/Button";
import QueryModal from "@/components/QueryModal";
import { changeEmployee } from "@/api";
export default {
    name: "BlockedProfile",
    components: { EnterButton, QueryModal },
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            success: false,
            error: false
        }
    },
    methods: {
        acceptDeleteProfile() {
            const user = { blocked: true }
            changeEmployee(user, this.user.id)
                .then(response => {
                    this.success = true
                    this.$emit('updateList')
                    return response
                }).catch(e => {
                    console.log(e)
                })
        }
    }
}
</script>
