<template>
    <h1 class="font-black text-4xl uppercase mb-10 lg:text-2xl lg:mb-5">
        {{ title }}
    </h1>
</template>

<script>
export default {
    name: "IntroTitle",
    props: {
        title: {
            type: String,
            required: true
        }
    }
};
</script>

<style scoped></style>
