<template>
    <div class="mb-20">
        <base-title
            v-if="heading"
            class="text-center"
        >
            {{heading}}
        </base-title>
        <div
            v-if="list"
            class="flex justify-around flex-wrap mb-10"
        >
            <div
                v-for="item in list"
                :key="item.id"
                class="text-center"
            >
                <div class="relative text-9xl md:text-7xl">
                    <span
                        class="absolute translate-x-1.5 translate-y-1.5 md:translate-x-1 md:translate-y-1"
                    >>{{ item.text }}</span>
                    <span class="relative text-orange">>{{ item.text }}</span>
                </div>
                <p class="text-xl">
                    {{ item.heading }}
                </p>
            </div>
        </div>
        <div class="text-center">
            <a
                href="https://arda.digital/"
                class="relative inline-block group focus:outline-none focus:ring"
                target="_blank"
            >
                <span
                    class="absolute inset-0 transition-transform translate-x-1.5
                    translate-y-1.5 bg-orange-light group-hover:translate-y-0 group-hover:translate-x-0"
                />

                <span
                    class="relative inline-block px-8 py-3 text-sm font-bold tracking-widest
                    text-black uppercase border-2 border-current group-active:text-opacity-75"
                >
                    {{ buttonText }}
                </span>
            </a>
        </div>
    </div>
</template>

<script>
import BaseTitle from "@/UI/BaseTitle";

export default {
    name: 'ArdaStats',
    components: { BaseTitle },
    props: {
        heading: {
            type: String,
            default: ''
        },
        buttonText: {
            type: String,
            default: ''
        },
        list: {
            type: Array,
            default: () => []
        }
    },
    emits: [ 'onClick' ]

}
</script>

<style scoped>

</style>
