<template>
    <h2
        v-if="heading"
        class="text-2xl mb-5"
        v-text="heading"
    />
    <div class="w-full max-w-[600px]">
        <div class="mb-10">
            <p
                v-if="description"
                v-text="description"
            />
            <hr class="my-5">
            <vc-blog-url-input-form
                :submit-button-text="form.submitButtonLabel"
                :description="form.description"
                :input-placeholder="form.inputPlaceholder"
                :should-submit="!submittedBlogUrl"
                @submit="afterFormSubmit"
            />
        </div>
        <partner-banner
            v-if="shouldDisplayPartnerBanner"
            :logo-url="partnerLogoUrl"
            :website-link-url="partner.websiteUrl"
            :website-link-text="partner.linkLabel"
        />
    </div>
    <vc-blog-url-input-form-submission-message
        v-if="shouldDisplayModal"
        :message-text="form.submissionMessage"
        :close-button-text="form.submissionMessageCloseButtonLabel"
        @close="handleModalClose"
    />
</template>

<script>
import VcBlogUrlInputForm from "@/components/VcBlogAnalytics/components/VcBlogUrlInputForm";
import PartnerBanner from "@/components/VcBlogAnalytics/components/PartnerBanner";
import VcBlogUrlInputFormSubmissionMessage from "@/components/VcBlogAnalytics/components/VcBlogUrlInputFormSubmissionMessage";

export default {
    name: "VcBlogAnalyticsFormScreen",

    components: {
        VcBlogUrlInputFormSubmissionMessage,
        VcBlogUrlInputForm,
        PartnerBanner
    },

    props: {
        heading: {
            type: String,
            required: true
        },

        description: {
            type: String,
            required: true
        },

        form: {
            type: Object,
            required: true
        },

        partner: {
            type: Object,
            required: true
        }
    },

    emits: [ "blog-submitted" ],

    data() {
        return {
            shouldDisplayModal: false,
            submittedBlogUrl: ""
        };
    },

    computed: {
        domain() {
            return process.env.VUE_APP_API_HOST;
        },

        partnerLogoUrl() {
            const logoUrlEndpoint = this.partner?.logoUrl;

            if (!logoUrlEndpoint) {
                return null;
            }

            return this.domain + logoUrlEndpoint;
        },

        shouldDisplayPartnerBanner() {
            const partnerInformation = this.partner;

            if (!partnerInformation) {
                return false;
            }

            const { websiteUrl, linkLabel, logoUrl } = partnerInformation;
            return websiteUrl && linkLabel && logoUrl;
        }
    },

    methods: {
        afterFormSubmit(blogUrl) {
            this.shouldDisplayModal = true;
            this.submittedBlogUrl = blogUrl;
        },

        handleModalClose() {
            this.shouldDisplayModal = false;
            this.$emit("blog-submitted", this.submittedBlogUrl)
        }
    }
};
</script>

<style scoped></style>
