<template>
    <div class="mb-20">
        <base-title
            v-if="heading"
            class="text-center"
        >
            {{ heading }}
        </base-title>
        <div
            v-if="list"
            class="flex justify-around flex-wrap sm:gap-4"
        >
            <mission-element
                v-for="item in list"
                :key="item.id"
            >
                <template #icon>
                    <img
                        class="max-w-[100px] h-[100px]"
                        :src="link + item.image"
                        :alt="item.label"
                    >
                </template>
                <template #text>
                    {{ item.label }}
                </template>
            </mission-element>
        </div>
    </div>
</template>

<script>
import MissionElement from "@/components/Landing/Mission/MissionElement";
import BaseTitle from "@/UI/BaseTitle";

export default {
    // eslint-disable-next-line vue/match-component-file-name
    name: "MissionItem",
    components: { BaseTitle, MissionElement },
    props: {
        heading: {
            type: String,
            default: ""
        },
        list: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        link() {
            return process.env.VUE_APP_API_HOST;
        }
    }
};
</script>

<style scoped></style>
