import {required} from "@/helpers";
import {REQUIRED_FIELD} from "@/constants";

export const step1 = {
    company: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    cities: {
        value: [],
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    newCity: {
        value: '',
        validators: []
    },
    site: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    description: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    contacts: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    rusProfile: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
};

export const step2 = {
    secondMemberFullName: {
        value: "",
        validators: []
    },
    secondMemberPost: {
        value: "",
        validators: []
    },
    secondMemberMail: {
        value: "",
        validators: []
    },
    secondMemberTelegram: {
        value: "",
        validators: []
    },
};
export const step3 = {
    numberOfStaff: {
        value: "",
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    isAccredited: {
        value: 'false',
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    isBroker: {
        value: 'false',
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    formats: {
        value: [],
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    services: {
        value: [],
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    newServices: {
        value: "",
        validators: []
    },
    commentsToServeices: {
        value: "",
        validators: []
    },
    expertise: {
        value: [],
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    newExpertise: {
        value: "",
        validators: []
    },
    commentToDomainExpertise: {
        value: "",
        validators: []
    },
    stacks: {
        value: [],
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    newStacks: {
        value: "",
        validators: []
    },
    markets: {
        value: [],
        validators: [
            {
                validator: required,
                message: REQUIRED_FIELD
            }
        ]
    },
    newMarkets: {
        value: "",
        validators: []
    },
    commentToMarkets: {
        value: "",
        validators: []
    },
    content: {
        value: "",
        validators: []
    },
    industrialDevelopments: {
        value: "",
        validators: []
    },
    benchMap: {
        value: "",
        validators: []
    },
    commentToBenchMap: {
        value: "",
        validators: []
    },
    requestShowcase: {
        value: "",
        validators: []
    },
    commentToRequestShowcase: {
        value: "",
        validators: []
    }
}

