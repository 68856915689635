<template>
    <loading-content v-if="status.isPending" />
    <!--  ошибка при применении фильтра  -->
    <p
        v-if="status.isError"
        class="text-2xl text-center"
    >
        При загрузке ресурсных карт произошла ошибка, попробуйте выбрать другой фильтр
    </p>

    <p
        v-if="showEmpty"
        class="text-2xl text-center"
    >
        По вашему запросу ничего не найдено
    </p>
    <!--  ошибка при загрузке страницы  -->
    <p
        v-if="error"
        class="text-2xl text-center"
    >
        {{ message }}
    </p>

    <resource-list
        v-if="showList"
        :list="list"
        @on-click="onClick"
    />
</template>

<script>
import ResourceList from "@/components/ResourcePage/ResourceList";
import LoadingContent from "@/components/LoadingContent";

export default {
    components: {
        ResourceList,
        LoadingContent
    },
    props: {
        status: {
            type: Object,
            required: true
        },
        message: {
            type: String,
            default: ''
        },
        error: {
            type: Boolean,
            default: () => false
        },
        list: {
            type: Object,
            required: true
        }
    },
    emits: [ 'onClick' ],
    computed: {
        showEmpty() {
            return this.status.isSuccess && this.list.length === 0 && !this.error
        },
        showList() {
            return this.list.length > 0 && this.status.isSuccess
        }
    },
    methods: {
        onClick(event) {
            this.$emit('onClick', event)
        }
    }
}
</script>

<style scoped>

</style>
