<template>
    <drop-down
        v-model="value"
        :options="options"
        :label="label"
        :placeholder="placeholder"
        :name="name"
        class="w-full"
        :searchable="searchable"
        :error="error"
        :message="message"
        :disabled="disabled"
        :is-loading="status.isPending"
        @on-select="$emit('onSelect', $event)"
    />
</template>

<script>
import DropDown from "@/components/CalendarPage/components/DropDown";

import { toRef } from "vue";
import { loadData } from "@/use/load";
import { getDirectory } from "@/api";
import { mainHandler } from "@/handlers";
import { REQUIRED_FIELD } from "@/constants";

export default {
    name: "LoadDropDown",
    components: { DropDown },
    props: {
        modelValue: {
            type: Object,
            required: true
        },
        query: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            default: ""
        },
        label: {
            type: String
        },
        error: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
            default: REQUIRED_FIELD
        },
        searchable: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        moreOption: {
            type: Boolean,
            required: false,
            default: false
        },
        moreOptionTitle: {
            type: String,
            required: false
        }
    },
    emits: [ "onSelect" ],
    setup(props) {
        const request = () => getDirectory(props.query);
        const { data: response, status } = loadData({ query: request, handler: mainHandler, initialValue: [] });
        const data = toRef(response, "data");

        return {
            data,
            status
        };
    },
    computed: {
        options() {
            if (!this.data?.length) return [];
			
            let options = this.sortOptions([ ...this.data ]);

            if (this.moreOption) {
                options.push({ value: null, title: this.moreOptionTitle ? this.moreOptionTitle : 'Другое' });
            }
			
            return options || [];
        },
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("onSelect", { ...value });
            }
        }
    },
    methods: {
        sortOptions(options) {
            return [ ...options ].sort((a, b) => {
                return a.title.localeCompare(b.title);
            }).sort((a, b) => {
                return a.sorting - b.sorting;
            });
        }
    }
};
</script>

<style></style>
