<template>
    <page-loading
        :message="errors?.empty"
        :status="status"
        :empty="errors?.empty"
    >
        <div class="flex flex-wrap items-center justify-between max-w-full">
            <h2
                v-if="data.heading"
                class="text-2xl mb-5"
            >
                {{ data.heading }}
            </h2>
        </div>

        <webinar-board
            :message="errors?.list"
            :error="!!errors?.list"
            :webinars="data.list"
        />
        <join-association
            v-if="data.join"
            :join="data.join"
        />
    </page-loading>
</template>

<script>
import {loadData} from "@/use/load";
import {webinarsPage} from "@/api";
import {toRef} from "vue";
import PageLoading from "@/components/PageLoading";
import listHandler from "../handlers/listHandler";
import WebinarBoard from "@/components/WebinarsPage/WebinarBoard";
import JoinAssociation from "@/components/JoinAssociation";

export default {
    name: "WebinarsPage",
    components: { WebinarBoard, PageLoading, JoinAssociation },
    setup() {
        const { data: response, status } = loadData({ query: webinarsPage, handler: listHandler });
        const data = toRef(response, "data");
        const errors = toRef(response, "errors");

        return {
            data,
            errors,
            status
        };
    }
};
</script>

<style scoped></style>
