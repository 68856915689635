const listHandler = ({ errors, success, data }) => {
    if (success) {
        return { data, error: false }
    }

    if (errors?.empty) {
        return { error: false, errors }
    }

    const { heading, list } = errors;

    if (heading && list) {
        return { error: true, errors }
    }
    return { data, error: false, errors }

}

export default listHandler
