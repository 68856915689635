<template>
    <div class="mb-20">
        <base-title
            v-if="heading"
            class="text-center"
        >
            {{ heading }}
        </base-title>
        <div
            v-if="list"
            class="flex flex-wrap justify-center gap-4"
        >
            <profit-element
                v-for="item in list"
                :key="item.id"
            >
                {{ item.text }}
            </profit-element>
        </div>
    </div>
</template>

<script>
import ProfitElement from "@/components/Landing/Profit/Element";
import BaseTitle from "@/UI/BaseTitle";
export default {
    name: "ProfitItem",
    components: { BaseTitle, ProfitElement },
    props: {
        heading: {
            type: String,
            default: ""
        },
        list: {
            type: Array,
            default: () => []
        }
    }
};
</script>

<style scoped></style>
