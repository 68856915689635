<template>
    <p>
        <span v-text="label" /> – <span
            class="font-bold"
            v-text="count"
        />
    </p>
</template>

<script>
export default {
    name: "BlogValueCount",

    props: {
        label: {
            type: String,
            required: true
        },

        count: {
            type: Number,
            required: true
        }
    }
}
</script>

<style scoped>

</style>