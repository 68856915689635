<template>
    <div class="mb-20 relative group inline-block">
        <span class="absolute inset-0 translate-x-2 translate-y-2 bg-orange" />
        <div class="relative group inline-block p-8 border-2 border-current">
            <base-title v-if="heading">
                {{ heading }}
            </base-title>
            <div
                v-if="text"
                class="mb-1"
                v-html="text"
            />
        </div>
    </div>
</template>

<script>

import BaseTitle from "@/UI/BaseTitle";
export default {
    name: "BaseAgenda",
    components: { BaseTitle },
    props: {
        heading: {
            type: String,
            default: ""
        },
        text: {
            type: String,
            default: ""
        }
    }
};
</script>

<style scoped></style>
