<template>
    <div
        class="relative max-w-xs w-full min-h-max block group focus:outline-none focus:ring cursor-pointer"
        @click="clickCard"
    >
        <span
            class="absolute inset-0 transition-transform shadow-md translate-x-2 translate-y-2 bg-gray-50"
        />

        <div
            class="w-full h-full relative p-4 border-2 border-current"
        >
            <div class="flex gap-1">
                <h3 class="text-lg font-bold">
                    {{ company }}
                </h3>
                <span class="w-6 h-6 right-4 top-4 block">
                    <svg
                        v-if="isMember"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                        />
                    </svg>
                </span>
            </div>
            <span
                v-if="city.length"
                class="w-full block text-gray-700 text-xs mb-2"
            >
                {{ city.join(', ') }}
            </span>
            <div
                v-if="format.length"
                class="flex flex-wrap gap-1 mb-2"
            >
                <span class="block w-full text-xs">Формат сотрудничества</span>
                <p
                    v-for="item of format"
                    :key="item"
                    class="bg-orange py-0.5 px-1 text-sm"
                >
                    {{ item }}
                </p>
            </div>
            <div
                v-if="workers.length"
                class="flex flex-wrap gap-1 mb-2"
            >
                <span class="block w-full text-xs">Количество сотрудников</span>
                <p class="bg-blue-300 py-0.5 px-1 text-sm">
                    {{ workers.join(', ') }}
                </p>
            </div>
            <div
                v-if="stack.length"
                class="flex flex-wrap gap-1 mb-2"
            >
                <span class="block w-full text-xs">Стек</span>
                <p
                    v-for="item of stack"
                    :key="item"
                    class="bg-green-300 py-0.5 px-1 text-sm"
                >
                    {{ item }}
                </p>
            </div>
            <div
                v-if="services.length"
                class="flex flex-wrap gap-1 mb-2"
            >
                <span class="block w-full text-xs">Услуги</span>
                <p
                    v-for="service of services"
                    :key="service"
                    class="bg-pink py-0.5 px-1 text-sm"
                >
                    {{ service }}
                </p>
            </div>
            <div
                v-if="expertises.length"
                class="flex flex-wrap gap-1 mb-2"
            >
                <span class="block w-full text-xs">Доменная экспертиза</span>
                <p
                    v-for="item of expertises"
                    :key="item"
                    class="bg-fuchsia-300 py-0.5 px-1 text-sm"
                >
                    {{ item }}
                </p>
            </div>
            <div
                v-if="markets.length"
                class="flex flex-wrap gap-1 mb-2"
            >
                <span class="block w-full text-xs">Рынки</span>
                <p
                    v-for="market of markets"
                    :key="market"
                    class="bg-yellow-200 py-0.5 px-1 uppercase text-sm"
                >
                    {{ market }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ResourceCard",
    components: {},
    props: {
        company: {
            type: String,
            required: true
        },
        isMember: {
            type: Boolean,
            default: false
        },
        city: {
            type: Array,
            default: () => {}
        },
        format: {
            type: Array,
            default: () => []
        },
        workers: {
            type: Array,
            default: () => []
        },
        stack: {
            type: Array,
            default: () => []
        },
        services: {
            type: Array,
            default: () => []
        },
        expertises: {
            type: Array,
            default: () => []
        },
        markets: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        clickCard() {
            return this.$emit("clickCard");
        }
    }
};
</script>

<style scoped></style>
