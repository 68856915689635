<template>
    <div
        class="relative group inline-block cursor-pointer"
        @click="handleSelect"
    >
        <span
            :class="{
                ['bg-orange']: isSelected,
                ['bg-orange-light']: !isSelected,
                ['translate-y-0']: isSelected,
                ['translate-x-0']: isSelected,
            }"
            class="absolute inset-0
                translate-x-1.5
                transition-transform
                translate-y-1.5 group-hover:translate-y-0 group-hover:translate-x-0"
        />
        <div
            class="relative inline-block p-4 border-2 border-current"
            v-text="label"
        />
    </div>
</template>

<script>
export default {
    name: "PeriodSelectionButton",

    props: {
        id: {
            type: [ String, Number ],
            required: true
        },

        label: {
            type: String,
            required: true
        },

        isSelected: {
            type: Boolean,
            required: true
        }
    },

    emits: [ "select" ],

    methods: {
        handleSelect() {
            this.$emit("select", this.id);
        }
    }
}
</script>

<style scoped>

</style>
