<template>
    <main-templates>
        <div class="mt-[50px] mb-[50px]">
            <div class="relative text-9xl md:text-7xl text-center">
                <span class="absolute translate-x-1.5 translate-y-1.5 md:translate-x-1 md:translate-y-1">404</span>
                <span class="relative text-orange">404</span>
            </div>
            <div class="relative text-5xl md:text-5xl text-center">
                <span class="absolute translate-x-1.5 translate-y-1.5 md:translate-x-1 md:translate-y-1">Not Found</span>
                <span class="relative text-orange">Not Found</span>
            </div>
        </div>
    </main-templates>
</template>

<script>
import MainTemplates from "@/pages/MainTemplates";

export default {
    name: "ErrorPage",
    components: { MainTemplates },
}
</script>

<style scoped>

</style>
