<template>
    <div
        v-if="qr"
        class="max-w-3xl mb-10 relative block"
    >
        <span class="absolute inset-0 translate-x-2 translate-y-2 bg-white" />
        <div
            class="flex flex-wrap items-center
             relative inline-block p-4 border-2 border-current gap-4"
        >
            <div
                class="w-2/6 sm:w-full"
            >
                <div class="w-full relative h-0 pb-full">
                    <img
                        class="w-full absolute left-0 top-0"
                        :src="qr"
                        alt="Qr код телеграм канала"
                    >
                </div>
            </div>
            <div class="block sm:w-full flex-1">
                <p
                    v-if="heading"
                    class="mb-5"
                >
                    {{ heading }}
                </p>
                <a
                    v-if="link && buttonText"
                    :href="link"
                    target="_blank"
                    class="relative inline-block
                    group focus:outline-none focus:ring text-center sm:w-full"
                >
                    <span
                        class="absolute inset-0 transition-transform
                            translate-x-1.5 translate-y-1.5 bg-orange
                            group-hover:translate-y-0 group-hover:translate-x-0"
                    />

                    <span
                        class="w-full relative inline-block px-8 py-3 text-sm
                            font-bold tracking-widest text-black uppercase border-2 border-current
                            group-active:text-opacity-75"
                    >
                        {{ buttonText }}
                    </span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "QrLink",
    props: {
        qr: {
            type: String,
            required: true
        },
        heading: {
            type: String,
            required: true
        },
        buttonText: {
            type: String,
            required: true
        },
        link: {
            type: String,
            required: true
        }
    }
};
</script>

<style scoped></style>
