<template>
    <modal-template @on-close="close">
        <div class="max-w-[500px]">
            <div 
                class="[&>p]:mb-2"
                v-html="messageText"
            />
            <enter-button
                class="sm:w-auto"
                @click="close"
            >
                {{ closeButtonText }}
            </enter-button>
        </div>
    </modal-template>
</template>

<script>
import ModalTemplate from "@/components/Modal/ModalTemplate";
import EnterButton from "@/components/Enter/Button";

export default {
    name: "VcBlogUrlInputFormSubmissionMessage",

    components: {
        ModalTemplate,
        EnterButton
    },

    props: {
        messageText: {
            type: String,
            required: true
        },

        closeButtonText: {
            type: String,
            required: true
        }
    },
    
    emits: [ "close" ],
    
    methods: {
        close() {
            this.$emit("close");
        }
    }
}
</script>

<style scoped>

</style>