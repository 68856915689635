<template>
    <drop-down
        v-model="value"
        :options="options"
        :name="name"
        :label="label"
        :list="list"
        :is-loading="status.isPending"
        :placeholder="placeholder"
        @on-select="onSelect"
        @on-remove="remove"
    />
    <base-input
        v-if="newOptionVisible"
        v-model="newOptionValue"
        class="w-full"
        placeholder="Добавить свой вариант"
        @blur="createNewOption"
    />
    <span
        v-if="error"
        class="mt-2 text-red inline-block text-xs font-thin"
    >
        {{ message }}
    </span>
</template>

<script>
import { loadData } from "@/use/load";
import { getDirectory } from "@/api";
import { toRef } from "vue";
import { filterList, REQUIRED_FIELD } from "@/constants";
import { mainHandler } from "@/handlers";
import DropDown from "@/UI/DropDown";
import BaseInput from "@/UI/BaseInput";

export default {
    name: "SelectedOptions",
    components: { DropDown, BaseInput },
    props: {
        modelValue: {
            type: Array,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        moreOption: {
            type: Boolean,
            required: false,
            default: ""
        },
        moreOptionTitle: {
            type: String,
            required: false
        },
        query: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            default: ""
        },
        label: {
            type: String
        },
        error: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
            default: REQUIRED_FIELD
        }
    },
    emits: [ 'onSelect' ],
    setup(props) {
        const request = () => getDirectory(props.query)
        const { data: response, status } = loadData({ query: request, handler: mainHandler, initialValue: [] });
        const data = toRef(response, "data");
        return {
            data,
            status
        };
    },
    data() {
        return {
            list: [],
            listIds: [],
            showInput: false,
            newOption: null,
            newOptionVisible: false,
            newOptionValue: '',
            requiredError: REQUIRED_FIELD
        }
    },
	
    computed: {
        options() {
            if (!this.data?.length) return []
			
            let options = [ ...this.data ];
			
            if (this.shouldSort) {
                options = this.sortOptionsByTitle(options);
            }
			
            if (this.moreOption) {
                options.push({ value: 'no', title: this.moreOptionTitle ? this.moreOptionTitle : 'Другое' });
            }
			
            return options || [];
        },
		
        shouldSort() {
            return this.name !== filterList.WORKERS;
        },
		
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("onSelect", { value });
            }
        }
    },
	
    methods: {
        sortOptionsByTitle(options) {
            return [ ...options ].sort((a, b) => {
                return a.title.localeCompare(b.title);
            });
        },
        onSelect({ value }) {
            const index = this.list.findIndex(i => i.id === value[0].value);

            if (index !== -1) {
                this.list.splice(index, 1);
            }
			
            const moreOptions = value.findIndex(i => i.value === "no") !== -1;

            const item = {
                title: value[0].title,
                id: value[0].value,
                key: value,
                type: "orange"
            }
			
            if (!this.newOptionVisible || !moreOptions) {
                this.list = [ ...this.list, item ];
                
                this.listIds = [ ...this.listIds, item.id ]
            }
			
            if (moreOptions) this.newOptionVisible = true;
            
            this.$emit("onСhoose", this.listIds, this.name);
        },
        remove({ id }) {
            this.list = this.list.filter(i => i.id !== id);
            this.listIds = this.listIds.filter(i => i !== id);
            this.$emit("onСhoose", this.listIds, this.name);
        },
        createNewOption() {
            this.$emit("crateNewOption", this.newOptionValue, this.name);
        }
    }
}
</script>
