<template>
    <div

        class="flex flex-wrap gap-8 sm:justify-center"
    >
        <resource-card
            v-for="(card) in list"
            :key="card.id"
            :company="card.company"
            :city="card.city"
            :expertises="card.expertises"
            :format="card.format"
            :is-member="card.isMember"
            :markets="card.markets"
            :services="card.services"
            :stack="card.stacks"
            :workers="card.workers"
            @click-card="$emit('onClick', card.company)"
        />
    </div>
</template>

<script>
import ResourceCard from "@/components/ResourcePage/ResourceCard";

export default {
    name: "ResourceList",
    components: {
        ResourceCard
    },
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    emits: [ 'onClick' ]

};
</script>

<style scoped></style>
