<template>
    <article
        class="flex w-full gap-3 items-start border-2 border-current bg-white p-4"
    >
        <span class="mt-1 rounded bg-orange-light p-2 text-white">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="black"
                class="w-6 h-6"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z"
                />
            </svg>
        </span>

        <div>
            <a
                :href="link"
                target="_blank"
                class="block text-lg font-medium text-gray-900"
            >
                {{ heading }}
            </a>

            <a
                :href="link"
                target="_blank"
                class="group inline-flex items-center gap-1 mt-1"
            >
                Читать статью
                <span
                    aria-hidden="true"
                    class="block transition-all group-hover:ms-0.5 rtl:rotate-180"
                >
                    &rarr;
                </span>
            </a>
        </div>
    </article>
</template>

<script>
export default {
    name: "ArticlesItem",
    props: {
        link: String,
        heading: String
    }
}
</script>

<style scoped>

</style>