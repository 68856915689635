<template>
    <div class="mb-20">
        <base-title
            class="text-center"
        >
            {{ heading }}
        </base-title>
        <div
            v-dragscroll.x="true"
            class="mb-20 -mx-5 px-5 pb-5 grid overflow-x-scroll grid-flow-col grid-rows-1 content-center gap-6"
        >
            <feedback-card
                v-for="card of list"
                :key="card"
                :card="card"
            />
        </div>
    </div>
</template>

<script>
import BaseTitle from "@/UI/BaseTitle";
import FeedbackCard from "@/components/Landing/Feedback/FeedbackCard";
import { dragscroll } from "vue-dragscroll";

export default {
    name: "Feedback",
    components: { BaseTitle, FeedbackCard },
    directives: {
        'dragscroll': dragscroll
    },
    props: {
        heading: {
            type: String,
            default: ""
        },
        list: {
            type: Array,
            default: () => []
        }
    }
}
</script>

<style scoped>

</style>