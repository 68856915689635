<template>
    <div class="relative flex items-stretch w-full max-w-[250px] xl:max-w-[350px] xl:min-h-[150px]">
        <span class="absolute inset-0 translate-x-3 translate-y-3 bg-[url('../public/pattern_circles.png')] bg-no-repeat bg-cover" />
        <div class="relative p-4  border-2 border-current flex items-start gap-2">
            <img
                class="max-w-[20px]"
                src="../../../assets/check.png"
                alt="&#9989;"
            >
            <div>
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProfitElement"
}
</script>

<style scoped>

</style>
