import { createApp } from "vue";
import App from "./App.vue";
import "./index.css";
import router from "@/router";
import plugins from "@/plugins";
import { VueMasonryPlugin } from "vue-masonry";
import store from "@/store";
import VueYandexMetrika from "vue-yandex-metrika";

createApp(App)
    .use(store)
    .use(router)
    .use(VueMasonryPlugin)
    .use(plugins)
    .use(VueYandexMetrika, {
        id: 92115971,
        env: process.env.NODE_ENV,
        router: router,
        options:
            {
                webvisor: true
            }
    })
    .mount("#app")
