<template>
    <div class="w-full max-w-lg mx-auto flex flex-col gap-4">
        <h2 class="text-lg font-black">
            Сотрудники, технологии, инструменты
        </h2>
        <base-input
            v-model="field.numberOfStaff.value"
            class="w-full"
            label="Количество сотрудников"
            placeholder="Без учета партрнерских ресурсов"
            :error="field.numberOfStaff.isError"
            :message="field.numberOfStaff.message"
            @input="field.numberOfStaff.input"
            @blur="field.numberOfStaff.blur"
        />
		
        <div class="w-full flex flex-col gap-2">
            Есть IT-аккредитация?
            <group-radio
                v-model="field.isAccredited.value"
                :text-error="field.isAccredited.message"
                :error="field.isAccredited.isError"
                class="-m-4"
                name="isAccredited"
                :variants="[
                    { id: 'accreditation-yes', title: 'Да', value: 'true' },
                    { id: 'accreditation-no', title: 'Нет', value: 'false' },
                ]"
                @input="field.isAccredited.input"
            />
        </div>
        <div class="w-full flex flex-col gap-2">
            Являетесь брокером (продаете чужие ресурсы)?
            <group-radio
                v-model="field.isBroker.value"
                :text-error="field.isBroker.message"
                :error="field.isBroker.isError"
                class="-m-4"
                name="isBroker"
                :variants="[
                    { id: 'broker-yes', title: 'Да', value: 'true' },
                    { id: 'broker-no', title: 'Нет', value: 'false' },
                ]"
                @input="field.isBroker.input"
            />
        </div>
        <group-checkbox
            v-model="field.formats.value"
            title="Формат сотрудничества"
            :variants="[
                { id: 1, name: 'Аутсорсинг', value: false },
                { id: 2, name: 'Аутстаффинг', value: false },
            ]"
            :error="field.formats.isError"
            :text-error="field.formats.message"
            @select="changeFormat"
        />
        <div class="w-full flex flex-col gap-2">
            <selected-options
                v-model="field.services.value"
                name="services"
                label="Услуги"
                placeholder="Выберите услуги"
                :more-option="true"
                more-option-title="Добавить свои услуги"
                :query="request.SERVICES"
                :error="field.services.isError"
                :message="field.services.message"
                @onСhoose="changeList"
                @crateNewOption="createNewServices"
            />
            <base-input
                v-model="field.commentsToServeices.value"
                type="textarea"
                class="w-full"
                placeholder="Комментарии по услугам"
                :error="field.commentsToServeices.isError"
                :message="field.commentsToServeices.message"
                @input="field.commentsToServeices.input"
                @blur="field.commentsToServeices.blur"
            />
        </div>
        <div class="w-full flex flex-col gap-2">
            <selected-options
                v-model="field.expertise.value"
                name="expertise"
                label="Экспертиза"
                placeholder="Выберите экспертизу"
                :more-option="true"
                more-option-title="Добавить свою экспертизу"
                :query="request.EXPERTISE"
                :error="field.expertise.isError"
                :message="field.expertise.message"
                @onСhoose="changeList"
                @crateNewOption="createNewExpertise"
            />
            <base-input
                v-model="field.commentToDomainExpertise.value"
                type="textarea"
                class="w-full"
                placeholder="Комментарии по экспертизе"
                :error="field.commentToDomainExpertise.isError"
                :message="field.commentToDomainExpertise.message"
                @input="field.commentToDomainExpertise.input"
                @blur="field.commentToDomainExpertise.blur"
            />
        </div>
		
        <div class="w-full flex flex-col gap-2">
            <selected-options
                v-model="field.stacks.value"
                name="stacks"
                label="Стек"
                placeholder="Выберите стек"
                :more-option="true"
                more-option-title="Добавить свой стек"
                :query="request.STACK"
                :error="field.stacks.isError"
                :message="field.stacks.message"
                @onСhoose="changeList"
                @crateNewOption="createNewStacks"
            />
        </div>
		
        <div class="w-full flex flex-col gap-2">
            <selected-options
                v-model="field.markets.value"
                name="markets"
                label="Рынки"
                placeholder="Выберите рынки"
                :more-option="true"
                more-option-title="Добавить свой рынок"
                :query="request.MARKETS"
                :error="field.markets.isError"
                :message="field.markets.message"
                @onСhoose="changeList"
                @crateNewOption="createNewMarkets"
            />
            <base-input
                v-model="field.commentToMarkets.value"
                type="textarea"
                class="w-full"
                placeholder="Комментарии по рынкам"
                :error="field.commentToMarkets.isError"
                :message="field.commentToMarkets.message"
                @input="field.commentToMarkets.input"
                @blur="field.commentToMarkets.blur"
            />
        </div>
		
        <base-input
            v-model="field.content.value"
            type="textarea"
            class="w-full"
            label="Контент"
            placeholder="Блоги, youtube, tg-каналы и тд"
            :error="field.content.isError"
            :message="field.content.message"
            @input="field.content.input"
            @blur="field.content.blur"
        />
        <base-input
            v-model="field.industrialDevelopments.value"
            type="textarea"
            class="w-full"
            label="Индустриальные наработки"
            placeholder="Opensource, продукты и т.д."
            :error="field.industrialDevelopments.isError"
            :message="field.industrialDevelopments.message"
            @input="field.industrialDevelopments.input"
            @blur="field.industrialDevelopments.blur"
        />
		
        <div class="w-full flex flex-col gap-2">
            <base-input
                v-model="field.benchMap.value"
                class="w-full"
                label="Ссылка на бенч-карту"
                placeholder="Вставьте ссылку на бенч-карту"
                :error="field.benchMap.isError"
                :message="field.benchMap.message"
                @input="field.benchMap.input"
                @blur="field.benchMap.blur"
            />
            <base-input
                v-model="field.commentToBenchMap.value"
                type="textarea"
                class="w-full"
                placeholder="Комментарий к вашей бенч-карте"
                :error="field.commentToBenchMap.isError"
                :message="field.commentToBenchMap.message"
                @input="field.commentToBenchMap.input"
                @blur="field.commentToBenchMap.blur"
            />
        </div>
        <div class="w-full flex flex-col gap-2">
            <base-input
                v-model="field.requestShowcase.value"
                class="w-full"
                label="Ссылка на витрину запросов"
                placeholder="Вставьте ссылку на витрину запросов"
                :error="field.requestShowcase.isError"
                :message="field.requestShowcase.message"
                @input="field.requestShowcase.input"
                @blur="field.requestShowcase.blur"
            />
            <base-input
                v-model="field.commentToRequestShowcase.value"
                type="textarea"
                class="w-full"
                placeholder="Комментарий к вашей витрине"
                :error="field.commentToRequestShowcase.isError"
                :message="field.commentToRequestShowcase.message"
                @input="field.commentToRequestShowcase.input"
                @blur="field.commentToRequestShowcase.blur"
            />
        </div>
		
        <enter-button
            type="submit"
            class="w-full"
            @click="toNextStep"
        >
            Отправить
        </enter-button>
    </div>
</template>

<script>
import BaseInput from "@/UI/BaseInput";
import EnterButton from "@/components/Enter/Button";
import { request } from "@/constants";
import SelectedOptions from "@/components/FormResourceMap/SelectedOptions";
import GroupRadio from "@/UI/GroupRadio";
import GroupCheckbox from "@/UI/GroupCheckbox";
import { submit } from "@/use/submit";
import { step3 } from "@/configForms/newCompany";
import { toRef } from "vue";

export default {
    name: "Step3",
    components: { BaseInput, EnterButton, SelectedOptions, GroupRadio, GroupCheckbox },
    setup() {
        const init = submit({
            fields: step3
        });
		
        const form = toRef(init, "form");
        const status = toRef(init, "status");
        return { form, status };
    },
    data() {
        return {
            request: request,
            stop: false
        };
    },
	
    computed: {
        field() {
            return {
                ...this.form.fields
            };
        }
    },
	
    methods: {
        checkErrors() {
            if (this.field.services.value.length === 1) {
                const checkNewServices = this.field.services.value.findIndex(i => i === 'no') !== -1;

                if (checkNewServices && !this.field.newServices.value) {
                    this.field.services.isError = true
                    this.field.services.message = this.message
                    this.stop = true
                } else {
                    this.stop = false
                }
            }
            if (this.field.expertise.value.length === 1) {
                const checkNewExpertise = this.field.expertise.value.findIndex(i => i === 'no') !== -1;

                if (checkNewExpertise && !this.field.newExpertise.value) {
                    this.field.expertise.isError = true
                    this.field.expertise.message = this.message
                    this.stop = true
                } else {
                    this.stop = false
                }
            }
            if (this.field.stacks.value.length === 1) {
                const checkNewStacks = this.field.stacks.value.findIndex(i => i === 'no') !== -1;

                if (checkNewStacks && !this.field.newStacks.value) {
                    this.field.stacks.isError = true
                    this.field.stacks.message = this.message
                    this.stop = true
                } else {
                    this.stop = false
                }
            }
            if (this.field.markets.value.length === 1) {
                const checkNewMarkets = this.field.markets.value.findIndex(i => i === 'no') !== -1;

                if (checkNewMarkets && !this.field.newMarkets.value) {
                    this.field.markets.isError = true
                    this.field.markets.message = this.message
                    this.stop = true
                } else {
                    this.stop = false
                }
            }
        },
        toNextStep() {
            this.checkErrors();
			
            if (!this.form.valid || this.stop) {
                this.form.showErrors()
                return;
            }
            this.$emit('finish', this.form.fields)
        },
        changeList(list, name) {
            this.field[name].value = list;
            if (this.field[name].value.length >= 2) {
                this.stop = false
            }
        },
        changeFormat(data) {
            this.field.formats.value = [ ...data ];
        },
        createNewServices(value) {
            const name = value;
            if (name) {
                this.field.newServices.value = name;
                this.field.services.isError = false;
                this.stop = false;
            } else if (this.field.services.value.length >= 2) {
                this.field.services.isError = false
                this.stop = false
            } else {
                this.field.newServices.value = name;
                this.field.services.isError = true
                this.field.services.message = this.message
            }
        },
        createNewExpertise(value) {
            const name = value;
            if (name) {
                this.field.newExpertise.value = name;
                this.field.expertise.isError = false;
                this.stop = false;
            } else if (this.field.expertise.value.length >= 2) {
                this.field.expertise.isError = false
                this.stop = false
            } else {
                this.field.newExpertise.value = name;
                this.field.expertise.isError = true
                this.field.expertise.message = this.message
            }
        },
        createNewStacks(value) {
            const name = value;
            if (name) {
                this.field.newStacks.value = name;
                this.field.stacks.isError = false;
                this.stop = false;
            } else if (this.field.stacks.value.length >= 2) {
                this.field.stacks.isError = false
                this.stop = false
            } else {
                this.field.newStacks.value = name;
                this.field.stacks.isError = true
                this.field.stacks.message = this.message
            }
        },
        createNewMarkets(value) {
            const name = value;
            if (name) {
                this.field.newMarkets.value = name;
                this.field.markets.isError = false;
                this.stop = false;
            } else if (this.field.markets.value.length >= 2) {
                this.field.markets.isError = false
                this.stop = false
            } else {
                this.field.newMarkets.value = name;
                this.field.markets.isError = true
                this.field.markets.message = this.message
            }
        }
    }
}
</script>

<style scoped>

</style>
