<template>
    <Component
        :is="tag"
        v-if="logo"
        :href="site"
        target="_blank"
        class="flex justify-center place-self-center w-[120px] h-[120px] group sm:w-[100px] sm:h-[100px]"
    >
        <img
            class="justify-self-center self-center max-h-[80%] max-w-[80%] group-hover:scale-110 sm:group-hover:scale-100 sm:max-h-[100%] sm:max-w-[100%]"
            :src="link + logo"
            :alt="site"
            :title="link + logo"
        >
    </Component>
</template>

<script>
export default {
    name: "ParticipantsMember",
    props: {
        logo: {
            type: String,
            default: ""
        },
        site: {
            type: String,
            default: ""
        }
    },
    computed: {
        link() {
            return process.env.VUE_APP_API_HOST;
        },
        tag() {
            return this.site ? "a" : "div";
        }
    }
};
</script>

<style scoped></style>
