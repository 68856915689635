import { API_REQUEST, request } from "@/constants";
import { axiosGet, axiosGetId, axiosPost } from "@/helpers";
import axiosInstance from "@/plugins/axios";

export const faqPage = () => axiosGet(request.FAQ);
export const mainPage = () => axiosGet(request.MAIN);
export const personalPage = () => axiosGet(request.PERSONAL);
export const leadsPage = () => axiosGet(request.LEADS);
export const linksPage = () => axiosGet(request.LINKS);
export const reportsPage = () => axiosGet(request.REPORTS);
export const standardPage = () => axiosGet(request.STANDARD);
export const mapPage = () => axiosGet(request.MAPS);
export const webinarsPage = () => axiosGet(request.WEBINARS);
export const prSupportPage = () => axiosGet(request.PR_SUPPORT);
export const getUser = () => axiosGet(request.USER);
export const calendarPage = () => axiosGet(request.CALENDAR);
export const getScripts = () => axiosGet(request.SCRIPTS);
export const fetchVcBlogAnalyticsPage = () => axiosGet(request.VC_BLOG_ANALYTICS);
export const fetchUserCompanyVcBlogStats = () => axiosGet(request.USER_VC_BLOG_STATS);
export const getAllCards = () => axiosGet(request.CARDS);
export const getEventType = () => axiosGet(request.EVENT_TYPE);
export const getCompanyUsers = () => axiosGet(request.USER_COMPANY);
export const getAllEventsCardsAll = () => axiosGet(request.EVENTS_ALL);
export const getAllEventsCards = () => axiosGet(request.EVENTS);

export const authQuery = data => axiosPost(request.AUTH, data);
export const joinPersonal = () => axiosPost(request.JOIN_PERSONAL);
export const changePassword = data => axiosPost(request.CHANGE_PASS, data);
export const sendRegistrationForm = data => axiosPost(request.REG_FORM, data);
export const createEvent = data => axiosPost(request.NEW_EVENT, data);
export const createEventCard = data => axiosPost(request.EVENTS, data);
export const createCollectionMap = data => axiosPost(request.CARDS, data);
export const forgotPassword = data => axiosPost(request.FORGOT_PASS, data);
export const resetPassword = data => axiosPost(request.RESET_PASS, data);
export const createUser = data => axiosPost(request.USERS, data);
export const createCity = data => axiosPost(request.CITY, data);

export const whatsNewPage = () => axiosGet(request.WHATS_NEW);
export const getCardEventFromId = id => axiosGetId(request.EVENT_CARD, id);
export const sendVcBlogUrl = ({ url, company }) => {
    return axiosPost(request.VC_BLOGS, {
        data: {
            url,
            company 
        } 
    });
}
export const changeEmployee = async (data, id) => {
    return axiosInstance.put(`${API_REQUEST[request.USERS]}/${id}`, data)
}
export const changeEventCardId = async (data, id) => {
    return axiosInstance.put(`${API_REQUEST[request.EVENTS]}/${id}`, data)
}

export const updateUser = async data => {
    return axiosInstance.patch(API_REQUEST[request.USER], data)
}
export const getCards = async query => {
    return axiosInstance.get(API_REQUEST[request.CARDS], {
        params: query
    })
}

export const getDirectory = async query => {
    return axiosInstance.get(API_REQUEST[query])
}

export const sendEmailToConfirmEmployee = async id => {
    return axiosInstance.post(`${API_REQUEST[request.SEND_EMPLOYEE_EMAIL_ACTIVATE]}/${id}`)
}
export const sendEmailToAdminForActivate = async id => {
    return axiosInstance.post(`${API_REQUEST[request.ACTIVATE_ACCOUNT_FOR_ADMIN_EMAIL]}/${id}`)
}

export const sendEmailActivateAccount = data => axiosPost(request.ACTIVATE_ACCOUNT_EMAIL, data)

export const deleteEventCard = async id => {
    return axiosInstance.delete(`${API_REQUEST[request.EVENTS]}/${id}`)
}
