<template>
    <query-modal
        :error="status.isError"
        :pending="status.isPending"
        :success="status.isSuccess"
        @close="closeModalRegistration"
    >
        <template #modal>
            <form
                class="w-full max-w-[412px]"
                @submit.prevent="onSubmit"
            >
                <div class="flex justify-between items-start mb-4">
                    <div>
                        <h2 class="text-lg font-black">
                            ВСТУПИТЬ В ИТ-КЛАСТЕР
                        </h2>
                        <p class="text-sm font-thin max-w-[250px]">
                            Заполните заявку, чтобы присоединиться к сообществу
                        </p>
                    </div>
                </div>
                <input-email
                    v-model.trim="field.email.value"
                    class="mb-4 w-full"
                    label="Почта"
                    type="email"
                    placeholder="Введите почту"
                    :error="field.email.isError"
                    :message="field.email.message"
                    @input="field.email.input"
                    @blur="field.email.blur"
                />
              <base-input
                  v-model.trim="field.phone.value"
                  class="mb-4 w-full"
                  label="Телефон"
                  type="tel"
                  placeholder="Введите телефон"
                  :error="field.phone.isError"
                  :message="field.phone.message"
                  @input="field.phone.input"
                  @blur="field.phone.blur"
              />
                <base-input
                    v-model.trim="field.name.value"
                    class="mb-4 w-full"
                    label="Имя"
                    type="text"
                    placeholder="Введите имя"
                    :error="field.name.isError"
                    :message="field.name.message"
                    @input="field.name.input"
                    @blur="field.name.blur"
                />
                <base-input
                    v-model.trim="field.lastName.value"
                    class="mb-4 w-full"
                    label="Фамилия"
                    type="text"
                    placeholder="Введите фамилию"
                    :error="field.lastName.isError"
                    :message="field.lastName.message"
                    @blur="field.lastName.blur"
                    @input="field.lastName.input"
                />
                <base-input
                    v-model.trim="field.position.value"
                    class="mb-4 w-full"
                    label="Должность"
                    type="text"
                    placeholder="Введите должность"
                    :error="field.position.isError"
                    :message="field.position.message"
                    @blur="field.position.blur"
                    @input="field.position.input"
                />
                <base-input
                    v-model.trim="field.company.value"
                    class="mb-4 w-full"
                    label="Название компании"
                    type="text"
                    placeholder="Введите название"
                    :error="field.company.isError"
                    :message="field.company.message"
                    @blur="field.company.blur"
                    @input="field.company.input"
                />
                <base-input
                    v-model.trim="field.site.value"
                    class="mb-4 w-full"
                    label="Ссылка на сайт компании"
                    type="text"
                    placeholder="Укажите ссылку"
                    :error="field.site.isError"
                    :message="field.site.message"
                    @blur="field.site.blur"
                    @input="field.site.input"
                />
                <ul class="list-inside list-decimal">
                    <li>
                        Программисты составляют бОльшую долю штата наемных сотрудников?
                        <group-radio
                            v-model="field.programmers.value"
                            :text-error="field.programmers.message"
                            :error="field.programmers.isError"
                            name="programmers"
                            :variants="[
                                { id: 'programmers-yes', title: 'Да', value: 'true' },
                                { id: 'programmers-no', title: 'Нет', value: 'false' },
                            ]"
                            @input="field.programmers.input"
                        />
                    </li>

                    <li>
                        Оцените, насколько стабильность вашей компании зависит от
                        посредников?
                        <base-range
                            v-model="field.stability.value"
                            :start="0"
                            end="10"
                            :step="1"
                            start-description="совсем не зависит, есть свой отдел продаж"
                            end-description="совсем не налажен маркетинг и продажи, полностью зависит"
                            @input="field.stability.input"
                        />
                    </li>

                    <li>
                        Есть ли у вас опыт работы по модели аутстаффинг в роли поставщика
                        или заказчика?
                        <group-radio
                            v-model="field.outstaffing.value"
                            :error="field.outstaffing.isError"
                            :text-error="field.outstaffing.message"
                            name="outstaffing"
                            :variants="[
                                { id: 'outstaffing-yes', title: 'Да', value: 'true' },
                                { id: 'outstaffing-no', title: 'Нет', value: 'false' },
                            ]"
                            @input="field.outstaffing.input"
                        />
                    </li>

                    <li>
                        Оцените по шкале от 0 до 10 свое отношение к White-label
                        аутстаффингу
                        <base-range
                            v-model="field.whiteLabelOutstaffing.value"
                            :start="0"
                            end="10"
                            :step="1"
                            start-description="негативно, не работаю с перекупщиками"
                            end-description="положительно, не вижу проблем"
                            @input="field.whiteLabelOutstaffing.input"
                        />
                    </li>
                </ul>
                <div class="mb-4">
                    <base-checkbox
                        id="agreement"
                        v-model="field.agreement.value"
                        :error="field.agreement.isError"
                        name="agreement"
                        :text-error="field.agreement.message"
                        @input="field.agreement.input"
                    >
                        <span
                            class="absolute left-10 -bottom-2 w-full min-w-[240px] sm:text-xs sm:bottom-0"
                        >
                            Я согласен с
                            <a
                                href="https://arda.digital/privacy-policy/"
                                target="_blank"
                                class="underline text-gray-500"
                            >
                                политикой конфиденциальности
                            </a>
                        </span>
                    </base-checkbox>
                </div>
                <enter-button
                    type="submit"
                    class="mb-4 w-full"
                >
                    Отправить заявку
                </enter-button>
            </form>
        </template>
        <template #success>
            <p class="mt-8 mb-3">
                Заявка на вступление в ARDA успешно отправлена
            </p>
        </template>
    </query-modal>
</template>

<script>
import BaseCheckbox from "@/UI/BaseCheckbox";
import EnterButton from "@/components/Enter/Button";
import BaseInput from "@/UI/BaseInput";
import BaseRange from "@/UI/BaseRange";
import GroupRadio from "@/UI/GroupRadio";
import InputEmail from "@/UI/InputEmail";
import { sendFormReg } from "@/api/formattedResponse";
import QueryModal from "@/components/QueryModal";
import { toRef } from "vue";
import { submit } from "@/use/submit";
import mainRegistration from "@/configForms/mainRegistration";

export default {
    name: "RegistrationModal",
    components: {
        QueryModal,
        BaseCheckbox,
        EnterButton,
        BaseInput,
        BaseRange,
        GroupRadio,
        InputEmail
    },
    emits: [ 'closeModalRegistration' ],
    setup() {
        const init = submit({
            submit: sendFormReg,
            fields: mainRegistration
        });

        const form = toRef(init, "form");
        const callback = toRef(init, "onSubmit");
        const status = toRef(init, "status");
        return { form, submit: callback, status };
    },

    computed: {
        field() {
            return {
                ...this.form.fields
            };
        }
    },
    methods: {
        onSubmit() {
            console.log('submit')
            if (!this.form.valid) {
                this.form.showErrors()
                return;
            }
            this.submit();
        },

        closeModalRegistration() {
            this.$emit("closeModalRegistration");
        }
    }
};
</script>

<style scoped></style>
