<template>
    <div class="w-full sm:max-w-full text-sm">
        <div class="flex items-center gap-2">
            <span>0%</span>
            <div
                ref="runnerContainer"
                class="relative flex-grow h-[20px] bg-white rounded-3xl overflow-hidden border border-orange"
            >
                <div
                    ref="runner"
                    class="h-full bg-orange"
                />
                <p
                    v-if="hasValidPercentage"
                    class="absolute w-full h-full top-0 left-0 text-center text-black"
                >
                    {{ percentage }}%
                </p>
            </div>
            <div class="inline-flex items-center">
                <span>100%</span>
                <div class="relative">
                    <img
                        class="w-3.5 text-color ml-1 opacity-30 hover:opacity-50 cursor-pointer"
                        src="@/assets/question-mark-rounded.svg"
                        alt=""
                        @click="toggleTotalValueCount"
                    >
                    <div class="absolute bottom-5 right-0 bg-white inline-block w-[200px]">
                        <total-value-count
                            v-show="shouldDisplayTotalValueCount"
                            :label="totalCountLabel"
                            :count="totalCount"
                            @close="hideTotalValueCount"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TotalValueCount from "@/components/VcBlogAnalytics/components/TotalValueCount";
export default {
    name: "PercentageScale",
    components: { TotalValueCount },
    props: {
        percentage: {
            type: Number,
            required: true
        },

        totalCount: {
            type: Number,
            required: true
        },

        totalCountLabel: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            shouldDisplayTotalValueCount: false
        }
    },

    computed: {
        hasValidPercentage() {
            return this.percentage === 0 || this.percentage;
        }
    },

    watch: {
        percentage() {
            this.updateRunnerWidth();
        }
    },

    mounted() {
        this.updateRunnerWidth();
        window.addEventListener("resize", this.updateRunnerWidth);
    },

    unmounted() {
        window.removeEventListener("resize", this.updateRunnerWidth);
    },

    methods: {
        updateRunnerWidth() {
            const containerElementWidth = this.$refs.runnerContainer.getBoundingClientRect().width;
            const percentageWidth = Math.round(containerElementWidth / 100 * this.percentage);
            this.$refs.runner.style.width = `${percentageWidth}px`;
        },

        toggleTotalValueCount() {
            if (this.shouldDisplayTotalValueCount) {
                this.hideTotalValueCount();
            } else {
                this.showTotalValueCount();
            }
        },

        hideTotalValueCount() {
            this.shouldDisplayTotalValueCount = false;
        },

        showTotalValueCount() {
            this.shouldDisplayTotalValueCount = true;
        }
    }
}
</script>

<style scoped>

</style>