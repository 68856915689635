<template>
    <div class="flex flex-wrap">
        <span
            v-if="label"
            class="w-full text-xs font-thin mb-1"
        >
            {{ label }}
        </span>
        <div class="relative block group w-full">
            <span
                class="absolute inset-0 transition-transform translate-x-1.5 translate-y-1.5 bg-orange-light group-hover:translate-y-0 group-hover:translate-x-0 group-focus:translate-y-0 group-focus:translate-x-0"
            />

            <vue-multiselect
                v-model="value"
                class="border-current border-2 multiselect-custom"
                :options="options"
                :show-labels="false"
                :multiple="true"
                :searchable="false"
                :placeholder="placeholder"
                :loading="isLoading"
                label="title"
                track-by="value"
            >
                <template #selection="{ values }">
                    <span
                        v-if="values.length"
                        class="text-sm font-bold tracking-widest text-[#9ca3af]"
                    >
                        {{ placeholder }}
                    </span>
                </template>
                <template #placeholder>
                    <span class="text-sm font-bold tracking-widest text-[#9ca3af]">
                        {{ placeholder }}
                    </span>
                </template>
                <template #noOptions>
                    <span class="text-sm font-bold tracking-widest text-black">Нет вариантов для выбора</span>
                </template>
            </vue-multiselect>
        </div>
    </div>

    <selected-list
        v-if="list.length"
        :list="list"
        @remove="remove"
    />
</template>

<script>
import SelectedList from "@/components/ResourcePage/SelectedList";
import VueMultiselect from "vue-multiselect";

export default {
    name: "DropDown",
    components: { SelectedList, VueMultiselect },
    props: {
        name: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            default: ""
        },
        label: {
            type: String
        },
        options: {
            type: Array,
            required: true
        },
        list: {
            type: Array
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    emits: [ 'onSelect' ],
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("onSelect", { value, name: this.name });
            }
        }
    },
    methods: {
        onSelect({ value }) {
            this.$emit("onSelect", { value, name: this.name });
        },
        remove({ id }) {
            this.$emit("onRemove", { id: id });
        }
    }
}
</script>

<style scoped>

</style>