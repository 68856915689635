<template>
	<div class="w-full max-w-lg mx-auto flex flex-col gap-4">
		<div>
			<h2 class="text-lg font-black">Второй участник telegram-чата</h2>
			<p class="text-sm font-thin">Сердцем кластера является telegram-чат, в котором собраны руководители
				IT-компаний. От каждой компании добавляем не более 2х участников. Строго C-level. Если хотите добавить
				второго участника, то заполните поля ниже или просто пропустите шаг</p>
		</div>
		<base-input
			v-model="name"
			class="w-full"
			label="ФИО второго участника"
			placeholder="Введите ФИО"
			:error="!corrected && !name"
			:message="message"
			@input="onInput"
		/>
		<base-input
			v-model="post"
			class="w-full"
			label="Должность второго участника"
			placeholder="Введите должность"
			:error="!corrected && !post"
			:message="message"
			@input="onInput"
		/>
		<input-email
			v-model="email"
			class="w-full"
			label="Почта"
			type="email"
			placeholder="Введите почту"
			:error="!emailCorrected || !corrected && !email"
			:message="emailMessage"
			@input="onEmailInput"
		/>
		<base-input
			v-model="telegram"
			class="w-full"
			label="Telegram второго участника"
			placeholder="Введите username"
			:error="!corrected && !telegram"
			:message="message"
			@input="onInput"
		/>
		<enter-button class="w-full" @click="toNextStep">Дальше</enter-button>
	</div>
</template>

<script>
import BaseInput from "@/UI/BaseInput";
import EnterButton from "@/components/Enter/Button";
import InputEmail from "@/UI/InputEmail";
import {submit} from "@/use/submit";
import {toRef} from "vue";
import {step2} from "@/configForms/newCompany";
import {isValidEmail} from "@/helpers";
import {REQUIRED_FIELD, INVALID_EMAIL} from "@/constants";

export default {
	name: "Step2",
	components: {BaseInput, EnterButton, InputEmail},
	
	data() {
		return {
			name: '',
			post: '',
			email: '',
			telegram: '',
			corrected: true,
			message: REQUIRED_FIELD,
			emailMessage: REQUIRED_FIELD,
			emailCorrected: true,
		};
	},
	setup() {
		const init = submit({
			fields: step2
		});
		
		const form = toRef(init, "form");
		const status = toRef(init, "status");
		return {form, status};
	},
	computed: {
		field() {
			return {
				...this.form.fields
			};
		}
	},
	methods: {
		toNextStep() {
			if (!this.corrected) {
				this.form.showErrors()
				return;
			}
			
			if (this.corrected && this.emailCorrected) {
				return this.$emit('toNext', this.form.fields);
			}
		},
		checkingForEmptiness() {
			if (!this.name && !this.post && !this.email && !this.telegram) {
				this.corrected = true;
				this.emailCorrected = true;
				return;
			}
			
			if (!this.name || !this.post || !this.email || !this.telegram) {
				return this.corrected = false;
			}
			
			if (this.name && this.post && this.telegram && this.emailCorrected) {
				return this.corrected = true;
			}
		},
		onInput() {
			this.checkingForEmptiness();
			
			this.field.secondMemberFullName.value = this.name;
			this.field.secondMemberPost.value = this.post;
			this.field.secondMemberTelegram.value = this.telegram;
		},
		onEmailInput() {
			this.checkingForEmptiness();
			if (this.email && !isValidEmail(this.email)) {
				this.emailMessage = INVALID_EMAIL;
				this.emailCorrected = false;
				return;
			}
			if (isValidEmail(this.email)) {
				this.emailCorrected = true;
			}
			
			this.field.secondMemberMail.value = this.email;
		},
	}
}
</script>

<style scoped>

</style>
