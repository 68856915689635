<template>
    <footer class="bg-black py-10 px-5 mt-auto">
        <div class="flex flex-wrap gap-5 justify-between items-end max-w-[1500px] mx-auto">
            <div class="flex items-end">
                <img
                    class="max-w-[100px] mr-20"
                    src="../../assets/logo-white.png"
                    alt="ITARDA"
                >
                <a
                    target="_blank"
                    href="https://vc.ru/u/1119860-arda"
                >
                    <img
                        class="max-h-[40px]"
                        src="../../assets/vc.png"
                        alt="vc.ru"
                    >
                </a>
            </div>
            <div class="text-white flex flex-wrap gap-4">
                <a
                    class="block font-bold"
                    href="mailto:it@arda.digital"
                >
                    it@arda.digital
                </a>
                <a
                    class="block"
                    target="_blank"
                    href="https://arda.digital/privacy-policy/"
                >
                    Политика конфиденциальности
                </a>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "FooterItem"
}
</script>

<style scoped>

</style>
