<template>
    <div class="flex sm:flex-col justify-between items-center border border-2 p-5">
        <div class="flex justify-center align-middle w-[120px] h-[120px] p-3 border-0 border-gray-400 bg-white">
            <img
                class="w-full h-full object-contain"
                :src="logoUrl"
                alt=""
            >
        </div>
        <div
            class="relative inline-block group focus:outline-none focus:ring max-w-[250px] w-full"
        >
            <span
                class="absolute inset-0 transition-transform translate-x-1.5 translate-y-1.5 bg-orange-light group-hover:translate-y-0 group-hover:translate-x-0"
            />

            <a
                class="w-full text-center relative inline-block px-8 py-3 text-sm font-bold tracking-widest text-black uppercase border-2 border-current"
                :href="websiteLinkUrl"
                target="_blank"
                v-text="websiteLinkText"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: "PartnerBanner",

    props: {
        logoUrl: {
            type: String,
            required: true
        },

        websiteLinkUrl: {
            type: String,
            required: true
        },

        websiteLinkText: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>

</style>