import config from "@/config";

const configSymbol = 'config';

export default {
    install(app) {
        app.config.unwrapInjectedRef = true;
        app.config.globalProperties.$config = config;
        app.provide(configSymbol, config);
    }
}
