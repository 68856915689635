import { computed, reactive } from "vue";
import { useField } from "./field";

export function useForm(init = {}) {
    const form = reactive({ fields: {} });

    for (const [ key, value ] of Object.entries(init)) {
        form.fields[key] = useField(value);
    }

    const withoutValid = key => !form.fields[key].valid;

    form.showErrors = function () {
        Object.keys(form.fields)
            .filter(withoutValid)
            .forEach(k => {
                form.fields[k].touched = true
            });
    };

    form.valid = computed(() => {
        return Object.keys(form.fields)
            .filter(withoutValid)
            .every(k => form.fields[k].valid);
    });

    return form;
}
