<template>
    <header class="bg-orange py-1.5 px-5">
        <div class="max-w-[1500px] flex justify-between items-center mx-auto">
            <img
                class="object-cover max-w-[80px] col-start-2 col-end-3"
                src="../../assets/ITARDA.png"
                alt="IT-кластер ARDA"
            >
            <div class="flex items-center">
                <router-link
                    v-if="token"
                    class="col-start-10 col-end-11 block mr-5 p-1 rounded-full border-2 border-transparent font-bold"
                    :to="{ name: mainLink }"
                >
                    Личный кабинет
                </router-link>
                <button
                    v-else
                    class="col-start-10 col-end-11 px-4 py-2 mr-5 font-bold"
                    @click="openModalAuthorization"
                >
                    Войти
                </button>
                <a
                    href="mailto:it@arda.digital"
                    class="col-start-11 col-end-12"
                > it@arda.digital</a>
            </div>
        </div>
    </header>
</template>

<script>
import { KEYS } from "@/constants";

export default {
    name: "HeaderItem",
    data() {
        return {
            token: localStorage.getItem(KEYS.TOKEN),
            mainLink: this.$config.routes.account.main.name
        }
    },
    methods: {
        openModalAuthorization() {
            return this.$emit('openModalAuthorization');
        }
    }
}
</script>