<template>
    <query-modal
        :error="error"
        :pending="false"
        :success="success"
        @close="$emit('closeModal')"
    >
        <template #modal>
            <div class="flex justify-center flex-col gap-4">
                <h3
                    class="font-medium text-lg"
                >
                    Активировать профиль сотрудника {{ user.name }} {{ user.lastName }}?
                </h3>
                <enter-button
                    class="mx-auto"
                    @click="updateUser"
                >
                    Активировать
                </enter-button>
            </div>
        </template>
        <template #success>
            <h3
                class="font-medium text-lg"
            >
                Профиль сотрудника активирован.
            </h3>
            <p class="mt-8 mb-3">
                Сотруднику отправлено письмо об активации
            </p>
        </template>
    </query-modal>
</template>

<script>
import EnterButton from "@/components/Enter/Button";
import QueryModal from "@/components/QueryModal";
import { changeEmployee, sendEmailActivateAccount } from "@/api";
export default {
    name: "ActivateProfile",
    components: { EnterButton, QueryModal },
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            success: false,
            error: false
        }
    },
    methods: {
        updateUser() {
            const user = { confirmed: true }
            changeEmployee(user, this.user.id)
                .then(() => {
                    const body = { email: this.user.email }
                    return sendEmailActivateAccount(body)
                })
                .then(response => {
                    this.success = true
                    this.$emit('updateList')
                    return response
                }).catch(e => {
                    console.log(e)
                })
        }
    }
}
</script>
